import React, { Component } from 'react';
import ModalLogout from '../subcomponents/ModalLogout'
import headerLogoNoScroll from '../../resources/HoG-Logo.jpg'
import { connect } from 'react-redux';
import 'bootstrap-icons/font/bootstrap-icons.css';
import {
  scrollEventOccurs,
  toogleMenuButtonClicked,
  doLogout
} from '../../actions/index';
import { Link } from "react-router-dom";

class Header extends Component {

    scrollOffsetX = 0
    scrollOffsetY = 0

    constructor(props) {
        super(props);
        this.state = {
            isLogoutModalOpen: false
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, true);
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, true);
    }
    
    handleScroll = event => {    
        // console.log("this.props", this.props)
        this.props.scrollEventOccurs(window.pageXOffset, window.pageYOffset)
    }

    onConfirmLogout = () => {
        console.log("onConfirmLogout")
        this.setState(({ isLogoutModalOpen }) => ({ isLogoutModalOpen: false }))
        this.props.doLogout();
    }

    onCloseModal = () => {
        console.log("this", this)
        this.setState(({ isLogoutModalOpen }) => ({ isLogoutModalOpen: false }))
    }

    onLogoutCLicked = () => {
        this.setState(({ isLogoutModalOpen }) => ({ isLogoutModalOpen: true }))
    }

    render() {
        // console.log("this.props.itemInCartCount",this.props.itemInCartCount)
        //console.log("this.props.isUserLogged",this.props.isUserLogged)

        if(!this.props.isUserLogged) {
            return( <div>
                <nav >
                    <div >       
                        <div className="headerDivNoScroll">
                            <div className="headerDivSx">
                                <img className="logoHoffmanNoScroll" src={headerLogoNoScroll} alt="" />                            
                            </div>                             
                        </div>
                    </div>
                </nav>
            </div>);
        }
        else {            
            return(
                <div>
                    <nav >
                        <div >       
                            <div className="headerDivNoScroll">
                                <div className="headerDivSx">
                                    <img className="logoHoffmanNoScroll" src={headerLogoNoScroll} alt="" />                                                        
                                </div>
                                <div className="headerDivDx">
                                    {/* <p>{this.props.itemInCartCount}</p> */}
                                    <div className='headerBtnContainerDx'>
                                        {
                                            this.props.itemInCartCount > 0 ?
                                            <Link  to="/cart" ><i role="img" className="bi bi-cart-check cartHeaderIcon"></i></Link>
                                            :
                                            <Link  to="/cart" ><i role="img" className="bi bi-cart4 cartHeaderIcon"></i></Link>
                                        }
                                       
                                        <button style={{}}><i role="img" className="bi bi-person-x-fill cartHeaderIcon" onClick={ (e) => this.onLogoutCLicked()}></i></button>
                                        <ModalLogout onConfirm={this.onConfirmLogout} closeModal={this.onCloseModal} isOpen={this.state.isLogoutModalOpen}/>
                                    </div>
                                    
                                </div>                               
                            </div>
                        </div>
                    </nav>
                </div>
            )
        }        
    }
}

function mapDispatchToProps(dispatch) {
    return {
      scrollEventOccurs: (scrollOffsetX, scrollOffsetY) => dispatch(scrollEventOccurs(scrollOffsetX, scrollOffsetY)),
      toogleMenuButtonClicked: () => dispatch(toogleMenuButtonClicked()),
      doLogout: () => dispatch(doLogout())
    }
  }
  
  function mapStateToProps(state) {
    // console.log("state", state)
    return {
      scrollOffsetX: state.scroll.scrollOffsetX,
      scrollOffsetY: state.scroll.scrollOffsetY,
      itemInCartCount: state.cart.itemInCart.length,
      isUserLogged: state.loggedUser.isUserLogged // da spostare dal reducer di menu e metterlo in un posto piu generico
    } 
  }

export default connect(mapStateToProps, mapDispatchToProps)(Header)