import React from 'react';

export default ({ children }) => {

  return (
      <div style={{ height: "100%", width: "100%" }}>
          <div className="mainContainer" style={{ height: "100%" }}>
              {children}
          </div>
      </div>
  );
};
