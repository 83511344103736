import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    setBpFavourite
  } from '../../actions/index';

class BpCard extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {   
        //console.log("BpCard ", this.props)
    }

    componentDidUpdate() {
        // console.log("BpCard componentDidUpdate ", this.props)
    }

    onFavIconClicked= () => {
        this.props.setBpFavourite(this.props.item)
    }

    render() {
        return (
            <div className="card bpCard" onClick={(e) => this.props.onClick(this.props.item)} >
                <div className="bpCardHeader" style={{ backgroundColor: this.props.item.color }}>
                    <p>{ this.props.item.bpCode }</p>
                </div>
                <div className="card-body bpCardBody">
                    <div className="first-div">
                        <p className="card-text">{ this.props.item.bpName }</p>
                        <p className="card-text">{ this.props.item.bpCode }</p>
                    </div>
                    <div className="second-div">
                        <p className="card-text">{ this.props.item.tel }</p>
                    </div>
                </div>
                <div className="bpCardFooter">
                    {this.props.item.isFavourite && (
                        <button className="card-link btn"><i role="img" className="bi bi-star-fill" onClick={(e) => this.onFavIconClicked()} ></i></button>
                    )}
                    {!this.props.item.isFavourite && (
                        <button className="card-link btn"><i role="img" className="bi bi-star" onClick={(e) => this.onFavIconClicked()}></i></button>
                    )}
                </div>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setBpFavourite: (bp) => dispatch(setBpFavourite(bp))
    }
}   

export default connect(null, mapDispatchToProps)(BpCard)