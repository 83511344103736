import { SET_CARTCLOSE_USERDET_FORM, SET_CARTCLOSE_ITEMTABLE, UPDATE_DELIVERY_DATE, ONCHANGE_USERDET_FORM_INPUT } from '../actions/types'

const INITIAL_STATE = {
    // FORM
    invoiceBp: {},
    destinations: [],
    selectedDestination: null,
    carriages: [],
    selectedCarriage: null,
    creationDate: new Date(),
    deliveryDate: null,
    internalRef: null,
    note: null,
    userDetailForm: [],
    // ITEMS
    itemTableColumns: [],
    itemTableRows: []
}

export default function (state = INITIAL_STATE, action) {
    // console.log("cart close reducer " + action.type, action.payload)
    switch(action.type) {
        case SET_CARTCLOSE_USERDET_FORM:
            console.log("SET_CARTCLOSE_USERDET_FORM", action.payload)
        /*
            var creationDate = new Date(action.payload.creationDate)
            var deliveryDate = null

            if(action.payload.deliveryDate) {
                deliveryDate = new Date(action.payload.deliveryDate)    
                sessionStorage.setItem('cartCloseDeliveryDate', action.payload.deliveryDate)            
            }
            else if(!sessionStorage.getItem('cartCloseDeliveryDate')){
                // lo metto a null perchè potrebbe essere undefined (questo crea problemi a componente datepicker)
                action.payload.deliveryDate = null
            }
            else {
                deliveryDate = new Date(sessionStorage.getItem('cartCloseDeliveryDate'))
            }

            sessionStorage.setItem('cartCloseInvoiceBp', JSON.stringify(action.payload.invoiceBp))
            sessionStorage.setItem('cartCloseDestinations', JSON.stringify(action.payload.destinations))
            sessionStorage.setItem('cartCloseCarriages', JSON.stringify(action.payload.carriages))
            sessionStorage.setItem('cartCloseCreationDate', action.payload.creationDate)            
            sessionStorage.setItem('cartCloseInternalRef', action.payload.internalRef)
            sessionStorage.setItem('cartCloseNote', action.payload.note)

            return {
                    ...state, 
                    invoiceBp: action.payload.invoiceBp,
                    destinations: action.payload.destinations,
                    carriages: action.payload.carriages,
                    creationDate: creationDate,
                    deliveryDate: deliveryDate,
                    internalRef: action.payload.internalRef,
                    note: action.payload.note
                }
                */
            
            sessionStorage.setItem('cartCloseUserDetailForm', JSON.stringify(action.payload))    
            return {...state, userDetailForm: action.payload}
        case SET_CARTCLOSE_ITEMTABLE:
            
            sessionStorage.setItem('cartCloseItemTableColumns', JSON.stringify(action.payload.columns))

            return {
                    ...state, 
                    itemTableColumns: action.payload.columns
                }
        case UPDATE_DELIVERY_DATE:
            var deliveryDate = null
            
            // console.log("UPDATE_DELIVERY_DATE", action.payload.deliveryDate)

            if(typeof action.payload.deliveryDate !== undefined) {
                deliveryDate = new Date(action.payload.deliveryDate)               
                sessionStorage.setItem('cartCloseDeliveryDate', action.payload.deliveryDate)    

                return {
                    ...state,
                    deliveryDate: deliveryDate
                }        
            }
            else {
                console.log("action.payload.deliveryDate UNDEFINED")
                return state
            }      
        case ONCHANGE_USERDET_FORM_INPUT:
            // console.log("ONCHANGE_USERDET_FORM_INPUT", action.payload)
            var formDetail = action.payload.formStep
            var newFormList = JSON.parse(JSON.stringify(state.userDetailForm))

            var formDetToUpdate = newFormList.find(fd => fd.iD_FormDetail === formDetail.iD_FormDetail)
            if(formDetToUpdate) {   
                formDetToUpdate.instance.value = action.payload.newValue
                sessionStorage.setItem('cartCloseUserDetailForm', JSON.stringify(newFormList))
                return {...state, userDetailForm: newFormList }
            }
        default:
            return state
    }
}