import { SET_LOGGED_USER, SET_ISLOGGEDUSER } from "../actions/types";

const INITIAL_STATE = {
    isUserLogged: false,
    id_User: "",
    userCode: "",
    userName: ""
}

export default function(state = INITIAL_STATE, action) {
    switch(action.type){
        // apertura/chiusura menu
        case SET_LOGGED_USER:
            console.log("SET_LOGGED_USER", action.payload)
            sessionStorage.setItem("id_User", action.payload.id_User)
            sessionStorage.setItem("userCode", action.payload.userCode)
            sessionStorage.setItem("userName", action.payload.userName)
            return { ...state, id_User: action.payload.id_User, userCode: action.payload.userCode, userName: action.payload.userName};  
            
        case SET_ISLOGGEDUSER:
            sessionStorage.setItem('isUserLogged', action.payload.isUserLogged)
            return { ...state, isUserLogged: action.payload.isUserLogged };        
        default:
            return state;
    }
}