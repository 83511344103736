import { SET_BP_LIST, SET_BP_FAVOURITE } from '../actions/types';

const INITIAL_STATE = {
    bpList: []
}

export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
        case SET_BP_LIST:
            console.log("SET_BP_LIST ",  action.payload)
            var newBpList = action.payload;
            sessionStorage.setItem('bpList', JSON.stringify(newBpList.bpList))
            return { ...state, bpList: newBpList.bpList }
        case SET_BP_FAVOURITE:
            var bp = action.payload
            var oldBpList = JSON.parse(JSON.stringify(state.bpList))
            var bpToUpdate = oldBpList.find(el => el.bpCode === bp.bpCode)

            if(typeof bpToUpdate !== "undefined" && bpToUpdate) {
                bpToUpdate.isFavourite = !bpToUpdate.isFavourite               
                sessionStorage.setItem('bpList', JSON.stringify(oldBpList))
                return { bpList: oldBpList }
            }

            return state
        default:
            return state
    }
}