import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    deleteOrder
  } from '../../actions/index';

class OrderCard extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {   
        // console.log("OrderCard ", this.props)
    }

    componentDidUpdate() {
        // console.log("OrderCard componentDidUpdate ", this.props)
    }

    onDeleteClicked= () => {
        this.props.deleteOrder(this.props.item)
    }

    render() {
        return (
            <div className="card orderCard" onClick={(e) => this.props.onClick(this.props.item)} >
                <div className="orderCardHeader" style={{ backgroundColor: this.props.item.color }}>
                    <p>{ this.props.item.orderCode }</p>
                </div>
                <div className="card-body orderCardBody">
                    <div className="first-div">
                        <p className="card-text">{ this.props.item.bpCode }</p>
                        <p className="card-text">{ this.props.item.deliveryAddress }</p>
                        <p className="card-text">{ this.props.item.orderDate }</p>
                    </div>
                    <div className="second-div">
                        <p className="card-text">&euro; { this.props.item.Tot }</p>
                    </div>
                </div>
                <div className="orderCardFooter">
                    {this.props.deletable &&
                        <button className="card-link btn"><i role="img" className="bi bi-trash-fill" onClick={(e) => this.onDeleteClicked()} ></i></button> 
                    }
                                         
                </div>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deleteOrder: (order) => dispatch(deleteOrder(order))
    }
}   

export default connect(null, mapDispatchToProps)(OrderCard)