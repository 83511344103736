import React, { useState  } from 'react';
import { connect } from 'react-redux';
import { doLogin, doLogout } from '../../actions/index';
import { Navigate, useNavigate } from "react-router-dom";
import {FormattedMessage} from 'react-intl';

function Login(props) {
    
    const [errCredential, setErrCredential] = useState(false)
    const [username, setUserInputVal] = useState("")
    const [psw, setPswInputVal] = useState("")

    let navigate = useNavigate();
    props.doLogout();
    let inputChangedUsernameHandler = function(e) {
        // username = e.target.value
        setUserInputVal(e.target.value)
    }

    let inputChangedPswHandler = function(e) {
        // psw = e.target.value
        setPswInputVal(e.target.value)
    }

    // console.log(props)
        return (
            <div id="login-main-div">
                <div className="loginContent ">
                    <div className="loginHeaderDiv">
                        <h3>Login</h3>  
                    </div>
                    <div key="login-form-username" className="">
                        <input key="login-form-username-input" type="text" className="" onChange={(e) => inputChangedUsernameHandler(e)} id="login-form-username-input"  name="login-form-username-input" aria-describedby="Username" placeholder="Username" defaultValue={psw}/>                  
                    </div>
                    <div key="login-form-psw" className="">
                        <input key="login-form-psw-input" className="" onChange={(e) => inputChangedPswHandler(e)} type="password"  id="login-form-psw-input" aria-describedby="Password" placeholder="Password" defaultValue={username}/>                 
                    </div>
                    <div id="loginErrorDiv" style={{display: errCredential ? 'block' : 'none', color: 'red', fontSize:'1rem'}}>
                        <p>Credenziali Errate</p>
                    </div>
                    <button type="submit" className="btn btn-primary" onClick={async (event) => {
                        var res = await props.doLogin({ username: username, psw: psw});
                        console.log("login res", res)
                        if(res && res.response && (res.response.status === 401 || res.response.status === "401")) {
                            setErrCredential(true)
                        }
                        else {
                            if(res.data && res.data.updatePassword && res.data.updatePassword === true){
                                navigate('/changepsw');
                            }
                            else {
                                navigate('/catalog');
                            }   
                            
                        }
                        }} >Login
                    </button>
                    <div>
                    <div id="pswForgetDiv">
                        <a href="https://www.cognitoforms.com/HoffmannItaliaSpA/RichiestaAccessiToolDesigner">
                        <FormattedMessage
                                id = "Login.HoffmannLink.Label"
                                defaultMessage="Non hai un account? Richiedilo subito gratis"
                            />
                        </a>
                    </div>
                    <div id="loginHoffmannLinkDiv">
                        <a  href="">
                        <FormattedMessage
                                id = "Login.ResetPswLink.Label"
                                defaultMessage="Password dimenticata?"
                            />
                        </a>
                    </div>
                    </div>
                    
                    
                </div>
           </div>
        )

}



function mapDispatchToProps(dispatch) {
    return {
        doLogin: (dataRquest) => dispatch(doLogin(dataRquest)),
        doLogout: () => dispatch(doLogout())
    }
}

export default connect(null, mapDispatchToProps)(Login)
