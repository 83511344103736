
export const GetConfigurationDetailMarkup = function(configurationForm) {
    var config = [] // configurazione del modello da visualizzare
    var keyDiv = ""
    var keyValue = ""
    var keyLabel = ""

     // configurationForm contiene la configurazione del modello, da visualizzare nel corpo della ModalConfirm
     configurationForm.forEach(confInput => {
        keyDiv = "divModalConfirm_" + confInput.formDetailCode;
        keyValue = "valueModalConfirm_" + confInput.formDetailCode;
        keyLabel = "labelModalConfirm_" + confInput.formDetailCode;

        //console.log("confInput", confInput)
        
        if(confInput.inputType === "checkbox"){
            // console.log("confInput checkbox", confInput, confInput.instance.value, confInput.instance.value === true, confInput.instance.value == true)

            if(confInput.instance.value === "checked" || confInput.instance.value === true || confInput.instance.value === "true" || confInput.instance.value === "True") {
                config.push(
                    <div key={keyDiv}>
                        <span className="spanLabel" key={keyLabel} >{confInput.inputPlaceholder}:</span> <span className="spanValue" key={keyValue}>Si</span>
                    </div>
                )   
            }
            else {
                config.push(
                    <div key={keyDiv}>
                        <span className="spanLabel" key={keyLabel} >{confInput.inputPlaceholder}:</span> <span className="spanValue" key={keyValue}>No</span>
                    </div>
                )   
            }
            
        }
        else {
            config.push(
                <div key={keyDiv}>
                    <span className="spanLabel" key={keyLabel} >{confInput.inputLabel}:</span> <span className="spanValue" key={keyValue}>{ confInput.instance.value }</span>
                </div>
            )    
        }
              
    })

    return config;
}

export const GetConfigurationDetailMarkupForPoints = function(configuration) {
    var config = [] // configurazione del modello da visualizzare
    var keyDiv = ""
    var keyValue = ""
    var keyLabel = ""

    var pntConf = configuration.find(c => c.formTemplateCode === "PNT_CONF")
    var nrStep = configuration.find(c => c.formTemplateCode === "PNT_NRSTEP")
    var nrStepInt = parseInt(nrStep.configurationForm[0].instance.value)
    var angVerConf = configuration.find(c => c.formTemplateCode === "PNT_ANGVER")
    var lubrorConf = configuration.find(c => c.formTemplateCode === "PNT_LUBROR")
    var altroConf = configuration.find(c => c.formTemplateCode === "PNT_ALTRO")
    var noteConf = configuration.find(c => c.formTemplateCode === "PNT_NOTE")
    var materPolimConf = configuration.find(c => c.formTemplateCode === "PNT_MATPOLIM")
    var materRameCong = configuration.find(c => c.formTemplateCode === "PNT_MATRAME")
    var materLavConf = configuration.find(c => c.formTemplateCode === "PNT_MATLAV")

    config = config.concat(GetConfigurationDetailMarkup(pntConf.configurationForm))

    config.push(
        <div key={ "divModalConfirm_" + nrStep.configurationForm[0].formDetailCode }>
            <span className="spanLabel" key={"labelModalConfirm_" + nrStep.configurationForm[0].formDetailCode} >{nrStep.configurationForm[0].inputLabel}:</span> <span className="spanValue" key={"valueModalConfirm_" + nrStep.configurationForm[0].formDetailCode}>{ nrStep.configurationForm[0].instance.value }</span>
        </div>
    )    

    for(var i = 1; i <= nrStepInt; i++) {
        var stepConf = configuration.find(c => c.formTemplateCode === ("PNT_STEP" + i))
        
        stepConf.configurationForm.forEach(confInput => {
            keyDiv = "divModalConfirm_" + confInput.formDetailCode;
            keyValue = "valueModalConfirm_" + confInput.formDetailCode;
            keyLabel = "labelModalConfirm_" + confInput.formDetailCode;

            if(confInput.instance.value && confInput.instance.value !== ""){
                config.push(
                    <div key={keyDiv}>
                        <span className="spanLabel" key={keyLabel} >{confInput.inputLabel}:</span> <span className="spanValue" key={keyValue}>{ confInput.instance.value }</span>
                    </div>
                )  
            }
        })
    }

    var angVerSelected = angVerConf.configurationForm.find(cf => cf.instance.value === cf.formDetailCode  || cf.instance.value === "checked")
    console.log("angVerSelected",angVerSelected)
    if(angVerSelected) {
        keyDiv = "divModalConfirm_" + angVerSelected.formDetailCode;
        keyValue = "valueModalConfirm_" + angVerSelected.formDetailCode;
        keyLabel = "labelModalConfirm_" + angVerSelected.formDetailCode;

        if(angVerSelected.formDetailCode === "FreeInputCheckbox") {

            var angVertFreeInpu = angVerConf.configurationForm.find(cf => cf.formDetailCode === "FreeInputText")
            config.push(
                <div key={keyDiv}>
                    <span className="spanLabel" key={keyLabel} >Angolo Vertice:</span> <span className="spanValue" key={keyValue}>{angVertFreeInpu.instance.value}</span>
                </div>
            )  
        }
        else {
            config.push(
                <div key={keyDiv}>
                    <span className="spanLabel" key={keyLabel} >Angolo Vertice:</span> <span className="spanValue" key={keyValue}>{angVerSelected.inputLabel}</span>
                </div>
            )  
        }
        
    }

    var lubrorSelected = lubrorConf.configurationForm.find(cf => cf.instance.value === cf.formDetailCode || cf.instance.value === "checked")
    if(lubrorSelected) {
        keyDiv = "divModalConfirm_" + lubrorSelected.formDetailCode;
        keyValue = "valueModalConfirm_" + lubrorSelected.formDetailCode;
        keyLabel = "labelModalConfirm_" + lubrorSelected.formDetailCode;

        config.push(
            <div key={keyDiv}>
                <span className="spanLabel" key={keyLabel} >Lubrorefrigerazione:</span> <span className="spanValue" key={keyValue}>{lubrorSelected.inputLabel}</span>
            </div>
        )  
    }

    config = config.concat(GetConfigurationDetailMarkup(altroConf.configurationForm))
    config = config.concat(GetConfigurationDetailMarkup(noteConf.configurationForm))
    console.log(materPolimConf.configurationForm.filter(cf => cf.instance.value === "checked" || cf.instance.value === "true"))
    config = config.concat(GetConfigurationDetailMarkup(materPolimConf.configurationForm.filter(cf => cf.instance.value === "checked" || cf.instance.value === "true" || cf.instance.value === true || cf.instance.value === "True")))
    config = config.concat(GetConfigurationDetailMarkup(materRameCong.configurationForm.filter(cf => cf.instance.value === "checked" || cf.instance.value === "true" || cf.instance.value === true || cf.instance.value === "True")))
    config = config.concat(GetConfigurationDetailMarkup(materLavConf.configurationForm.filter(cf => cf.instance.value === "checked" || cf.instance.value === "true" || cf.instance.value === true || cf.instance.value === "True")))

    return config
}

export const GetConfigurationDetailMarkupForCutters = function(configuration) {
    var config = [] // configurazione del modello da visualizzare
    var keyDiv = ""
    var keyValue = ""
    var keyLabel = ""

    var frsConf = configuration.find(c => c.formTemplateCode === "FRS_CONF")
    var lubrorConf = configuration.find(c => c.formTemplateCode === "FRS_LUBROR")
    var altroConf = configuration.find(c => c.formTemplateCode === "FRS_ALTRO")
    var noteConf = configuration.find(c => c.formTemplateCode === "FRS_NOTE")
    var materPolimConf = configuration.find(c => c.formTemplateCode === "FRS_MATPOLIM")
    var materRameCong = configuration.find(c => c.formTemplateCode === "FRS_MATRAME")
    var materLavConf = configuration.find(c => c.formTemplateCode === "FRS_MATLAV")

    config = config.concat(GetConfigurationDetailMarkup(frsConf.configurationForm))

    var lubrorSelected = lubrorConf.configurationForm.find(cf => cf.instance.value === cf.formDetailCode || cf.instance.value === "checked")
    if(lubrorSelected) {
        keyDiv = "divModalConfirm_" + lubrorSelected.formDetailCode;
        keyValue = "valueModalConfirm_" + lubrorSelected.formDetailCode;
        keyLabel = "labelModalConfirm_" + lubrorSelected.formDetailCode;

        config.push(
            <div key={keyDiv}>
                <span className="spanLabel" key={keyLabel} >Lubrorefrigerazione:</span> <span className="spanValue" key={keyValue}>{lubrorSelected.inputLabel}</span>
            </div>
        )  
    }

    config = config.concat(GetConfigurationDetailMarkup(altroConf.configurationForm))
    config = config.concat(GetConfigurationDetailMarkup(noteConf.configurationForm))
    console.log(materPolimConf.configurationForm.filter(cf => cf.instance.value === "checked" || cf.instance.value === "true"))
    config = config.concat(GetConfigurationDetailMarkup(materPolimConf.configurationForm.filter(cf => cf.instance.value === "checked" || cf.instance.value === "true" || cf.instance.value === true || cf.instance.value === "True")))
    config = config.concat(GetConfigurationDetailMarkup(materRameCong.configurationForm.filter(cf => cf.instance.value === "checked" || cf.instance.value === "true" || cf.instance.value === true || cf.instance.value === "True")))
    config = config.concat(GetConfigurationDetailMarkup(materLavConf.configurationForm.filter(cf => cf.instance.value === "checked" || cf.instance.value === "true" || cf.instance.value === true || cf.instance.value === "True")))

    return config
}