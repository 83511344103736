import { TOOGLE_MENU_BUTTON_CLICKED ,SET_MENU_CONTENT } from "../actions/types";

const INITIAL_STATE = {
    menuElements: [],
    isMenuOpen: false,
}

export default function(state = INITIAL_STATE, action) {
    switch(action.type){
        // apertura/chiusura menu
        case TOOGLE_MENU_BUTTON_CLICKED:
            console.log("state.isMenuOpen", state.isMenuOpen)

            if(state.isMenuOpen === true || state.isMenuOpen === "true") {
                sessionStorage.setItem('isMenuOpen', false )
                return { ...state, isMenuOpen: false };
            }
            else {
                sessionStorage.setItem('isMenuOpen', true )
                return { ...state, isMenuOpen: true };
            }
        case SET_MENU_CONTENT:
            // console.log("SET_MENU_CONTENT", action.payload.menuElements)
            sessionStorage.setItem('menuElements', JSON.stringify(action.payload.menuElements))
            return {...state, menuElements: action.payload.menuElements}
        default:
            return state;
    }
}