import React, { Component } from 'react';

class BpDetails extends Component {

    constructor(props) {
        super(props)
    }

    render() {
        return(
            <p>ANAGRAFICA BP TODO</p>
        )
    }
}

export default BpDetails;