import React, { Component } from 'react';
import { connect } from 'react-redux';
import CartItemAccessoryGrp from './CartItemAccessoryGrp';
import 'bootstrap-icons/font/bootstrap-icons.css';
import ModalConfirm from '../subcomponents/ModalConfirm';
import { initializeModalConfirm, onChangeItemNote } from '../../actions';
import { GetConfigurationDetailMarkup, GetConfigurationDetailMarkupForPoints, GetConfigurationDetailMarkupForCutters } from './../../utils/Utils.js';
import {FormattedMessage} from 'react-intl';

class CartItem extends Component {

    CartItemQty = 0;

    constructor(props) {
        super(props);
        this.state = {
            CartItemQty: this.props.item.Qty,
            CartAddBtnVisible: false,
            CartRemoveBtnVisible: true,
            isItemConfigDetailModalOpen: false
        }
    }

    increaseItemCartQty = () => {
        this.setState(({ CartItemQty, CartAddBtnVisible }) => ({ CartItemQty: CartItemQty + 1,  CartAddBtnVisible: true }))
    }

    decreaseItemCartQty = () => {
        if(this.state.CartItemQty > 0) {
            if(this.state.CartItemQty === 1) {
                this.setState(({ CartItemQty, CartAddBtnVisible }) => ({ CartItemQty: CartItemQty - 1, CartAddBtnVisible: false }))
            }
            else {
                this.setState(({ CartItemQty, CartAddBtnVisible }) => ({ CartItemQty: CartItemQty - 1, CartAddBtnVisible: true }))
            }            
        }
    }

    updateItemCartQty = (e) => {
        
        var newValue = e.target.value
        console.log("updateItemCartQty", e.target.value)
        try {
            newValue = parseInt(newValue)
            console.log("updateItemCartQty newValue", newValue)
        }
        catch(e){
            newValue = 1
        }

        if(newValue < 1) {
            console.log("new value < 1")
            newValue = 1
        }

        console.log("updateItemCartQty newValue", newValue)

        var prevQty =  this.props.item.Qty;
        this.props.item.Qty = newValue // this.state.CartItemQty
        this.props.updateItemCartQty(this.props.item, prevQty)
        this.setState(({ CartAddBtnVisible }) => ({ CartAddBtnVisible: false }))
        
    }

    removeItemCart = () => {
        this.props.removeItemCart(this.props.item)
    }

    inputChangedHandler = (e) => {
        console.log("inputChangedHandler", this.CartItemQty)
    }

    openModalItemConfiguration = (e) => {
        e.preventDefault()

        var confirmRedirectTo = "" // redirezione su /cart se utente conferma

        if(this.props.item.itemGrp.itemGrpCode === "PUNTA"){
            var config = GetConfigurationDetailMarkupForPoints(this.props.item.modelConfiguration.configuration) // configurazione del modello da visualizzare
        }
        else if(this.props.item.itemGrp.itemGrpCode === "FRESA"){
            var config = GetConfigurationDetailMarkupForCutters(this.props.item.modelConfiguration.configuration) // configurazione del modello da visualizzare
        }
        else { // PIASTRA
            var config = GetConfigurationDetailMarkup(this.props.item.modelConfiguration.configuration[0].configurationForm) // configurazione del modello da visualizzare
        }
        
        // Funzione che restituisce il markup da settare come contenuto della modal
        var getContent = function() {         
            return  <div id="modalConfirmConfigTopContainer" className="modalConfirmContentTopDiv cartItemConfigDetailModal">
                        <h3>
                            <FormattedMessage
                                id = "CartItem.ModalItemConfiguration.Title"
                                defaultMessage="Dettaglio Configurazione"
                            />
                        </h3>
                        <div id="modalConfirmConfigDiv" className="modalConfirmConfigDiv">
                            {config }
                        </div>
                    </div>
        }

        // Stile css della ModalConfirm
        var customStyles = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: "50%",
                border: "1px solid black",             
              }
        }

        // Inizializza lo stato della ModalConfirm
        this.props.initializeModalConfirm(confirmRedirectTo, getContent, customStyles)
        // Apre la ModalConfirm
        this.setState(({ isItemConfigDetailModalOpen }) => ({ isItemConfigDetailModalOpen: true }))
    }

    closeModalItemConfiguration = () => {
        this.setState(({ isItemConfigDetailModalOpen }) => ({ isItemConfigDetailModalOpen: false }))
    }

    onChangeItemNote = (e, item) => {
        e.preventDefault();
        this.props.onChangeItemNote(item, e.target.value)
    }

    getQtyInputMarkup = () => {
        return <div key="div_item_qty" className="form-group">
                <label key="label_item_qty" className="" htmlFor="input_item_qty">
                    <FormattedMessage
                                id = "CartItem.ItemSection.InputQtyLabel"
                                defaultMessage="Quantità"
                    />
                </label>
                <input key="input_item_qty" type="number" className="form-control" id="input_item_qty" name="input_item_qty" aria-describedby="Quantità" placeholder="" value={this.props.item.Qty} onChange={ (e) => this.updateItemCartQty(e) } />
            </div>
    }

    render() {
        var urlThumbnail = ""
        var accessories = []
        var _this = this
        if(this.props.item.thumbnails && this.props.item.thumbnails.length > 0) {
            urlThumbnail = this.props.item.thumbnails[0].pathToThumbnail
        }

        // Accessori
        if(this.props.item.accessories){
            this.props.item.accessories.forEach(function(acc, index){
                //console.log("acc",acc)
                var key = acc.accessoryGrpCode + '_' + index
                accessories.push(<CartItemAccessoryGrp key={key} accessoryGrp={acc} item={_this.props.item} ></CartItemAccessoryGrp>)
            })
        }

        return(
            <div>
                <div className="cartItemContainer">
                <ModalConfirm modalClassName="itemConfigDetailModal" onConfirm={this.closeModalItemConfiguration} iconConfirmBtn="" textConfirmBtn="Ok" isOpen={this.state.isItemConfigDetailModalOpen} />  
                    <div id="CartItemCardHeader" className="cartItemContainerHeader">
                        <p>{this.props.item.itemName}</p>
                    </div>
                    <div id="CartItemCardContent" className="cartItemContainerContent">
                        <div className="CartItemContentDx">
                            <div id="CartItemCardImg"  className="cartItemContainerImg">
                                <img src={ urlThumbnail } ></img>
                            </div>
                            <div id="CartItemCardDetail" className="cartItemContainerDetails">
                                <div>
                                    <p>
                                    <FormattedMessage
                                        id = "CartItem.Details.ProductName.Label"
                                        defaultMessage="Nome Prodotto"
                                    />:
                                    </p>
                                    <p>{this.props.item.configurationDescr}</p>
                                </div>
                                <div>
                                    <p>
                                        <FormattedMessage
                                            id = "CartItem.Details.Description.Label"
                                            defaultMessage="Descrizione"
                                        />:
                                    </p>
                                    <p>{this.props.item.descr}</p>
                                </div>
                                <div>
                                    <p>UM:</p>
                                    <p>{this.props.item.um.umCode}</p>
                                </div>
                                <div key="cart_item_note_div">
                                    <label key="cart_item_note_label" htmlFor={this.props.item.itemCode}>Note:</label>
                                    <input key="cart_item_note_input" id={this.props.item.itemCode} name={this.props.item.itemCode} aria-describedby="" defaultValue={this.props.item.note} onChange={(e) => this.onChangeItemNote(e, this.props.item) }/>
                                </div>
                                <div>
                                    <button type="submit" onClick={(e) => this.openModalItemConfiguration(e)} className="cartItemConfigDetailBtn" >
                                    <FormattedMessage
                                        id = "CartItem.LinkItemConfiguration.Label"
                                        defaultMessage="Dettaglio Configurazione"
                                    />
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/*
                        <div id="CartItemCardQty" className="cartItemContainerQty">
                            <div className="cartItemContainerQtyInput">
                                <button className="card-link btn" onClick={(e) => this.decreaseItemCartQty() } ><i role="img" className="bi bi-dash-circle" ></i></button>
                                <input  type="number" value={ this.state.CartItemQty } readOnly onChange={(e) => this.inputChangedHandler(e)} />
                                <button className="card-link btn"  onClick={ (e) => this.increaseItemCartQty() }><i role="img" className="bi bi-plus-circle"  ></i></button>
                            </div>
                            <div className="cartItemContainerQtyConfirm">
                                { this.state.CartAddBtnVisible ? <button className="card-link btn" onClick={ (e) => this.updateItemCartQty() } > <i role="img" className="bi bi-cart-plus" ></i></button> : null}
                                { this.state.CartRemoveBtnVisible ? <button className="card-link btn" onClick={ (e) => this.removeItemCart() } > <i role="img" className="bi bi-cart-x-fill" ></i></button> : null}                            
                            </div>
                        </div>
                        */}
                        <div className="cartItemContainerQty">
                            { this.getQtyInputMarkup() }
                        </div>
                    </div>              
                </div>
                {
                    this.props.item.accessories && this.props.item.accessories.length > 0 ?
                        <div className="cartAccContainer">
                            <div id="CartItemCardAccessories" >
                                <div className="CartItemCardAccessoriesHead">
                                        <p>
                                        <FormattedMessage
                                            id = "CartItem.AccessorySection.Title"
                                            defaultMessage="Accessori"
                                        />
                                        </p>
                                </div>
                                { accessories }
                                </div>
                            </div>
                    :
                    <div></div>
                }
               
            </div>
        )
    }
}

function mapStateToProps(state) {
    // console.log("cart item state ", state)
    return {
        modelConfig: state.modelconfigurator.modelConfig
    }
}

function mapDispatchToProps(dispatch) {
    return {
        initializeModalConfirm: (title, getContent, customStyles) => dispatch(initializeModalConfirm(title, getContent, customStyles)),
        onChangeItemNote: (item, note) => dispatch( onChangeItemNote(item, note) )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CartItem)