import React, { Component } from 'react';

class CatalogCard extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {    }

    render() {
        var label = "";
        var urlThumbail = "";

        // categoria catalogo
        if(this.props.item.catalogName) {           
            label = this.props.item.catalogName
        }
        // item
        else {
            
            label =  this.props.item.itemName
        }


        // categoria catalogo
        if(this.props.item.pathToThumbnail) {            
            urlThumbail = this.props.item.pathToThumbnail
        }
        // item
        else if(this.props.item.thumbnails && this.props.item.thumbnails.length > 0) {
            urlThumbail = this.props.item.thumbnails[0].pathToThumbnail
        }

        return (
            <div className="catalogCard" onClick={(e) => this.props.onClick(this.props.item)}>
                <div className="catalogCardImgContainer">
                    <img className="catalogCardImg" src={ urlThumbail }  />
                </div>
                <div className="catalogCardTitleContainer">
                    <p className="catalogCardTitle" >{ label }</p> 
                </div>              
            </div>
        )
    }
}


export default CatalogCard