import { act } from 'react-dom/test-utils';
import { SET_MODELCARTDETAILS_FORM, ADD_ITEM_IN_CART, SET_ITEMS_IN_CART_ACCESSORIES, UPDATE_ITEM_IN_CART_QTY, REMOVE_ITEM_IN_CART, SET_ITEM_PRICE, UPDATE_DELIVERY_DATE_ITEM,
    SET_ITEM_CONFIGURATION_PDF_URL, SET_ITEM_CONFIGURATION_STEP_URL, ONCHANGE_ACCESSORYGRP_FORM_INPUT, UPDATE_ITEM_IN_CART_ACCESSORIES, ONCHANGE_CNC_FORM_INPUT,
    SET_MODELCONFIGURATOR_DESCR, EMPTY_CART, ONCHANGE_ITEM_NOTE } from '../actions/types'
import config from '../config.json'

/*
    oggetto di itemInCart:
    {
        ID_Item int
        itemCode string
        itemName string 
        UM string
        Note string
        urlThumbnail string 
        Descr string
        Qty int
        configuration array [ -- array che rappresenta la configurazione scelta per l item, definita in ModelConfigurator
            {
                id string
                label string         
                inputType string
                value string/int/object
            }
        ]
        accesories array [
            {

            }
        ]
    }

    oggetto di formList
    {
            id  int
            label string          
            inputType string
            inputValues array
            inputPlaceholder string
            inputDefaultValue string
            divClassName string
            inputClassName string
            labelClassName string
        }

*/
const INITIAL_STATE = {
    itemInCart : [],
    formList: [],
    completeRequestBtnEnabled: false
}

export default function (state = INITIAL_STATE, action) {
    // console.log("cart reducer " + action.type, action.payload)
    switch(action.type) {
        case SET_MODELCARTDETAILS_FORM:
            // console.log("SET_MODELCARTDETAILS_FORM", state)
            sessionStorage.setItem('cartDetailsForm', JSON.stringify(action.payload.formList))
            return {...state, formList: action.payload.formList }
            break;
        case ADD_ITEM_IN_CART:
            var item = action.payload.item
            item.Qty = 1
            item.price = 0;
            item.total = 0;
            item.configurationCode = Math.random();
            item.deliveryDate = new Date();

            /*
                TODO: finchè non ci danno le specifiche sulle tipologia di articoli che possono essere contemporaneamente nel carrello,
                        prevedo solo un articolo
            */
            //var newCart = state.itemInCart.concat(item)
            var newCart = [item]
            console.log("newCart", newCart)
            sessionStorage.setItem('itemInCart', JSON.stringify(newCart))
            sessionStorage.setItem('completeRequestBtnEnabled', true)
            return {...state, itemInCart: newCart, completeRequestBtnEnabled: true }
            break;
        case UPDATE_ITEM_IN_CART_QTY:
            var item = action.payload.item
            var newQty = item.Qty
            var prevQty = action.payload.prevQty;
            var newCart = JSON.parse(JSON.stringify(state.itemInCart))
            var itemToUpdate = newCart.find(i => i.ItemCode === item.ItemCode)

            if(itemToUpdate && newQty >= 0) {
                itemToUpdate.Qty = newQty

                console.log("newCart", newCart)

                newCart.forEach(i => {
                    i.accessories.forEach(a => {
                    
                        if(a.accessoryGrpForm) {
                            // se l accessorio non è modificabile dall utente bisogna aggiornare automaticamente la qta per questo accessorio
                            var qtyForm =  a.accessoryGrpForm.find(af => af.formDetailCode.includes("Qty") && af.dimmed === 1)
                            if(qtyForm){
                                var currQty = parseInt(qtyForm.instance.value);
                                qtyForm.instance.value = ((currQty /parseInt(prevQty)) * parseInt(newQty)).toString()

                                console.log("currQty", currQty)
                                console.log("qtyForm.instance.value", qtyForm.instance.value)
                                
                            }
                        }
                    })
                })

                sessionStorage.setItem('itemInCart', JSON.stringify(newCart))
                return {...state, itemInCart: newCart }
                break;
            }
        case REMOVE_ITEM_IN_CART:
            var item = action.payload.item   
            var newCart = JSON.parse(JSON.stringify(state.itemInCart))
            // ("action.payload.item", action.payload.item)
            var itemToRemoveIndex = newCart.findIndex(i => i.iD_Item === item.iD_Item && i.configurationCode === item.configurationCode)
            // console.log("itemToRemoveIndex",itemToRemoveIndex)
            if(itemToRemoveIndex >= 0){
                if(newCart.length == 1) {
                    sessionStorage.setItem('itemInCart', JSON.stringify([]))
                    sessionStorage.setItem('completeRequestBtnEnabled', false)
                    return {...state, itemInCart: [], completeRequestBtnEnabled: false }
                }
                else {
                    newCart.splice(itemToRemoveIndex, 1)
                    // ("REMOVE_ITEM_IN_CART newCart", newCart)
                    sessionStorage.setItem('itemInCart', JSON.stringify(newCart))
                    sessionStorage.setItem('completeRequestBtnEnabled', true)
                    return {...state, itemInCart: newCart, completeRequestBtnEnabled: true }
                    break;
                }   
                
            }
            break;
        case SET_ITEM_PRICE:
                console.log("SET_ITEM_PRICE", action.payload)

                var item = action.payload.item   
                var newCart = JSON.parse(JSON.stringify(state.itemInCart)) 

                var itemToSetPrice = newCart.find(i => i.iD_Item === item.iD_Item && i.configurationCode === item.configurationCode)
                // console.log("itemToSetPrice", itemToSetPrice)
                if(itemToSetPrice) {
                    itemToSetPrice.price = parseFloat(action.payload.price.price)
                    itemToSetPrice.total = itemToSetPrice.price * itemToSetPrice.Qty
                    itemToSetPrice.supplierCode = ""

                    if(action.payload.price.itemCode && action.payload.price.itemCode != ""){
                        itemToSetPrice.itemCode = action.payload.price.itemCode
                    }

                    if(action.payload.price.supplierCode){
                        itemToSetPrice.supplierCode = action.payload.price.supplierCode
                    }

                    if(action.payload.price.extraCharges){
                        itemToSetPrice.extraCharges = action.payload.price.extraCharges
                    }
                }

                sessionStorage.setItem('itemInCart', JSON.stringify(newCart))
                return {...state, itemInCart: newCart }
                break;
        case UPDATE_DELIVERY_DATE_ITEM:

            if(action.payload.deliveryDate) {
                var item = action.payload.item
                var newCart = JSON.parse(JSON.stringify(state.itemInCart))
                var itemToUpdate = newCart.find(i => i.iD_Item === item.iD_Item && i.configurationCode === item.configurationCode)
                        
                if(itemToUpdate) {
                    itemToUpdate.deliveryDate = action.payload.deliveryDate 
                    sessionStorage.setItem('itemInCart', JSON.stringify(newCart))
                    return {...state, itemInCart: newCart }  
                }
                else {
                    return { ...state } 
                }                              
            }
            else {
                return state
            }       
        case SET_ITEM_CONFIGURATION_PDF_URL:
            console.log("SET_ITEM_CONFIGURATION_PDF_URL", action.payload)    

            if(action.payload.pdfUrl) {
                var item = action.payload.item
                var newCart = JSON.parse(JSON.stringify(state.itemInCart))
                var itemToUpdate = newCart.find(i => i.iD_Item === item.iD_Item && i.configurationCode === item.configurationCode)
                        
                if(itemToUpdate) {
                    itemToUpdate.pdfUrl = config.server + action.payload.pdfUrl 
                    sessionStorage.setItem('itemInCart', JSON.stringify(newCart))
                    return {...state, itemInCart: newCart }  
                }
                else {
                    return { ...state } 
                }                              
            }
            else {
                return state
            } 
        case SET_ITEM_CONFIGURATION_STEP_URL:
            console.log("SET_ITEM_CONFIGURATION_STEP_URL", action.payload)    

            if(action.payload.stepUrl) {
                var item = action.payload.item
                var newCart = JSON.parse(JSON.stringify(state.itemInCart))
                var itemToUpdate = newCart.find(i => i.iD_Item === item.iD_Item && i.configurationCode === item.configurationCode)
                        
                if(itemToUpdate) {
                    itemToUpdate.stepUrl = config.server + action.payload.stepUrl 
                    sessionStorage.setItem('itemInCart', JSON.stringify(newCart))
                    return {...state, itemInCart: newCart }  
                }
                else {
                    return { ...state } 
                }                              
            }
            else {
                return state
            }    
        case SET_ITEMS_IN_CART_ACCESSORIES:
            // console.log("SET_ITEMS_IN_CART_ACCESSORIES", action.payload)

            var item = action.payload.item
            var newCart = JSON.parse(JSON.stringify(state.itemInCart))
            var itemToUpdate = newCart.find(i => i.iD_Item === item.iD_Item && i.configurationCode === item.configurationCode)
            // console.log("itemToUpdate", itemToUpdate)            
            if(itemToUpdate) {
                itemToUpdate.accessories = action.payload.accessories 
                itemToUpdate.accessories.forEach(function(acc){
                    if(acc.imgUrl) {
                        acc.imgUrl = config.server + acc.imgUrl
                    }
                })

                sessionStorage.setItem('itemInCart', JSON.stringify(newCart))
                return {...state, itemInCart: newCart }  
            }
            else {
                 return { ...state } 
            }                              
        case ONCHANGE_ACCESSORYGRP_FORM_INPUT:
            console.log("ONCHANGE_ACCESSORYGRP_FORM_INPUT", action.payload)

            var formDetail = action.payload.formStep
            var item = action.payload.item
            var newCart = JSON.parse(JSON.stringify(state.itemInCart))
            var itemToUpdate = newCart.find(i => i.iD_Item === item.iD_Item && i.configurationCode === item.configurationCode)
            console.log("itemToUpdate", itemToUpdate)            
            if(itemToUpdate && itemToUpdate.accessories) {

                itemToUpdate.accessories.forEach(function(acc){
                    if(acc.accessoryGrpForm){
                        acc.accessoryGrpForm.forEach(function(formDet){
                            if(formDet.formDetailCode === formDetail.formDetailCode) {
                                formDet.instance.value = action.payload.newValue
                            }
                        })
                    }
                })

                sessionStorage.setItem('itemInCart', JSON.stringify(newCart))
                return {...state, itemInCart: newCart }  
            }
            else {
                 return { ...state } 
            }       
        case UPDATE_ITEM_IN_CART_ACCESSORIES:
            if(action.payload.itemInCart){
                sessionStorage.setItem('itemInCart', JSON.stringify(action.payload.itemInCart))
                return {...state, itemInCart: action.payload.itemInCart }  
            }
        case ONCHANGE_CNC_FORM_INPUT:
            console.log("ONCHANGE_CNC_FORM_INPUT", action.payload)
            var formDetail = action.payload.formStep
            var newFormList = JSON.parse(JSON.stringify(state.formList))

            var formDetToUpdate = newFormList.find(fd => fd.iD_FormDetail === formDetail.iD_FormDetail)
            if(formDetToUpdate) {   
                formDetToUpdate.instance.value = action.payload.newValue
                sessionStorage.setItem('cartDetailsForm', JSON.stringify(newFormList))
                return {...state, formList: newFormList }
            }
        
        case SET_MODELCONFIGURATOR_DESCR:
            console.log("SET_MODELCONFIGURATOR_DESCR", action.payload)
            
            var newItemInCart = JSON.parse(JSON.stringify(state.itemInCart))
            newItemInCart[0].configurationDescr = action.payload.configurationDescr
            sessionStorage.setItem('itemInCart', JSON.stringify(newItemInCart))
            
            return {...state, itemInCart: newItemInCart }
        case EMPTY_CART:
            sessionStorage.setItem('itemInCart', JSON.stringify([]))
            sessionStorage.setItem('cartDetailsForm', JSON.stringify([]))
            sessionStorage.setItem('completeRequestBtnEnabled', false)
            return { itemInCart: [], formList: [], completeRequestBtnEnabled: false}

        case ONCHANGE_ITEM_NOTE:
            console.log("ONCHANGE_ITEM_NOTE", action.payload)
            var newItemInCart = JSON.parse(JSON.stringify(state.itemInCart))

            var itemToUpd = newItemInCart.find(i => i.iD_Item === action.payload.item.iD_Item)
            if(itemToUpd) {
                itemToUpd.note = action.payload.note;              
            }

            sessionStorage.setItem('itemInCart', JSON.stringify(newItemInCart))           
            return {...state, itemInCart: newItemInCart }

        default:
            return state
    }
}