import { UPDATE_CATALOG_CONTENT } from '../actions/types'

/*
  Oggetto in catalogElements: 
  {
    "iD_Catalog": INT,
    "catalogCode": STRING,
    "catalogName": STRING,
    "iD_Parent": INT,
    "rowNumber": INT,
    "creationDate": STRING "MM/dd/aaaa hh:mm:ss",
    "updateDate":  STRING "MM/dd/aaaa hh:mm:ss",
    "note": STRING,
    "pathToThumbnail": STRING (es. "https://localhost:44387/data/THUMBNAILS/PST.png")
  }





*/
const INITIAL_STATE = {
    bp: {},
    catalogElements: [],
    itemElements: []
}

export default function (state = INITIAL_STATE, action) {
    // console.log("catalog reducer " + action.type, action.payload)
    
    switch(action.type) {
        case UPDATE_CATALOG_CONTENT:
            // console.log("JSON.stringify(action.payload.catalogElements)", JSON.stringify(action.payload.catalogElements))
            sessionStorage.setItem('catalogElements', JSON.stringify(action.payload.catalogElements))
            sessionStorage.setItem('itemElements', JSON.stringify(action.payload.itemElements))
            sessionStorage.setItem('catalogBpActive', JSON.stringify(action.payload.bp))
            return {...state, bp: action.payload.bp, catalogElements: action.payload.catalogElements, itemElements: action.payload.itemElements}
        default:
            return state;
    }
}