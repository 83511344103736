import { SET_ACCESS_TOKEN, FORGE_UPDATE_MODEL_PARAMETERS, SET_LOADING_SPINNER_VISIBILITY, SET_MODELCONFIGURATOR_FORM, SET_MODEL_CONF_ITEM,
    UPDATE_MODEL_PARAMS_REDUCER, ONCHANGE_CONFIGURATOR_FORM_INPUT, EMPTY_CART, SET_MODELCONFIGURATOR_SVF } from '../actions/types';
import config from '../config.json'
import { GetConfigurationDetailMarkup } from '../utils/Utils';
/*
    oggetto di modelConfig:
    {
        id string
        label string         
        inputType string
        value string/int/object
    }

    oggetto di formList
    {
            id  int
            label string          
            inputType string
            inputValues array
            inputPlaceholder string
            inputDefaultValue string
            divClassName string
            inputClassName string
            labelClassName string
        }
    
    oggetto item
    {
        ID_Item int
        itemCode string
        itemName string 
        UM string
        Note string
        urlThumbnail string 
        Descr string
        Qty int
        configuration array [ -- array che rappresenta la configurazione scelta per l item, definita in ModelConfigurator
            {
                id string
                label string         
                inputType string
                value string/int/object
            }
        ]
        accesories array [
            {

            }
        ]
    }
    

*/
const INITIAL_STATE = {
    svf: "",
    accessToken: "",
    item: null,
    modelConfig: [],
    loading: false,
    outputIam: ""
}

export default function(state = INITIAL_STATE, action) {
    //console.log("modelconfigurator reducer", action)
    switch(action.type) {
        case SET_ACCESS_TOKEN:
            sessionStorage.setItem('accessToken', action.payload.accessToken)
            return {...state, accessToken: action.payload.accessToken}
        case FORGE_UPDATE_MODEL_PARAMETERS:
            console.log("FORGE_UPDATE_MODEL_PARAMETERS", action.payload)
            sessionStorage.setItem('svf', config.server + action.payload.svfUrl )
            sessionStorage.setItem('outputIam', config.server + action.payload.outputIam )
            return {...state, svf: config.server + action.payload.svfUrl, outputIam: action.payload.outputIam }
        case SET_LOADING_SPINNER_VISIBILITY:
            return {...state, loading: action.payload }
        case SET_MODELCONFIGURATOR_SVF:
            var svf = ""
            console.log("SET_MODELCONFIGURATOR_SVF", action.payload)
            if(action.payload.svf && action.payload.svf !== ""){
                svf = config.server + action.payload.svf
            }
            else {
                svf = state.svf
            }

            sessionStorage.setItem('svf', svf)
            return {...state, svf: svf }
        case SET_MODELCONFIGURATOR_FORM:            
            console.log("SET_MODELCONFIGURATOR_FORM", action.payload)
           
            sessionStorage.setItem('modelConfig', JSON.stringify(action.payload.formList))

            console.log("modelConfig", JSON.parse(JSON.stringify(action.payload.formList)))

            return {...state, modelConfig: action.payload.formList }
        case SET_MODEL_CONF_ITEM:
            sessionStorage.setItem('modelConfiguratorItem', JSON.stringify(action.payload.item))
            sessionStorage.setItem('svf', "" )
            sessionStorage.setItem('outputIam', "" )
            sessionStorage.setItem('modelConfig', JSON.stringify([]))
            return {...state, item: action.payload.item, svf: "", outputIam: "", modelConfig: [] }
        case UPDATE_MODEL_PARAMS_REDUCER:
            console.log("UPDATE_MODEL_PARAMS_REDUCER", action.payload)

            var newParams = action.payload.newParams
            var newConf = JSON.parse(JSON.stringify(state.modelConfig))
            //var newConfForm = newConf.configuration[0].configurationForm

            newConf.configuration.forEach(c => {
                c.configurationForm.forEach(cf => {
                    var paramCode = Object.keys(newParams).find(p => p === cf.formDetailCode)
    
                    if(paramCode) {
                        console.log("newParams[paramCode]", cf, newParams[paramCode])
                        var newVal =  newParams[paramCode].value.replace(/\"/g,"") // i valori restituiti dall api Forge possono contenere caratteri doppi apici " indesiderati
                        

                        if(cf.inputType === "number"){
                            if(newParams[paramCode].unit) {
                                // rimuovo l unita di misura dal valore
                                newVal = newVal.replace(newParams[paramCode].unit, '').trim()
                            }

                            try{
                                newVal = Number.parseFloat(newVal).toFixed(2)
                                console.log("newval", newVal)
                            }
                            catch{
                                console.log("ERROR: non è possibile convertire in numero il valore per" + cf.formDetailCode)
                                newVal = ""
                            }
                            
                        }

                        if(cf.inputType === "select" && newVal === ""){
                            newVal = cf.instance.value
                        }

                        cf.instance.value = newVal 
                        cf.inputDefaultValue = newVal
                    }
                    else{
                        console.log("ERRORE: non trovato parametro " + cf.formDetailCode)
                    }
                });
            })         

            //console.log("newConfForm", newConfForm)
            console.log("modelConfig", newConf)
            sessionStorage.setItem('modelConfig', JSON.stringify(newConf))
            // sessionStorage.setItem('modelConfiguratorForm', JSON.stringify(newConfForm))

            return {...state, modelConfig: newConf }
        case ONCHANGE_CONFIGURATOR_FORM_INPUT:
            console.log("ONCHANGE_CONFIGURATOR_FORM_INPUT", action.payload)

            var idInput = action.payload.idInput
            console.log("idInput", idInput)
            var newValue = action.payload.newValue
            var formTemplateCode = ""
            var formDetailCode = idInput
            // gli id degli input sono creati concatendo il formTemplate code + '__' + formDetailCode
            if(idInput.includes("__")) {
                formTemplateCode = idInput.split("__")[0]
                formDetailCode = idInput.split("__")[1]
            }
            
            var newConf = JSON.parse(JSON.stringify(state.modelConfig))
            var newConfForm = newConf.configuration.find(c => c.formTemplateCode === formTemplateCode).configurationForm 

            // console.log("newConf", newConf)
            // console.log("newConfForm", newConfForm)

            var paramCode = newConfForm.find(p => p.formDetailCode === formDetailCode)
            // console.log("paramCode", paramCode)

            if(paramCode){
                if(paramCode.inputType === "radio"){
                    /* gli input radio hanno un comportamento particolare: se seleziono, il valore non è "checked", ma corrisponde al contenuto di formDetailCode. Inoltre se seleziono
                       un radio e successivamente seleziono uno diverso, il primo continuerà ad avere il valore di formDetailCode, quindi bisogna modificarlo manualmente in "unchecked" */
                    console.log("formDetailCode", formDetailCode)
                    var radios = newConfForm.filter(cf => cf.inputType === "radio" && cf.formDetailCode !== formDetailCode)
                    console.log("radios",radios)
                    radios.forEach(cf => cf.instance.value = "unchecked")
                }

                paramCode.instance.value = newValue
            }

            // TODO
            // Da creare servizio backend per aggiornamento dinamico form (tramite procedura)
            if(formDetailCode === "Configurazione_AUTO"){
                console.log("Configurazione_AUTO", newValue === true)

                for(var i=0; i<newConfForm.length; i++){  
                    console.log("newConfForm[i]", newConfForm[i])                 
                    if(newValue === true && newConfForm[i].formDetailCode !== "Larghezza_X" && newConfForm[i].formDetailCode !== "Profondita_Y" && newConfForm[i].formDetailCode !== "Configurazione_AUTO"){
                        newConfForm[i].dimmed = 1
                    }
                    else if(newValue === false && newConfForm[i].formDetailCode !== "X_UNITA_SUGGERITE" && newConfForm[i].formDetailCode !== "Y_UNITA_SUGGERITE"){
                        newConfForm[i].dimmed = 0
                    }
                }              
            }

            console.log("newConf",newConf)
            //sessionStorage.setItem('modelConfig', JSON.stringify(newConf))
            return {...state, modelConfig: newConf }
        case EMPTY_CART:
            /* se non metto svf a null, dopo aver svuotato carrello ho problema sovrapposizione modelli */
            sessionStorage.setItem('svf', null )
            return {...state,  svf: null }
        default:
            return state
    }
}