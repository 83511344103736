import React, { useState } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import {FormattedMessage} from 'react-intl';

function ModalLogout(props) {

    const [isOpen, setLogoutModOpen] = useState(false)
    Modal.setAppElement('#root');



        const buttonStyle= {
            border: "1px solid black",
            margin: "1rem 1rem 0 0"         
        }

        const modalStyle = {
            content : {
                border: "1px solid black",
                bottom: "auto",
                left: "50%",
                marginRight: "-50%",
                right: "auto",
                top: "50%",
                transform: "translate(-50%, -50%)",
                width: "min(60vw, 600px)",
                fontSize: "min(1.5vw, 20px)",
                fontWeight: "500"
            }         
        }

        return (
            <div>
                <Modal
                   isOpen={props.isOpen}
                   // onAfterOpen={}
                   onRequestClose={props.closeModal}
                   style={modalStyle}
                   contentLabel="Conferma Logout" >
                       <div>
                            <p style={{marginBottom:"5px", borderBottom:"3px solid #FF7000"}}>
                                <FormattedMessage
                                            id = "ModalLogout.Title"
                                            defaultMessage="Confermi di voler uscire?"
                                 />
                            </p>
                       </div>
                       <div className="floatBtnsDiv">
                       <Link to="/"><button style={buttonStyle} type="submit" className="btn btn-light" onClick={(e) => props.onConfirm(e)}>
                                <FormattedMessage
                                            id = "ModalLogout.LogoutButtonLabel"
                                            defaultMessage="Logout"
                                 />
                            </button>
                        </Link>
                       <Link to="/changepsw"><button style={buttonStyle} type="submit" className="btn btn-light" onClick={(e) => props.closeModal(e)}>
                                <FormattedMessage
                                            id = "ModalLogout.ChnPswButtonLabel"
                                            defaultMessage="Cambia Password"
                                 /></button>
                        </Link>
                       <button style={buttonStyle} type="submit" className="btn btn-light" onClick={(e) => props.closeModal(e)}>
                                <FormattedMessage
                                         id = "ModalLogout.BackButtonLabel"
                                            defaultMessage="Annulla"
                                 />
                        </button>
                            
                        </div>
                </Modal>
            </div>
        )
    
}

function mapStateToProps(state) {
    //console.log("ModalConfirm state ", state)
    return {
   
    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalLogout)