import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import Login from './components/pages/Login';
import ChangePassword from './components/pages/ChangePassword';
import Welcome from './components/Welcome';
import Catalog from './components/pages/Catalog';
import { Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store.js';
import Header from './components/subcomponents/Header';
import Menu from './components/subcomponents/Menu';
import BpDetails from './components/pages/BpDetails';
import BpList from './components/pages/BpList';
import Orders from './components/pages/Orders';
import ModelConfigurator from './components/pages/ModelConfigurator';
import ModelConfiguratorPoint from './components/pages/ModelConfiguratorPoint';
import ModelConfiguratorCutter from './components/pages/ModelConfiguratorCutter';
import Cart from './components/pages/Cart';
import CartClose from './components/pages/CartClose';
import { useNavigate } from "react-router-dom";
import {IntlProvider} from 'react-intl';
import French from './lang/fr.json';
import English from './lang/en.json';
import Espanol from './lang/es.json';
import Portuguese  from './lang/pt.json';
import Italian  from './lang/it.json';

const locale = navigator.language;
    let lang;
    if (locale==="fr") {
      lang = French;
    }
    else if(locale==="es"){
      lang = Espanol;
    }
    else if(locale==="it"){
      lang = Italian;
    }
    else if(locale==="pt"){
      lang = Portuguese;
    }
    else {
      lang = English;
    }

  console.log("locale",locale)
class Main extends React.Component {

  constructor(props){
    super(props); 
  }

  

  render() {
    return(        
      <Provider store={store}>
        
          <BrowserRouter>
            <App>
              <Header /> 
              <div className="mainContentDiv">
                <Menu />
                <Routes>
                  <Route path="/" element={ <Login/> } />
                  <Route path="login" element={ <Login/> } />
                  <Route path="welcome" element={ <Welcome/> } />
                  <Route path="catalog" element={ <Catalog/> } />
                  <Route path="bpdetails" element={ <BpDetails/> } />
                  <Route path="bplist" element={ <BpList/> } />
                  <Route path="orders" element={ <Orders/> } />
                  <Route path="modelconfigurator" element={ <ModelConfigurator/> } />
                  <Route path="modelconfiguratorpoint" element={ <ModelConfiguratorPoint/> } />
                  <Route path="modelconfiguratorcutter" element={ <ModelConfiguratorCutter/> } />
                  <Route path="cart" element={ <Cart/> } />
                  <Route path="cartclose" element={ <CartClose/> } />
                  <Route path="changepsw" element={ <ChangePassword/> } />
                </Routes>
              </div>
            </App>
          </BrowserRouter>   
        
      </Provider>
    )
  }
}

ReactDOM.render( <IntlProvider locale ={locale} messages={lang}> <Main />  </IntlProvider>  , document.getElementById('root'));

 