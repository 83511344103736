import React, { Component } from 'react';

class PopupSection extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isSectionOpen: true
        }

        console.log("constructor", this)
    }

    componentDidMount() {    }

    viewHideSection = (e) => {
        this.setState(({ isSectionOpen }) => ({ isSectionOpen: !isSectionOpen }))
        console.log("viewHideSection", this.state)
    }

    render() {


        return (
            <div className={ this.props.className }>
                <div className='popupSectionHeaderDiv'>
                    <span>{ this.props.title }</span>

                    {
                        this.state.isSectionOpen ?
                        <button key="button_confirm" type="button"  className="btn btn-light" onClick={ (e) => this.viewHideSection(e) }><i role="img" className="bi bi-chevron-down"  ></i></button>
                        :
                        <button key="button_confirm" type="button"  className="btn btn-light" onClick={ (e) => this.viewHideSection(e) }><i role="img" className="bi bi-chevron-up"  ></i></button>
                    }
                </div>
                <div className='popupSectionContentDiv' style={{display: this.state.isSectionOpen ? 'flex' : 'none' }}>
                    { this.props.sectionContent } 
                </div>                       
            </div>
        )
    }
}


export default PopupSection