import { ADD_BREADCRUMB_STEP_CATALOG, SELECT_BREADCRUMB_STEP_CATALOG, INITIALIZE_BREADCRUMB_CATALOG } from "../actions/types";

const INITIAL_STATE = {
    steps: []
}

export default function(state = INITIAL_STATE, action) {
    switch(action.type){
        case ADD_BREADCRUMB_STEP_CATALOG:
            var newStep = action.payload;

            // console.log("newStep", newStep)
            var newBreadcrumb = state.steps.concat(newStep);
            sessionStorage.setItem('catalogBreadcrumbSteps', JSON.stringify(newBreadcrumb))
            return { ...state, steps: newBreadcrumb };
        case SELECT_BREADCRUMB_STEP_CATALOG:
            var step = action.payload;
            var newBreadcrumb = state.steps.slice(0, state.steps.findIndex(el => el.item.catalogCode === step.item.catalogCode) + 1);
            sessionStorage.setItem('catalogBreadcrumbSteps', JSON.stringify(newBreadcrumb) )
            return { ...state, steps: newBreadcrumb };
        case INITIALIZE_BREADCRUMB_CATALOG:
            // console.log("INITIALIZE_BREADCRUMB_CATALOG", action.payload)
            var steps = action.payload;
            var newBreadcrumb = steps;
            sessionStorage.setItem('catalogBreadcrumbSteps', JSON.stringify(newBreadcrumb) )
            return { ...state, steps: newBreadcrumb };
        default:
            return state;
    }
}