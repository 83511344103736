import React, { useState } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

function ModalSuccess(props) {

    Modal.setAppElement('#root');

    const buttonStyle= {
        border: "1px solid black",
        margin: "1rem 1rem 0 0"         
    }

    const modalStyle = {
        content : {
            border: "1px solid black",
            bottom: "auto",
            left: "50%",
            marginRight: "-50%",
            right: "auto",
            top: "50%",
            transform: "translate(-50%, -50%)",
            width: "30%",
            fontSize: "min(1.5vw, 20px)",
            fontWeight: "500"
        }         
    }

        return (
            <div>
                <Modal
                   isOpen={props.isOpen}
                   // onAfterOpen={}
                   onRequestClose={props.closeModal}
                   style={modalStyle}
                   contentLabel={props.title} >
                       <div className="modalSuccessContentTopDiv">
                            <p>{props.message}</p>
                       </div>
                       <div className="floatBtnsDiv">
                            { typeof props.confirmRedirectTo === "undefined" || props.confirmRedirectTo === "" ? 
                                <button style={buttonStyle} type="submit" className="btn orangeBtn" onClick={(e) => props.onConfirm(e)}>Ok</button>                       
                                : 
                                <button style={buttonStyle} type="submit" className="btn orangeBtn" onClick={(e) => props.onConfirm(e)}><Link  to={props.confirmRedirectTo} >Ok</Link></button>  
                            }                            
                        </div>
                </Modal>
            </div>
        )
    
}


export default connect(null, null)(ModalSuccess)