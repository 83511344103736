import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import {
    toogleMenuButtonClicked
  } from '../../actions/index';
  import {FormattedMessage} from 'react-intl';

class Menu extends Component {

    constructor(props){
        super(props);

        this.keyCount = 0;
        this.getKey = this.getKey.bind(this)
    }

    getKey() {
        var newKey = "menu" + this.keyCount++
        return newKey;
    }  

    getElements= (_this) => {
        console.log("menuElements", this.props.menuElements)

        var menuMarkup = []

        menuMarkup.push(<li key={this.getKey()}><div className=""><button className="btn" onClick={(e) => this.props.toogleMenuButtonClicked()}><i role="img" className="bi bi-list"></i></button></div></li> )

        this.props.menuElements.forEach(m => {
            if(_this.props.isMenuOpen) {
                menuMarkup.push(<li key={this.getKey()}><div key={_this.getKey()} className=""> <a  href={m.target}> <i role="img" className={m.icon}></i> <p>
                            <FormattedMessage
                                id = {m.menuLabel}
                                defaultMessage="Catalogue"
                            />
                        </p> </a>  </div></li>)
            }
            else {
                menuMarkup.push(<li key={this.getKey()}><div key={_this.getKey()} className=""> <a  href={m.target}> <i role="img" className={m.icon}></i>  </a>  </div></li>)
            }
        });

        return menuMarkup
    }

    render() {
        if(!this.props.isUserLogged) {
            return (
                <div style={{ display: "none" }}>
                </div>
            )
        }
        else if(this.props.isMenuOpen) {
            return (
                <div className="MenuMainDivOpen">
                    <ul className="nav flex-column menuList">
                            {this.getElements(this)}                 
                    </ul>
                </div>
            )
        }
        else {
            return (
                <div className="MenuMainDivClose" >
                    <ul className="nav flex-column menuList">
                            {this.getElements(this)}                  
                    </ul>
                </div>
            )
        }
        
    }
}

function mapStateToProps(state) {
    // console.log(state)
    return {
        menuElements: state.menu.menuElements,
        display: state.menu.display,
        isUserLogged: state.loggedUser.isUserLogged,
        isMenuOpen: state.menu.isMenuOpen
    }
}

function mapDispatchToProps(dispatch) {
    return {
      toogleMenuButtonClicked: () => dispatch(toogleMenuButtonClicked())
    }
  }


export default connect(mapStateToProps, mapDispatchToProps)(Menu)