import React, { Component } from 'react';
import { connect } from 'react-redux';
import CartItem from '../subcomponents/CartItem';
import { getCartDetailsForm, updateItemInCartQty, removeItemInCart, getItemAccessories, updateItemInCartAccessories,
    onChangeCncFormInput, getItemCncFormAgainstFormValues, initializeModalConfirm, emptyCart } from '../../actions/index';
import  {Link}  from "react-router-dom";
import ModalConfirm from '../subcomponents/ModalConfirm';
import { createBrowserHistory } from "history";
import {FormattedMessage} from 'react-intl';

const locale = navigator.language;

  class Cart extends Component {

    constructor(props) {
        super(props);

        this.keyCount = 0;
        this.getKey = this.getKey.bind(this)
        this.state = {
            isEmptyCartModalOpen: false
        }
    }

    getKey() {
        var newKey = "cart" + this.keyCount++
        return newKey;
    }  

    componentDidUpdate()  {
        //console.log("cart update", this.props)
        // console.log(sessionStorage.getItem('itemInCart'))
    }

    componentDidMount() {
        //console.log("itemInCart", this.props.itemInCart)
        let _this = this
        
        /*
            TODO:
                recuperare da this.props.itemInCart l'item di cui recuperare form CNC (nel carrello potrebbero esserci anche gli accessori e diversi item)
                Di default ora prendo il primo item
        */
        if(_this.props.itemInCart && _this.props.itemInCart.length > 0) {

            var dataRequest = {
                ID_Item: _this.props.itemInCart[0].iD_Item,
                LangCode: locale
            }
            _this.props.getCartDetailsForm(dataRequest)
                .then(function(response){
                    // console.log("response", response, response.status)
                }).catch(function(err){
                    console.log("err",err)
                    let history = createBrowserHistory();
                    history.push("/login", _this.props)
                });

            if(true){
                var getAccRequest = {
                    item: _this.props.itemInCart[0],
                    langCode: locale,
                    jsonData: JSON.stringify({ item: _this.props.itemInCart[0], form: _this.props.formList})
                }
                
                
                _this.props.getItemAccessories(getAccRequest)
            }
          
        }
        
    }

    updateItemCartQty = (item, prevQty) => {
        console.log("item", item)
        this.props.updateItemInCartQty(item, prevQty)
        //this.props.updateItemInCartAccessories(this.props.itemInCart)
    }

    removeItemCart = (item) => {
        // console.log("itemInCart", this.props.itemInCart)
        // console.log("removeItemCart", item)
        this.props.removeItemInCart(item)
    }


    getCartItemsToRender = () => {
        console.log("this.props.itemInCart",this.props.itemInCart)

        if(this.props.itemInCart.length > 0) {
            const itemInCart = []         
            

            // articoli a carrello       
            for (let i = 0; i < this.props.itemInCart.length; i++) {   
                var prevQty =  this.props.itemInCart[i].Qty;          
                itemInCart.push( <CartItem key={this.props.itemInCart[i].iD_Item + "_" + i} item={this.props.itemInCart[i]} updateItemCartQty={ () => this.updateItemCartQty(this.props.itemInCart[i], prevQty)} removeItemCart={ () => this.removeItemCart(this.props.itemInCart[i]) } />);
            }

            return itemInCart
        }
        else {
            return <h4>Nessun Articolo a Carrello.</h4>
        }
    }
    
    onCompleteRequestClicked = () => {
        // console.log("*** onCompleteRequestClicked *** ", this.props.itemInCart)
        // Aggiornamento nello store redux degli accessori 
        this.props.updateItemInCartAccessories(this.props.itemInCart)
    }

    onCncFormInputChange = (event, formStep) => {
        console.log("onCncFormInputChange", formStep)
        event.preventDefault();
        this.props.onChangeCncFormInput(formStep, event.target.id, event.target.value)
        
        var formListCopy = JSON.parse(JSON.stringify(this.props.formList))
        var formDetToUpdate = formListCopy.find(fd => fd.iD_FormDetail === formStep.iD_FormDetail)
        if(formDetToUpdate) {   
            formDetToUpdate.instance.value = event.target.value
            console.log("getItemCncFormAgainstFormValues", formListCopy)
            this.props.getItemCncFormAgainstFormValues(formListCopy)
        }       
    }

    getFormToRender = function() {
        var formList = this.props.formList
        // console.log("Utils getFormToRender", formList)
        var formListToRender = []
        var keyDiv = ""
        var keyInput = ""
        var keyLabel = ""
        var _this = this

        formList.map(function(formStep, index) {
    
            // console.log("formStep ", formStep)
            keyDiv = "div_" + formStep.formDetailCode + "_" + index;
            keyInput = "input_" + formStep.formDetailCode
            keyLabel = "label_" + formStep.formDetailCode + "_" + index;
    
            switch(formStep.inputType) {
                case "text":
                    // console.log("formStep.instance.value", formStep.instance.value)
                    if(formStep.dimmed === 1) {
                        formListToRender.push(
                            <div key={keyDiv} className={formStep.divClassName}>
                                <label key={keyLabel} className={formStep.labelClassName} htmlFor={formStep.formDetailCode}>{formStep.inputLabel}</label>
                                <input key={keyInput} type="text" readOnly className={formStep.inputClassName} id={formStep.formDetailCode} name={formStep.formDetailCode} aria-describedby={formStep.inputLabel} placeholder={formStep.inputPlaceHolder} defaultValue={formStep.inputDefaultValue} />
                            </div>
                        )
                    }
                    else {
                        formListToRender.push(
                            <div key={keyDiv} className={formStep.divClassName}>
                                <label key={keyLabel} className={formStep.labelClassName} htmlFor={formStep.formDetailCode}>{formStep.inputLabel}</label>
                                <input key={keyInput} type="text" className={formStep.inputClassName} id={formStep.formDetailCode} name={formStep.formDetailCode} aria-describedby={formStep.inputLabel} placeholder={formStep.inputPlaceHolder} value={formStep.inputDefaultValue || formStep.instance.value} onChange={(e) => _this.onCncFormInputChange(e, formStep)} />
                            </div>
                        )
                    }
                    
                    break;
                case "select":
                    var options = []
                    var keyOpt = ""
                    formStep.inputValuesList.map(function(selectValue, index){
                        keyOpt = "option_" + formStep.formDetailCode + "_" + index;
                        
                        if(formStep.instance.value === selectValue) {
                            options.push(
                                <option selected key={keyOpt}>{selectValue}</option>
                            )
                        }
                        else {
                            options.push(
                                <option key={keyOpt}>{selectValue}</option>
                            )
                        }
                        
                    })
    
                    formListToRender.push(
                        <div key={keyDiv} className={formStep.divClassName}>
                            <label key={keyLabel} className={formStep.labelClassName} htmlFor={formStep.formDetailCode}>{formStep.inputLabel}</label>
                            <select key={keyInput} className={formStep.inputClassName} id={formStep.formDetailCode} onChange={(e) => _this.onCncFormInputChange(e, formStep)} >
                                { options }
                            </select>
                        </div>
                    )
                    break;
                case "checkbox":
                    formListToRender.push(
                        <div key={keyDiv} className={formStep.divClassName}>
                            <input key={keyInput} className={formStep.inputClassName} type="checkbox" value={formStep.formDetailCode} id={formStep.formDetailCode} />
                            <label key={keyLabel} className={formStep.labelClassName} htmlFor={formStep.formDetailCode}>
                                {formStep.inputLabel}
                            </label>
                        </div>
                    );
                    break;
                default:
                    break;
            }
        })
    
        // console.log("formListToRender", formListToRender)
         return formListToRender;
    }

    /* MODAL SVUOTA CARRELLO */
    openModalEmptyCart = function(e) {
        console.log("emptyCart")
        e.preventDefault();

        var confirmRedirectTo = "/catalog" // redirezione su /catalog se utente conferma

         // Funzione che restituisce il markup da settare come contenuto della modal
         var getContent = function() {         
            return  <div className="modalConfirmContentTopDiv">
                        <h3>
                            <FormattedMessage
                                id = "Cart.ModalEmptyCart.Title"
                                defaultMessage="Conferma Azione"
                            />
                        </h3>
                        <div className="modalConfirmTextDiv">
                            <p>
                            <FormattedMessage
                                id = "Cart.ModalEmptyCart.P1"
                                defaultMessage="Confermi di voler eliminare il contenuto del Carrello?"
                            /></p>
                        </div>
                    </div>
        }

        // Stile css della ModalConfirm
        var customStyles = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: "50%",
                border: "1px solid black",             
              }
        }

        // Inizializza lo stato della ModalConfirm
        this.props.initializeModalConfirm(confirmRedirectTo, getContent, customStyles)
        // Apre la ModalConfirm
        this.setState(({ isEmptyCartModalOpen }) => ({ isEmptyCartModalOpen: true }))
    }
    
    closeModalEmptyCart = () => {
        console.log(this)
        this.setState(({ isEmptyCartModalOpen }) => ({ isEmptyCartModalOpen: false }))
    }

    emptyCart = () => {
        this.props.emptyCart();
    }


    render() {
       // console.log("this.props", this.props)    
        if(this.props.itemInCart && this.props.itemInCart.length > 0) {
            return (
                <div style={{ minWidth: 0, width: "100%"}}>    
                    <ModalConfirm onConfirm={this.emptyCart} closeModal={this.closeModalEmptyCart} iconConfirmBtn="" textConfirmBtn="Conferma" isOpen={this.state.isEmptyCartModalOpen} />                
                    {
                        this.props.completeRequestBtnEnabled === true || this.props.completeRequestBtnEnabled === "true" ?
                            <div className="cartFloatBtns">
                                <button type="submit" onClick={(e) => this.openModalEmptyCart(e)} className="whiteBtn btn" >
                                    <FormattedMessage
                                        id = "CartItem.EmptyCartButton"
                                        defaultMessage="Svuota Carrello"
                                    />
                                </button>
                                <Link to="/cartclose" className="cartCompleteRequest"><button type="submit" disabled={false} onClick={(e) => this.onCompleteRequestClicked(e)} className="redirectBtn orangeBtn btn" >
                                    <FormattedMessage
                                        id = "Cart.CompleteReqButton"
                                        defaultMessage="Completa Richiesta"
                                    />
                                </button></Link>                               
                            </div>
                            :
                            <button type="submit" disabled={true} className="cartCompleteRequestBtn orangeBtn" >Completa Richiesta</button> 
                    }                                                          
                    <div id="CartMainContent" className="cartMainContentContainer">
                        {
                            this.props.formList && this.props.formList.length > 0 ?
                                <div id="CartDetailsForm" className="cartDetailsFormTopContainer">
                                    <p>
                                    <FormattedMessage
                                                    id = "Cart.CartDetailsForm.Title"
                                                    defaultMessage=" Dettagli Macchina CNC"
                                        />                              
                                    </p>    
                                    <form>
                                        { this.getFormToRender() }
                                    </form>                  
                                </div>
                            :
                            <div></div>
                        }
                        
                        <div id="CartItemContainer" className="cartItemTopContainer">
                            {  this.getCartItemsToRender() }
                        </div>                       
                    </div>
                </div>
            )
        }
        else {
            return (<div></div>)
        }
       
    }
  }

  function mapStateToProps(state) {
    // console.log("cart state ", state)
    return {
        itemInCart: state.cart.itemInCart,
        formList: state.cart.formList,
        completeRequestBtnEnabled: state.cart.completeRequestBtnEnabled
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getCartDetailsForm: (dataRequest) => dispatch(getCartDetailsForm(dataRequest)),
        updateItemInCartQty: (item, prevQty) => dispatch(updateItemInCartQty(item, prevQty)),
        removeItemInCart: (item) => dispatch(removeItemInCart(item)),
        getItemAccessories: (dataRequest) => dispatch(getItemAccessories(dataRequest)),
        updateItemInCartAccessories: (itemInCart) => dispatch(updateItemInCartAccessories(itemInCart)),
        onChangeCncFormInput: (formStep, idInput, newValue) => dispatch(onChangeCncFormInput(formStep, idInput, newValue)),
        getItemCncFormAgainstFormValues: (dataRequest) => dispatch(getItemCncFormAgainstFormValues(dataRequest)),
        initializeModalConfirm: (title, getContent, customStyles) => dispatch(initializeModalConfirm(title, getContent, customStyles)),
        emptyCart: () => dispatch(emptyCart())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Cart)