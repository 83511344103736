import React, { Component } from 'react';
import { connect } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import Table from 'react-bootstrap/Table'
import { GetPointConfigurationParameters, GetConfigurationParameters, GetCutterConfigurationParameters } from './../../utils/TrasformDataForApi';
import { createBrowserHistory } from "history";
import ModalConfirm from '../subcomponents/ModalConfirm';
import { getCartCloseForm, getCartCloseTableItems, getItemPrice, getDrawingPdf,
    getModelStep, downloadStepFromUrl, sendEstimateRequestMail, onChangeUserDetFormInput,initializeModalConfirm,
    emptyCart } from '../../actions/index';
import ModalSuccess from '../subcomponents/ModalSuccess';
import {FormattedMessage} from 'react-intl';

const locale = navigator.language;

class CartClose extends Component {
    
    constructor(props){
        super(props)

        this.keyCount = 0;
        this.getKey = this.getKey.bind(this)

        this.state = {
            isDeleteDocModalOpen: false, // Modal richiesta conferma per eliminare la richiesta di preventivo
            isConfirmReqEstimateModalOpen: false, // Modal richiesta conferma per inviare richiesta di preventivo
            isMailSendedModalOpen: false // Modal conferma invio mail avvenuto con successo
        }
    }

    getKey() {
        var newKey = "cartclose" + this.keyCount++
        return newKey;
    }  

    componentDidMount() {
        this.props.getCartCloseForm({ langCode: locale });
        this.props.getCartCloseTableItems()
        let _this = this
        console.log("cart close item in cart mmm", this.props.itemInCart[0])

        for(var x=0; x<this.props.itemInCart.length; x++){
            var confJson = ""

            if(this.props.itemInCart[x].modelConfiguration){

                if(this.props.itemInCart[x].itemGrp.itemGrpCode === "PUNTA"){
                    confJson = GetPointConfigurationParameters(this.props.itemInCart[x])
                }
                else if(this.props.itemInCart[x].itemGrp.itemGrpCode === "FRESA"){
                    confJson = GetCutterConfigurationParameters(this.props.itemInCart[x])
                }
                else {
                    confJson = GetConfigurationParameters(this.props.itemInCart[x])
                }
                
                

                console.log("confJson", confJson)

                var dataReq= {
                    item: this.props.itemInCart[x],
                    jsonClientConf: confJson
                }

                this.props.getItemPrice(dataReq)
                    .then(function(response){
                        // console.log("response", response, response.status)
                    }).catch(function(err){
                        console.log("err",err)
                        let history = createBrowserHistory();
                        history.push("/login", _this.props)
                    })
            }

            if(this.props.itemInCart[x].outputIam && !this.props.itemInCart[x].pdfUrl){
                var getPdfReq = {
                    appBundleName: "ExportDrawing",
                    appBundleLabelVersion: "alpha",
                    activityName: "ExportDrawing",
                    activityLabelVersion: "alpha",
                    bucketName: this.props.itemInCart[x].forgeBucketName,
                    objectName: this.props.itemInCart[x].objectName,
                    assemblyRoot: this.props.itemInCart[x].assemblyRoot,
                    drawingToGenerate: this.props.itemInCart[x].assemblyRoot.replace(".iam",".idw"),
                    outputModelIAM: this.props.itemInCart[x].outputIam,
                    item: this.props.itemInCart[x],
                    rifHoi :  this.props.itemInCart[x].modelConfiguration.rifHoi
                }

                if(this.props.itemInCart[x].itemGrp.itemGrpCode === "PUNTA") {
                    var dt = new Date()
                    var dtFormat = String(dt.getDate()).padStart(2, '0') + "-" + String(dt.getMonth() + 1).padStart(2, '0') + "-" + dt.getFullYear() + " " + dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds()
                    var step1Conf = this.props.itemInCart[x].modelConfiguration.configuration.find(c => c.formTemplateCode === "PNT_STEP1")
                    var step2Conf = this.props.itemInCart[x].modelConfiguration.configuration.find(c => c.formTemplateCode === "PNT_STEP2")
                    var step3Conf = this.props.itemInCart[x].modelConfiguration.configuration.find(c => c.formTemplateCode === "PNT_STEP3")
                    var mainConf = this.props.itemInCart[x].modelConfiguration.configuration.find(c => c.formTemplateCode === "PNT_CONF")
                    var rifCli = ""
                    var toll = ""
                    var tollMin = ""
                    var tollMax = ""
                    var material = ""

                    if(mainConf) {
                        var rifCliC = mainConf.configurationForm.find(cf => cf.formDetailCode === "RIFERIMENTO_CLIENTE")
                        var tollC = mainConf.configurationForm.find(cf => cf.formDetailCode === "Toll")
                        var tollMinC = mainConf.configurationForm.find(cf => cf.formDetailCode === "Toll_Min")
                        var tollMaxC = mainConf.configurationForm.find(cf => cf.formDetailCode === "Toll_Max")
                        var materialC = mainConf.configurationForm.find(cf => cf.formDetailCode === "MAtUt")

                        if(tollC) {
                            toll = tollC.instance.value;
                        }

                        if(tollMinC) {
                            tollMin = tollMinC.instance.value;
                        }

                        if(rifCliC) {
                            rifCli = rifCliC.instance.value;
                        }

                        if(tollMaxC) {
                            tollMax = tollMaxC.instance.value;
                        }

                        if(materialC) {
                            material = materialC.instance.value;
                        }
                    }

                    var jsonParams = "{'Part Number': { 'Value': '" + this.props.itemInCart[x].modelConfiguration.rifHoi  + "'}, 'Stock Number': {'Value': '" +  rifCli + "'},"
                    jsonParams += " 'Description': {'Value': '" +  getPdfReq.objectName + "'}, 'Vendor': { 'Value': '049-7960209' },"
                    jsonParams += " 'Toll': { 'Value': '" + toll + "' }, 'Toll_Min': { 'Value': '" + tollMin + "' }, 'Toll_Max': { 'Value': '" + tollMax + "' }, 'MAtUt': { 'Value': '" + material + "' }"
                    
                    console.log("jsonParams", jsonParams)
                    if(step1Conf){
                        step1Conf.configurationForm.forEach(cf => {
                            jsonParams += " , '" + cf.formDetailCode + "': { 'Value': '" + cf.instance.value + "' }"
                        })
                    }

                    if(step2Conf){
                        step2Conf.configurationForm.forEach(cf => {
                            jsonParams += " , '" + cf.formDetailCode + "': { 'Value': '" + cf.instance.value + "' }"
                        })
                    }

                    if(step3Conf){
                        step3Conf.configurationForm.forEach(cf => {
                            jsonParams += " , '" + cf.formDetailCode + "': { 'Value': '" + cf.instance.value + "' }"
                        })
                    }

                    jsonParams += "}"

                    //console.log("jsonParams", jsonParams)
                    getPdfReq.parametersJson = jsonParams //"{'PartNumber': { 'Value': '" + dtFormat  + "'}, 'Description': {'Value': '" +  getPdfReq.objectName + "'}, 'Vendor': { 'Value': 'Supporto Hoffmann: XXX XXXXXXXXX' }, 'Toll': { 'Value': '1' }, 'Toll_Min': { 'Value': '2' }, 'Toll_Max': { 'Value': '3' }, 'Toll_Max_D1': { 'Value': '3' }}"
                }
                else  if(this.props.itemInCart[x].itemGrp.itemGrpCode === "FRESA") {
                    var dt = new Date()
                    var dtFormat = String(dt.getDate()).padStart(2, '0') + "-" + String(dt.getMonth() + 1).padStart(2, '0') + "-" + dt.getFullYear() + " " + dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds()
 
                    var mainConf = this.props.itemInCart[x].modelConfiguration.configuration.find(c => c.formTemplateCode === "FRS_CONF")
                    var rifCli = ""
                    var toll = ""
                    var tollMin = ""
                    var tollMax = ""
                    var material = ""

                    if(mainConf) {
                        var rifCliC = mainConf.configurationForm.find(cf => cf.formDetailCode === "RIFERIMENTO_CLIENTE")
                        var tollC = mainConf.configurationForm.find(cf => cf.formDetailCode === "Toll")
                        var tollMinC = mainConf.configurationForm.find(cf => cf.formDetailCode === "Toll_Min")
                        var tollMaxC = mainConf.configurationForm.find(cf => cf.formDetailCode === "Toll_Max")
                        var materialC = mainConf.configurationForm.find(cf => cf.formDetailCode === "MAtUt")

                        if(tollC) {
                            toll = tollC.instance.value;
                        }

                        if(tollMinC) {
                            tollMin = tollMinC.instance.value;
                        }

                        if(rifCliC) {
                            rifCli = rifCliC.instance.value;
                        }

                        if(tollMaxC) {
                            tollMax = tollMaxC.instance.value;
                        }

                        if(materialC) {
                            material = materialC.instance.value;
                        }
                    }

                    console.log("material", material)

                    var jsonParams = "{'Part Number': { 'Value': '" + this.props.itemInCart[x].modelConfiguration.rifHoi  + "'}, 'Stock Number': {'Value': '" +  rifCli + "'},"
                    jsonParams += " 'Description': {'Value': '" +  getPdfReq.objectName + "'}, 'Vendor': { 'Value': '049-7960209' },"
                    jsonParams += " 'Toll': { 'Value': '" + toll + "' }, 'Toll_Min': { 'Value': '" + tollMin + "' }, 'Toll_Max': { 'Value': '" + tollMax + "' }, 'MAtUt': { 'Value': '" + material + "' }"
                    
                    console.log("jsonParams", jsonParams)

                    jsonParams += "}"

                    //console.log("jsonParams", jsonParams)
                    getPdfReq.parametersJson = jsonParams //"{'PartNumber': { 'Value': '" + dtFormat  + "'}, 'Description': {'Value': '" +  getPdfReq.objectName + "'}, 'Vendor': { 'Value': 'Supporto Hoffmann: XXX XXXXXXXXX' }, 'Toll': { 'Value': '1' }, 'Toll_Min': { 'Value': '2' }, 'Toll_Max': { 'Value': '3' }, 'Toll_Max_D1': { 'Value': '3' }}"
                }
                else if(this.props.itemInCart[x].itemGrp.itemGrpCode === "PIASTRA") {
                    var dt = new Date()
                    // var dtFormat = String(dt.getDate()).padStart(2, '0') + "-" + String(dt.getMonth() + 1).padStart(2, '0') + "-" + dt.getFullYear() + " " + dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds()
                    getPdfReq.parametersJson = "{'Part Number': { 'Value': '" + this.props.itemInCart[x].modelConfiguration.rifHoi  + "'}, 'Description': {'Value': '" +  getPdfReq.objectName + "'}, 'Vendor': { 'Value': '049-7960209' }}"
                }

                this.props.getDrawingPdf(getPdfReq)
                
            }
            
            if(this.props.itemInCart[x].outputIam && !this.props.itemInCart[x].stepUrl){
                var getModelStepReq = {
                    bucketName: this.props.itemInCart[x].forgeBucketName,
                    forgeIamIdObj: this.props.itemInCart[x].outputIam,
                    assemblyRoot: this.props.itemInCart[x].assemblyRoot,
                    item: this.props.itemInCart[x]
                }

                this.props.getModelStep(getModelStepReq)
            }

            
        }

        
        
    }

    componentDidUpdate() {
        // console.log("cart close componentDidUpdate")
    }


    onDownloadStepClicked = (stepUrl) => {
        this.props.downloadStepFromUrl(stepUrl).then(function(response){
            // console.log("onDownloadStepClicked",response)
            var link = document.createElement('a');
            link.download = 'stepfile.stp';
            var blob = new Blob([response], {type: 'text/plain'});
            link.href = window.URL.createObjectURL(blob); 
            link.click();
        })
    }

    sendEstimateRequestMail = () => {
        var items = []
        var self = this;
        self.setState(({ isConfirmReqEstimateModalOpen }) => ({ isConfirmReqEstimateModalOpen : false}))

        this.props.itemInCart.forEach(i => {
            var itemCopy = JSON.parse(JSON.stringify(i))
            itemCopy.item = i

            items.push(itemCopy)
        })    

        var dataRequest = {
            userDetail: this.props.userDetailForm, 
            cncDetail: this.props.cncForm,
            items: items // ogni oggetto item contiene sia accessori e/o sovrapprezzi
        }

        this.props.sendEstimateRequestMail(dataRequest).then(function(response){
            // console.log("sendEstimateRequestMail",response)

            if(response && response.data && response.data === "SUCCESS"){
                self.setState(({ isMailSendedModalOpen }) => ({ isMailSendedModalOpen : true}))
            }
            else {
                alert("Si è verificato un problema durante l'invio della mail")
            }
        })
    }

    onUserDetFormInputChange = (event, formStep) => {
        event.preventDefault();
        this.props.onChangeUserDetFormInput(formStep, event.target.id, event.target.value)
    }

    transformNumberSeparators(number){

        if(typeof number === "number"){
            number = number.toFixed(2)
        }

        var numberString = new String(number);

        //console.log("number", numberString)

        numberString = numberString.replace(".", ",")

        if(numberString.split(",")[0].length > 6){
            numberString = numberString.slice(0, numberString.split(",")[0].length - 6) + "." + numberString.slice(numberString.split(",")[0].length - 6, numberString.split(",")[0].length - 3) + "." + numberString.slice(numberString.split(",")[0].length - 3)
        }
        else if(numberString.split(",")[0].length > 3){
            numberString = numberString.slice(0, numberString.split(",")[0].length - 3) + "." + numberString.slice(numberString.split(",")[0].length - 3)
        }

        return numberString
    }

    getUserDetailFormToRender = () => {
        // console.log("getUserDetailFormToRender", this.props.userDetailForm)
        var formListToRender = []
        var keyDiv = ""
        var keyInput = ""
        var keyLabel = ""
        var _this = this;

        this.props.userDetailForm.map(function(formStep, index) {

            // console.log("formStep ", formStep)
            keyDiv = "div_" + formStep.formDetailCode + "_" + index;
            keyInput = "input_" + formStep.formDetailCode
            keyLabel = "label_" + formStep.formDetailCode + "_" + index;

            switch(formStep.inputType) {
                case "text":
                    // console.log("formStep.instance.value", formStep.instance.value)
                    if(formStep.dimmed === 1) {
                        formListToRender.push(
                            <div key={keyDiv} className={formStep.divClassName}>
                                <label key={keyLabel} className={formStep.labelClassName} htmlFor={formStep.formDetailCode}>{formStep.inputLabel}</label>
                                <input key={keyInput} type="text" readOnly className={formStep.inputClassName} id={formStep.formDetailCode} name={formStep.formDetailCode} aria-describedby={formStep.inputLabel} defaultValue={formStep.inputDefaultValue} />
                            </div>
                        )
                    }
                    else {
                        formListToRender.push(
                            <div key={keyDiv} className={formStep.divClassName}>
                                <label key={keyLabel} className={formStep.labelClassName} htmlFor={formStep.formDetailCode}>{formStep.inputLabel}</label>
                                <input key={keyInput} type="text" className={formStep.inputClassName} id={formStep.formDetailCode} name={formStep.formDetailCode} aria-describedby={formStep.inputLabel} value={formStep.instance.value || formStep.inputDefaultValue} onChange={(e) => _this.onUserDetFormInputChange(e, formStep)} />
                            </div>
                        )
                    }
                    
                    break;
                    case "select":
                        var options = []
                        var keyOpt = ""
                        formStep.inputValuesList.map(function(selectValue, index){
                            keyOpt = "option_" + formStep.formDetailCode + "_" + index;

                            if(formStep.instance.value === selectValue) {
                                options.push(
                                    <option selected key={keyOpt}>{selectValue}</option>
                                )
                            }
                            else {
                                options.push(
                                    <option key={keyOpt}>{selectValue}</option>
                                )
                            }
                            
                        })

                        formListToRender.push(
                            <div key={keyDiv} className={formStep.divClassName}>
                                <label key={keyLabel} className={formStep.labelClassName} htmlFor={formStep.formDetailCode}>{formStep.inputLabel}</label>
                                <select key={keyInput} className={formStep.inputClassName} id={formStep.formDetailCode} onChange={(e) => _this.onUserDetFormInputChange(e, formStep)}>
                                    { options }
                                </select>
                            </div>
                        )
                        break;
                    case "checkbox":
                        formListToRender.push(
                            <div key={keyDiv} className={formStep.divClassName}>
                                <input key={keyInput} className={formStep.inputClassName} type="checkbox" value={formStep.formDetailCode} id={formStep.formDetailCode} onChange={(e) => _this.onUserDetFormInputChange(e, formStep)} />
                                <label key={keyLabel} className={formStep.labelClassName} htmlFor={formStep.formDetailCode}>
                                    {formStep.inputLabel}
                                </label>
                            </div>
                        );
                        break;
                    default:
                        break;
                }
            })

            // console.log("formListToRender", formListToRender)
            return formListToRender;
    }

    /* MODAL ELIMINA DOCUMENTO */
    openModalDeleteDoc = (e) => {
        e.preventDefault();

        var confirmRedirectTo = "/catalog" // redirezione su /catalog se utente conferma

         // Funzione che restituisce il markup da settare come contenuto della modal
         var getContent = function() {         
            return  <div className="modalConfirmContentTopDiv">
                        <h3>
                            <FormattedMessage
                                id = "CartClose.ModalEmptyCart.Title"
                                defaultMessage="Conferma Azione"
                            />
                        </h3>
                        <div className="modalConfirmTextDiv">
                            <p>
                                <FormattedMessage
                                    id = "CartClose.ModalDeleteDoc.P1"
                                    defaultMessage="Confermi di voler eliminare la richiesta di Preventivo?"
                                />                             
                            </p>
                        </div>
                    </div>
        }

        // Stile css della ModalConfirm
        var customStyles = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: "50%",
                border: "1px solid black",             
              }
        }

        // Inizializza lo stato della ModalConfirm
        this.props.initializeModalConfirm(confirmRedirectTo, getContent, customStyles)
        // Apre la ModalConfirm
        this.setState(({ isDeleteDocModalOpen }) => ({ isDeleteDocModalOpen: true }))
    }

    closeModalDeleteDoc = () => {
        this.setState(({ isDeleteDocModalOpen }) => ({ isDeleteDocModalOpen: false }))
    }

    deleteDocument = () => {
        this.setState(({ isDeleteDocModalOpen }) => ({ isDeleteDocModalOpen: false }))
        this.props.emptyCart();
    }

    /* MODAL CONFERMA RICHIESTA PREVENTIVO DOCUMENTO */
    openModalReqEstimate = (e) => {
        e.preventDefault();
        
        var formInputNotCompiled = this.props.userDetailForm.find(fd => (typeof fd.instance.value === "undefined" || !fd.instance.value || fd.instance.value === "") && fd.mandatory == 1 )
        if(formInputNotCompiled){
            if(locale === "it") {
                alert("Compilare i campi richiesti")               
            }
            else {
                alert("Set mandatory fields")
            }
            
            return
        }

        var itemPdfNotReady = this.props.itemInCart.find(i => typeof i.pdfUrl === "undefined" || !i.pdfUrl || i.pdfUrl === "")
        if(itemPdfNotReady){
            if(locale === "it") {
                alert("Attendere la creazione dei PDF")               
            }
            else {
                alert("Wait for PDF creation")
            }
            
                   
            return
        }

        var confirmRedirectTo = undefined // nessun redirect

         // Funzione che restituisce il markup da settare come contenuto della modal
         var getContent = function() {         
            return  <div className="modalConfirmContentTopDiv">
                        <h3>
                            <FormattedMessage
                                id = "CartClose.ModalReqEstimate.Title"
                                defaultMessage="Conferma Azione"
                            />
                        </h3>
                        <div className="modalConfirmTextDiv">
                            <p>Confermi di voler inoltrare la richiesta di Preventivo?</p>
                        </div>
                    </div>
        }

        // Stile css della ModalConfirm
        var customStyles = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: "50%",
                border: "1px solid black",             
              }
        }

        // Inizializza lo stato della ModalConfirm
        this.props.initializeModalConfirm(confirmRedirectTo, getContent, customStyles)
        // Apre la ModalConfirm
        this.setState(({ isConfirmReqEstimateModalOpen }) => ({ isConfirmReqEstimateModalOpen: true }))
    }

    closeModalReqEstimate = () => {
        this.setState(({ isConfirmReqEstimateModalOpen }) => ({ isConfirmReqEstimateModalOpen: false }))
    }

    emptyCart = () => {
        this.setState(({ isMailSendedModalOpen }) => ({ isMailSendedModalOpen: false }))
        this.props.emptyCart();
    }

    render() {

        const itemTableColumns = []
        const itemTableRows = []
        var total = 0
        var availabilityColor = "green"

        // header tabella articoli
        for (let i = 0; i < this.props.itemTableColumns.length; i++) {
            let keyOpt = "itemTableH_" + i
            itemTableColumns.push( <td key={keyOpt}>
                <FormattedMessage
                    id = {this.props.itemTableColumns[i].columnName}
                    defaultMessage=""
                />
                
            </td> );
        }

        // rows tabella articoli
        // console.log("this.props.itemTableRows", this.props.itemTableRows)
        // console.log("this.props.itemInCart", this.props.itemInCart)

        for (let i = 0; i < this.props.itemInCart.length; i++) {
            let keyOptR = "itemTableR_" + i
            var date = null
            var pdfDwnld = <span></span>
            var stepDwnld = <span></span>

            if(this.props.itemInCart[i].deliveryDate) {
                date = new Date(this.props.itemInCart[i].deliveryDate)
            }

            if(this.props.itemInCart[i].pdfUrl) {
                pdfDwnld = <td><a className="dwnlPdfLink" target="_blank" href={this.props.itemInCart[i].pdfUrl} ><i role="img" className="bi bi-cloud-download"></i></a></td>
            } 
            else {
                pdfDwnld = <td><i role="img" className="bi bi-stopwatch dwnlPdfWait"></i></td>
            }

            if(this.props.itemInCart[i].stepUrl) {
                stepDwnld = <td><button className="dwnlStepBtn" onClick={(e) => this.onDownloadStepClicked(this.props.itemInCart[i].stepUrl)}> <i role="img" className="bi bi-cloud-download"></i></button> </td>
            }
            else {
                stepDwnld = <td><i role="img" className="bi bi-stopwatch dwnlStepWait"></i></td>
            }
            
            try {
                var priceString = this.transformNumberSeparators(this.props.itemInCart[i].price)
                var totalString = this.transformNumberSeparators(this.props.itemInCart[i].total)     
                var itemCode = this.props.itemInCart[i].itemGrp.itemGrpCode === "PIASTRA" ? this.props.itemInCart[i].modelConfiguration.rifHoi : this.props.itemInCart[i].itemCode

                if(this.props.itemInCart[i].price < 0 || this.props.itemInCart[i].total < 0) {
                    priceString = totalString = "a richiesta"
                    availabilityColor = "#FF7000"
                    itemCode = "-"
                }
            }
            catch(e){
                console.log(e)
                priceString = totalString = "a richiesta"
                availabilityColor = "#FF7000"
            }

            let cells = [
                <td key={keyOptR + "_C1"}> <div className="availabilityCircle" style={{backgroundColor: availabilityColor }}></div> </td>,
                // <td key={keyOptR + "_C2"}>{this.props.itemInCart[i].modelConfiguration.rifHoi === "" ? this.props.itemInCart[i].itemCode : this.props.itemInCart[i].modelConfiguration.rifHoi }</td>,
                <td key={keyOptR + "_C2"}>{itemCode}</td>,
                <td key={keyOptR + "_C3"} style={{maxWidth:"15vw"}}>{this.props.itemInCart[i].configurationDescr}</td>,
                <td key={keyOptR + "_C4"}>{this.props.itemInCart[i].um.umCode}</td>,
                <td key={keyOptR + "_C5"}><p style={{margin:"0", width:"50%", textAlign:"right"}}>{this.props.itemInCart[i].Qty}</p></td>,
                <td key={keyOptR + "_C6"}><p style={{margin:"0", width:"50%", textAlign:"right"}}>{priceString}</p></td>,
                <td key={keyOptR + "_C7"}><p style={{margin:"0", width:"50%", textAlign:"right"}}>0,00</p></td>,
                <td key={keyOptR + "_C8"}><p style={{margin:"0", width:"50%", textAlign:"right"}}>{totalString}</p></td>,
                pdfDwnld,
                stepDwnld
            ]

            total += parseFloat(this.props.itemInCart[i].total)

            itemTableRows.push(<tr key={keyOptR}> {cells} </tr>)           

            // accessori
            if(this.props.itemInCart[i].accessories) {

                // console.log("this.props.itemInCart[i].accessories", this.props.itemInCart[i].accessories)

                for (let j = 0; j < this.props.itemInCart[i].accessories.length; j++) {
                    let keyOptR_Acc = "itemTableR_Acc_" + i
                    var accessoryGrp = this.props.itemInCart[i].accessories[j]
                    let qtySelected = parseInt(accessoryGrp.accessoryGrpForm[0].instance.value)
                    let accesSelected = accessoryGrp.accessories.find(a => a.accessoryCode === accessoryGrp.accessoryGrpForm[1].instance.value)

                    if(accesSelected && qtySelected && qtySelected > 0){
                        try {
                            var priceString = this.transformNumberSeparators(accesSelected.pricelistPrice)
                            var totalString = this.transformNumberSeparators(qtySelected * accesSelected.pricelistPrice) 
            
                        }
                        catch(e){
            
                        }

                        let cellsAcc = [
                            <td key={keyOptR_Acc + "_C1"}> <div className="availabilityCircle" style={{backgroundColor: availabilityColor }}></div> </td>,
                            <td key={keyOptR_Acc + "_C2"}><p>{accesSelected.accessoryCode}</p></td>,
                            <td key={keyOptR_Acc + "_C3"}><p>{accesSelected.accessoryName}</p></td>,
                            <td key={keyOptR_Acc + "_C4"}><p>{accesSelected.um.umCode}</p></td>,
                            <td key={keyOptR_Acc + "_C5"}><p style={{margin:"0", width:"50%", textAlign:"right"}}>{ qtySelected }</p></td>,
                            <td key={keyOptR_Acc + "_C6"}><p style={{margin:"0", width:"50%", textAlign:"right"}}>{priceString}</p></td>,
                            <td key={keyOptR_Acc + "_C7"}><p style={{margin:"0", width:"50%", textAlign:"right"}}>0,00</p></td>,
                            <td key={keyOptR_Acc + "_C8"}><p style={{margin:"0", width:"50%", textAlign:"right"}}>{ totalString }</p></td>,
                            <td></td>,
                            <td></td>
                        ]

                        total += parseFloat(qtySelected * accesSelected.pricelistPrice)
                        itemTableRows.push(<tr key={keyOptR + j + "_ACC"}> {cellsAcc} </tr>)  
                    }                   
                }
            }

            if(this.props.itemInCart[i].extraCharges){
                for (let j = 0; j < this.props.itemInCart[i].extraCharges.length; j++) {
                    let keyOptR_Ext = "itemTableR_Extra_" + i
                    let qtySelected = this.props.itemInCart[i].Qty // la qta per ogni sovrapprezzo equivale alla qta selezionata per l articolo
                    let extra = this.props.itemInCart[i].extraCharges[j]
                    var itemCode = this.props.itemInCart[i].extraCharges[j].extraChargeName

                    try {
                        var priceString = "" 
                        var totalString = "" 

                        if(extra.price < 0){
                            priceString = "a richiesta"
                            totalString = "a richiesta"
                            availabilityColor = "#FF7000"
                            itemCode = '-'
                        }
                        else {
                            priceString = this.transformNumberSeparators(extra.price)
                            totalString = this.transformNumberSeparators(qtySelected * extra.price) 
                        }
            
                    }
                    catch(e){
            
                    }

                    let cellsAcc = [
                            <td key={keyOptR_Ext + "_C1"}> <div className="availabilityCircle" style={{backgroundColor: availabilityColor }}></div> </td>,
                            <td key={keyOptR_Ext + "_C3"}><p>{itemCode}</p></td>,
                            <td key={keyOptR_Ext + "_C2"}><p>{extra.extraChargeCode}</p></td>,                         
                            <td key={keyOptR_Ext + "_C4"}><p>NR</p></td>,
                            <td key={keyOptR_Ext + "_C5"}><p style={{margin:"0", width:"50%", textAlign:"right"}}>{ qtySelected }</p></td>,
                            <td key={keyOptR_Ext + "_C6"}><p style={{margin:"0", width:"50%", textAlign:"right"}}>{priceString}</p></td>,
                            <td key={keyOptR_Ext + "_C7"}><p style={{margin:"0", width:"50%", textAlign:"right"}}>0,00</p></td>,
                            <td key={keyOptR_Ext + "_C8"}><p style={{margin:"0", width:"50%", textAlign:"right"}}>{ totalString }</p></td>,
                            <td></td>,
                            <td></td>
                    ]

                    total += parseFloat(qtySelected * extra.price)
                    itemTableRows.push(<tr key={keyOptR + j + "_EXTRA"}> {cellsAcc} </tr>)  
                                      
                }
            }

            total = total.toFixed(2)
            var totalString = this.transformNumberSeparators(total)

            if(total < 0){
                totalString = "a richiesta"
            }
            
            itemTableRows.push(<tr key={"totalpricerow"} style={{fontWeight:"500"}}> <td><FormattedMessage id = "CartClose.ItemTable.Footer.Total" defaultMessage="TOTALE" /></td> <td colSpan="6"></td> <td><p style={{margin:"0", width:"50%", textAlign:"right"}}>{ totalString }</p></td> <td colSpan="3"></td> </tr>)  
            
        }
        
        return (
            <div style={{width: "100%", minWidth: "0"}}>
                <ModalConfirm onConfirm={this.deleteDocument} closeModal={this.closeModalDeleteDoc} iconConfirmBtn="" textConfirmBtn="Conferma" isOpen={this.state.isDeleteDocModalOpen} />                
                <ModalConfirm onConfirm={this.sendEstimateRequestMail} closeModal={this.closeModalReqEstimate} iconConfirmBtn="" textConfirmBtn="Conferma" isOpen={this.state.isConfirmReqEstimateModalOpen} />  
                <ModalSuccess onConfirm={this.emptyCart} title = "Operazione Completata"  message="Mail inviata con successo" isOpen={this.state.isMailSendedModalOpen} confirmRedirectTo="/catalog" />              
                <div className="cartCloseFormContainer">
                   <p>Dettagli Utente</p> 
                   <form>
                    { this.getUserDetailFormToRender() }    
                    </form>
                </div>

                <div className="cartCloseItemContainer">
                    <Table striped responsive hover>
                        <thead>
                            <tr>
                                { itemTableColumns }
                            </tr>                                                     
                        </thead>
                        <tbody>
                            { itemTableRows }  
                        </tbody>
                    </Table>
                </div>

                <div className="cartCloseBtnContainer">
                    <button type="submit" onClick={(e) => this.openModalDeleteDoc(e)} className="btn whiteBtn" >
                        <FormattedMessage
                                id = "CartClose.DeleteDocumentButtonLabel"
                                defaultMessage="Elimina"
                        />
                    </button>
                    <button type="submit" className="btn orangeBtn" onClick={(e) => this.openModalReqEstimate(e)}>
                    <FormattedMessage
                                id = "CartClose.SendRequestButtonLabel"
                                defaultMessage="Richiedi Preventivo"
                        />
                    </button>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    // console.log("cart close state ", state)
    return {
        creationDate: state.cartClose.creationDate,
        deliveryDate: state.cartClose.deliveryDate,
        invoiceBp: state.cartClose.invoiceBp,
        destinations: state.cartClose.destinations,
        selectedDestination: state.cartClose.selectedDestination,
        carriages: state.cartClose.carriages,
        selectedCarriage: state.cartClose.selectedCarriage,
        internalRef: state.cartClose.internalRef,
        note: state.cartClose.note,
        itemTableColumns: state.cartClose.itemTableColumns,
        itemTableRows: state.cartClose.itemTableRows,
        itemInCart: state.cart.itemInCart,
        cncForm: state.cart.formList,
        userDetailForm: state.cartClose.userDetailForm
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getCartCloseForm: (dataRequest) => dispatch(getCartCloseForm(dataRequest)),
        getCartCloseTableItems: () => dispatch(getCartCloseTableItems()),
        getItemPrice: (dataRequest) => dispatch(getItemPrice(dataRequest)),
        getDrawingPdf: (dataRequest, successCB) => dispatch(getDrawingPdf(dataRequest, successCB)),
        getModelStep: (dataRequest) => dispatch(getModelStep(dataRequest)),
        downloadStepFromUrl: (stepUrl) => dispatch(downloadStepFromUrl(stepUrl)),
        sendEstimateRequestMail: (dataRequest) => dispatch(sendEstimateRequestMail(dataRequest)),
        onChangeUserDetFormInput: (formStep, idInput, newValue) => dispatch(onChangeUserDetFormInput(formStep, idInput, newValue)),
        initializeModalConfirm: (title, getContent, customStyles) => dispatch(initializeModalConfirm(title, getContent, customStyles)),
        emptyCart: () => dispatch(emptyCart())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CartClose)