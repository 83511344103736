import React, { Component } from 'react';
import { connect } from 'react-redux';
import { viewerCss, viewerJs } from './../shared.js';
import { getForgeOAuthToken, setLoadingSpinnerVisibility, updateModelParameters, getModelConfiguratorForm, toogleMenuButtonClicked, addToCart,
     initializeModalConfirm, updateModelParametersInReducer, onChangeConfiguratorFormInput, getConfigurationDescription } from '../../actions/index';
import Script from 'react-load-script';
import LoadingSpinner from '../subcomponents/LoadingSpinner';
import ModalConfirm from '../subcomponents/ModalConfirm';
import { createBrowserHistory } from "history";
import {FormattedMessage} from 'react-intl';
import { GetConfigurationDetailMarkup } from './../../utils/Utils.js';
import ModalConfiguratorInfo from '../subcomponents/ModalConfiguratorInfo.js';

let Autodesk = null;
const locale = navigator.language;

class ModelConfigurator extends Component {

    constructor(props){
        super(props);

        var self = this;

        this.viewerDiv = React.createRef();
        this.viewer = null;
        this.getKey = this.getKey.bind(this)

        this.state = {
            isConfirmModalOpen: false,
            isCartNotEmptyWarnModalOpen: false,
            isConfigurationInfoModalOpen: false
        }

    }

    getKey() {
        var newKey = "modelconfigurator" + this.keyCount++
        return newKey;
    }  

    componentDidMount() {
        let _this = this      
        console.log("ModelConfigurator componentDidMount", this)

        // In questa pagina chiudo il menu per recuperare spazio
        if(this.props.isMenuOpen === "true" || this.props.isMenuOpen == true) {
            this.props.toogleMenuButtonClicked()
        }

        // Action che inizializza l'oggett formList dello stato di modelconfigurator
        this.props.getModelConfiguratorForm({ iD_Item: this.props.item.iD_Item, LangCode: locale})
            .then(function(response){
                // console.log("getModelConfiguratorForm response", response, response.status)
            }).catch(function(err){
                console.log("err",err)
                let history = createBrowserHistory();
                history.push("/login", _this.props)
            });
    }

    componentDidUpdate(prevProps) {
        // console.log("componentDidUpdate")

        if (Autodesk && (this.props.svf !== prevProps.svf)) {
            Autodesk.Viewing.Document.load(
                this.getSvfUrl(), this.onDocumentLoadSuccess.bind(this), this.onDocumentLoadFail.bind(this)
            );
        }
    }

    componentWillUnmount() {

        // Riapro il menu quando esco da modelconfigurator
        /*
        HOF-33 richiesto da alessandro di tenerlo chiuso
        if(!this.props.isMenuOpen) {
            this.props.toogleMenuButtonClicked()
        }
        */


    }


    handleScriptLoad() {
        // console.log("handleScriptLoad")
        this.props.setLoadingSpinnerVisibility(true);
        var self = this;

        var oauthCallback = function(token) {
                // console.log("token",token)
                const options = { 
                    env: 'AutodeskProduction',
                    accessToken: token
                }
    
                Autodesk = window.Autodesk;   
                // const container = self.viewerDiv.current;
                const container = document.getElementById("ForgeViewer");
               
                self.viewer = new Autodesk.Viewing.GuiViewer3D(container);
 
                // uncomment this for Viewer debugging
                //self.viewer.debugEvents(true);   
                Autodesk.Viewing.Initializer(options, self.handleViewerInit.bind(self));
        }

        if(this.props.accessToken === ""){
            self.props.getForgeOAuthToken((res) => {
                // console.log("getForgeOAuthToken", res)
                 oauthCallback(res.data.dictionary.access_token)
            })
        }
        else {
            oauthCallback(this.props.accessToken)
        }
            
        
    }

    handleViewerInit() {
        // console.log("handleViewerInit", this.viewer)

        const errorCode = this.viewer.start();
        if (errorCode)
        {
            console.log("*** errorCode", errorCode);
            return;
        }
            
        // orient camera in the same way as it's on the thumbnail
        // corresponding to ViewOrientationTypeEnum.kIsoTopRightViewOrientation
        const viewer = this.viewer;
        
        this.viewer.addEventListener(Autodesk.Viewing.EXTENSION_LOADED_EVENT, (event) => {
            //console.log("EXTENSION_LOADED_EVENT", event.extensionId)
            const viewCubeExtensionId = "Autodesk.ViewCubeUi";

            // this is not perfect, because the view transition is visible, so it's a place to improve someday
            if (event.extensionId === viewCubeExtensionId) {

                const viewCubeUI = event.target.getExtension(viewCubeExtensionId);
                viewCubeUI.setViewCube("front top right");

                viewer.removeEventListener(Autodesk.Viewing.EXTENSION_LOADED_EVENT);
            }

        });

        // skip loading of svf when there is no active project svf
        if (!this.props.svf)
        {
            //console.log("!!!!!this.props.svf",this.props.svf)
            return;
        }

        Autodesk.Viewing.Document.load(this.getSvfUrl(), this.onDocumentLoadSuccess.bind(this),this.onDocumentLoadFail.bind(this));
    }

    getSvfUrl() {
        console.log("this.props.svf", this.props.svf + "/bubble.json")
        return this.props.svf + "/bubble.json";
    }

    onDocumentLoadSuccess(viewerDocument) {
        var self = this;
        // console.log("*** onDocumentLoadSuccess", viewerDocument)
        if(viewerDocument.myPath === self.getSvfUrl()){
            const defaultModel = viewerDocument.getRoot().getDefaultGeometry();
            this.viewer.loadDocumentNode(viewerDocument, defaultModel).then(function(){
            self.props.setLoadingSpinnerVisibility(false);
            });
        }
    }

    onDocumentLoadFail(errorCode, error) {
        // console.log("*** onDocumentLoadFail", errorCode, error)
        this.props.setLoadingSpinnerVisibility(false);
    }

    updateModel(event) {
        event.preventDefault();
        // console.log("***updateModel this.props.modelConfig", this.props.modelConfig)
        var parameters = "{"
        var paramForReducer = []

        this.props.modelConfig.configuration[0].configurationForm.forEach(confInput => {

            // console.log("confInput", confInput)

            /*
                TODO
                    aggiungere la gestione per tutti i tipi di input, non solo text/select

            */
            if(confInput.inputType === "text" || confInput.inputType === "select"){
                // console.log(document.getElementById(confInput.formDetailCode).value)
                parameters = parameters + " \"" + confInput.formDetailCode + "\" : { \"Value\": \""  + document.getElementById(this.props.modelConfig.configuration[0].formTemplateCode + "__" + confInput.formDetailCode).value + "\" }  ,"                    
                paramForReducer.push({ formDetailCode:  confInput.formDetailCode, value: document.getElementById(this.props.modelConfig.configuration[0].formTemplateCode + "__" + confInput.formDetailCode).value })
            }

            if(confInput.inputType === "checkbox"){
                console.log(document.querySelector('#' + this.props.modelConfig.configuration[0].formTemplateCode + "__" + confInput.formDetailCode + ':checked'))

                if(document.querySelector('#' + this.props.modelConfig.configuration[0].formTemplateCode + "__" + confInput.formDetailCode + ':checked')) {
                    parameters = parameters + " \"" + confInput.formDetailCode + "\" : { \"Value\": \""  + "True" + "\" }  ,"                    
                    paramForReducer.push({ formDetailCode:  confInput.formDetailCode, value: "True" })
                }
                else {
                    parameters = parameters + " \"" + confInput.formDetailCode + "\" : { \"Value\": \""  + "False" + "\" }  ,"                    
                    paramForReducer.push({ formDetailCode:  confInput.formDetailCode, value: "False" })
                }
                
            }

            console.log(parameters)
            
        })

        parameters = parameters.slice(0, -1)
        parameters = parameters + " } "

        // console.log("parameters", JSON.parse(parameters))
        
        var dataRequest = {
            appBundleName: "UpdateParameters",
            appBundleLabelVersion: "alpha",
            activityName: "UpdateProject",
            activityLabelVersion: "alpha",
            bucketName: this.props.modelConfig.forgeBucketName,
            objectName: this.props.modelConfig.forgeObjectName,
            assemblyRoot: this.props.modelConfig.assemblyRoot,
            parameters: JSON.parse(parameters)
        }

        this.props.setLoadingSpinnerVisibility(true);

        this.props.updateModelParameters(dataRequest, (updResp) => { 
            //console.log("updResp", updResp)
            var outputParameters = JSON.parse(updResp.UpdateParametersOutput)
            console.log("paramOut", outputParameters)
            // this.props.updateModelParametersInReducer(paramForReducer)   
            this.props.updateModelParametersInReducer(outputParameters)    
        });
    }

    // Aggiunge articolo (prelevato dallo stato di modelconfigurator) a carrello
    addItemInCart = (e) => {
        // console.log("***** addItemInCart ***** ", this.props.item)
        e.preventDefault();
        var item = this.props.item

        item.modelConfiguration = this.props.modelConfig;
        item.outputIam = this.props.outputIam; // identificativo dello iam nel bucket forge. Utilizzato per generare pdf.
        item.forgeBucketName = this.props.modelConfig.forgeBucketName
        item.objectName = this.props.modelConfig.forgeObjectName
        item.assemblyRoot = this.props.modelConfig.assemblyRoot
        
        this.props.addToCart(item)
        // recupero descrizione in base a configurazione
        // setta nel modello dell item la descrizione in base alla configurazione        
        var dataRequest = JSON.stringify({Item: item, ModelConfiguration: item.modelConfiguration })   
        this.props.getConfigurationDescription(dataRequest)
        this.setState(({ isConfirmModalOpen }) => ({ isConfirmModalOpen: false }))
    }

    /*
        Controlla se sono già presenti articoli a carrello. In caso affermativo, apre la modal che avvisa l utente. In caso contrario apre la modal per confermare la configurazione scelta.
    */
    openModal = (e) => {
        e.preventDefault();

        if(this.props.itemInCart.length > 0){
            this.openModalCartNotEmptyWarn();
        }
        else {
            this.openModalConfirm();
        }
    }

    openModalConfiguratorInfo = (e) => {
        this.setState(({ isConfigurationInfoModalOpen }) => ({ isConfigurationInfoModalOpen: true }))
    }

    /*
        Inizializza lo stato di ModalConfirm e apre la modal
        Apre la modal per visualizzare ed eventualmente confermare la configurazione scelta
    */
    openModalConfirm = () => {

        this.setState(({ isCartNotEmptyWarnModalOpen }) => ({ isCartNotEmptyWarnModalOpen: false }))

        var confirmRedirectTo = "/cart" // redirezione su /cart se utente conferma
        var config = GetConfigurationDetailMarkup(this.props.modelConfig.configuration[0].configurationForm) // configurazione del modello da visualizzare // configurazione del modello da visualizzare

        // Funzione che restituisce il markup da settare come contenuto della modal
        var getContent = function() {         
            return  <div id="modalConfirmConfigTopContainer" className="modalConfirmContentTopDiv">
                        <h3><FormattedMessage
                                id = "ModelConfigurator.ConfirmConfiguration"
                                defaultMessage="Conferma Configurazione"
                            /></h3>
                        <div id="modalConfirmConfigDiv" className="modalConfirmConfigDiv">
                            {config }
                        </div>
                    </div>
        }

        // Stile css della ModalConfirm
        var customStyles = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: "60%",
                border: "1px solid black",             
              }
        }

        // Inizializza lo stato della ModalConfirm
        this.props.initializeModalConfirm(confirmRedirectTo, getContent, customStyles)
        // Apre la ModalConfirm
        this.setState(({ isConfirmModalOpen }) => ({ isConfirmModalOpen: true }))
    }

    /*
        Apre la modal che avvisa l utente della presenza di un articolo a carrello. Se conferma, tale articolo a carrello verrà sostituito dall articolo corrente
    */
    openModalCartNotEmptyWarn = () => {

         // Funzione che restituisce il markup da settare come contenuto della modal
         var getContent = function() {         
            return  <div id="modalConfirmConfigTopContainer" className="modalConfirmContentTopDiv">
                        <h3>
                        <FormattedMessage
                                id = "ModelConfigurator.ModalCartNotEmptyWarn.Title"
                                defaultMessage="Attenzione"
                            />
                            </h3>
                        <div className="">
                            <p>
                            <FormattedMessage
                                id = "ModelConfigurator.ModalCartNotEmptyWarn.P1"
                                defaultMessage="Nel carrello è presente un articolo configurato."
                            />
                                </p> 
                            <p>
                            <FormattedMessage
                                id = "ModelConfigurator.ModalCartNotEmptyWarn.P2"
                                defaultMessage="Conferma se desideri sostituirlo con l'articolo corrente."
                            />
                            </p>
                        </div>
                    </div>
        }

        // Stile css della ModalConfirm
        var customStyles = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: "50%",
                border: "1px solid black",             
              }
        }

        // Inizializza lo stato della ModalConfirm
        this.props.initializeModalConfirm("", getContent, customStyles)
        // Apre la ModalConfirm
        this.setState(({ isCartNotEmptyWarnModalOpen }) => ({ isCartNotEmptyWarnModalOpen: true }))
    }

    closeModalConfirm = () => {
        this.setState(({ isConfirmModalOpen }) => ({ isConfirmModalOpen: false }))
    }

    closeModalCartNotEmptyWarn = () => {
        this.setState(({ isCartNotEmptyWarnModalOpen }) => ({ isCartNotEmptyWarnModalOpen: false }))
    }

    closeModalConfigurationInfo = () => {
        this.setState(({ isConfigurationInfoModalOpen }) => ({ isConfigurationInfoModalOpen: false }))
    }

    getFormToRender = () => {
        // console.log(" getFormToRender",  this.props.modelConfig.configuration.configurationForm)
        var _this= this
        var formListToRender = []
        var keyDiv = ""
        var keyInput = ""
        var keyLabel = ""
        
        this.props.modelConfig.configuration[0].configurationForm.map(function(formStep, index) {
            
            var formTemplateCode = _this.props.modelConfig.configuration[0].formTemplateCode

            // console.log("formStep ", formStep)
            keyDiv = "div_" + formStep.formDetailCode + "_" + index;
            keyInput = "input_" + formStep.formDetailCode + "_" + index;
            keyLabel = "label_" + formStep.formDetailCode + "_" + index;
    
            formStep.onChange = function(e) {
                var newValue = e.target.value

                if(e.target.type === "checkbox"){
                    newValue = e.target.checked
                }

                _this.props.onChangeConfiguratorFormInput(e.target.id, newValue)
            }
    
            switch(formStep.inputType) {
                case "text":
                    //console.log("formStep.instance.value", formStep.instance.value)
                    if(formStep.dimmed === 1) {
                        formListToRender.push(
                            <div key={keyDiv} className={formStep.divClassName}>
                                <label key={keyLabel} className={formStep.labelClassName} htmlFor={formStep.formDetailCode}>{formStep.inputLabel}</label>
                                <input key={keyInput} type="text" readOnly className={formStep.inputClassName} id={formTemplateCode + "__" + formStep.formDetailCode} name={formStep.formDetailCode} aria-describedby={formStep.inputLabel} placeholder={formStep.inputLabel} value={ typeof formStep.instance.value === "undefined" ? formStep.inputDefaultValue : formStep.instance.value} />
                            </div>
                        )
                    }
                    else {
                        formListToRender.push(
                            <div key={keyDiv} className={formStep.divClassName}>
                                <label key={keyLabel} className={formStep.labelClassName} htmlFor={formStep.formDetailCode}>{formStep.inputLabel}</label>
                                <input key={keyInput} type="text" className={formStep.inputClassName} id={formTemplateCode + "__" + formStep.formDetailCode} name={formStep.formDetailCode} aria-describedby={formStep.inputLabel} placeholder={formStep.inputLabel} value={ typeof formStep.instance.value === "undefined" ? formStep.inputDefaultValue : formStep.instance.value } onChange={(e) => formStep.onChange(e) } />
                            </div>
                        )
                    }
                    
                    break;
                case "select":
                    var options = []
                    var keyOpt = ""
                    formStep.inputValuesList.map(function(selectValue, index){
                        keyOpt = "option_" + formStep.formDetailCode + "_" + index;                      
                        //console.log("formStep.instance.value", formStep.instance.value)
                        //console.log("selectValue", selectValue)
                        var instanceV = formStep.instance.value
                        // potrebbe essere presente l unita di misura, che elimino
                        instanceV = instanceV.replace(/\D+/g, "")
                        selectValue = selectValue.replace(/\D+/g, "")
                        
                        if(instanceV  === selectValue) {
                            options.push(
                                <option selected key={keyOpt}>{selectValue}</option>
                            )
                        }
                        else {
                            options.push(
                                <option key={keyOpt}>{selectValue}</option>
                            )
                        }
                        
                    })
                    
                    if(formStep.dimmed === 1) {
                        formListToRender.push(
                            <div key={keyDiv} className={formStep.divClassName}>
                                <label key={keyLabel} className={formStep.labelClassName} htmlFor={formStep.formDetailCode}>{formStep.inputLabel}</label>
                                <select key={keyInput} disabled className={formStep.inputClassName} id={formTemplateCode + "__" + formStep.formDetailCode} onChange={(e) => formStep.onChange(e)} >
                                    { options }
                                </select>
                            </div>
                        )
                    }
                    else {
                        formListToRender.push(
                            <div key={keyDiv} className={formStep.divClassName}>
                                <label key={keyLabel} className={formStep.labelClassName} htmlFor={formStep.formDetailCode}>{formStep.inputLabel}</label>
                                <select key={keyInput} className={formStep.inputClassName} id={formTemplateCode + "__" + formStep.formDetailCode} onChange={(e) => formStep.onChange(e)} >
                                    { options }
                                </select>
                            </div>
                        )
                    }
                    
                    break;
                case "checkbox":
                    if(formStep.dimmed === 1) {
                        formListToRender.push(
                            <div key={keyDiv} className={formStep.divClassName}>
                                <input key={keyInput} disabled className={formStep.inputClassName} type="checkbox" value={formStep.formDetailCode} id={formTemplateCode + "__" + formStep.formDetailCode} onChange={(e) => formStep.onChange(e) } />
                                <label key={keyLabel} className={formStep.labelClassName} htmlFor={formStep.formDetailCode}>
                                    {formStep.inputLabel}
                                </label>
                            </div>
                        );
                    }
                    else{
                        if(formStep.instance.value === "checked" || formStep.instance.value === "true" || formStep.instance.value === "True" || formStep.instance.value === true) {
                            formListToRender.push(
                                <div key={keyDiv} className={formStep.divClassName}>
                                    <input key={keyInput} className={formStep.inputClassName} type="checkbox" checked value={formStep.formDetailCode} id={formTemplateCode + "__" + formStep.formDetailCode} onChange={(e) => formStep.onChange(e) } />
                                    <label key={keyLabel} className={formStep.labelClassName} htmlFor={formStep.formDetailCode}>
                                        {formStep.inputLabel}
                                    </label>
                                </div>
                            );
                        }
                        else {
                            formListToRender.push(
                                <div key={keyDiv} className={formStep.divClassName}>
                                    <input key={keyInput} className={formStep.inputClassName} type="checkbox" unchecked value={formStep.formDetailCode} id={formTemplateCode + "__" + formStep.formDetailCode} onChange={(e) => formStep.onChange(e) } />
                                    <label key={keyLabel} className={formStep.labelClassName} htmlFor={formStep.formDetailCode}>
                                        {formStep.inputLabel}
                                    </label>
                                </div>
                            );
                        }
                        
                    }
                    
                    break;
                default:
                    break;
            }
        })
    
         // console.log("formListToRender", formListToRender)
         return formListToRender;
    }

    getFormButtons = () => {
        if(this.props.outputIam && this.props.outputIam !== "") {
            return[
                <div className="modelConfFormBtnContainer">
                    <button key="button_upd" type="button" className="btn btn-primary btnConfUpd" onClick={ (e) => this.updateModel(e) } >Update</button>
                    <button key="button_confirm" type="button" className="btn btn-light btnConfAddCart" onClick={ (e) => this.openModal(e) }><i role="img" className="bi bi-cart-plus"  ></i></button>
                </div>
            ]
        }
        else {
            return[
                <div className="modelConfFormBtnContainer">
                    <button key="button_upd" type="button" className="btn btn-primary btnConfUpd" onClick={ (e) => this.updateModel(e) } >Update</button>
                    <button key="button_confirm" type="button" disabled className="btn btn-light btnConfAddCart" onClick={ (e) => this.openModal(e) }><i role="img" className="bi bi-cart-plus"  ></i></button>
                </div>
            ]
        }
    }

    render() {
        // console.log(this.props)
        if(this.props.modelConfig.configuration){
            
            return(
                <div className="modelConfiguratorMainContent">
                    {this.props.loading &&
                        <LoadingSpinner isVisible="true" />
                    }       
                    {/* Modal che avvisa l utente della presenza di un articolo a carrello */}
                    <ModalConfirm onConfirm={this.openModalConfirm} closeModal={this.closeModalCartNotEmptyWarn} isOpen={this.state.isCartNotEmptyWarnModalOpen} textConfirmBtn="ModelConfigurator.ModalConfirm.ConfirmButtonLabel" /> 
                    {/* Modal per confermare la configurazione corrente e la conseguente aggiunta a carrello (se già presente un articolo a carrello, quest'ultimo verrà sostituito) */}
                    <ModalConfirm onConfirm={this.addItemInCart} closeModal={this.closeModalConfirm} isOpen={this.state.isConfirmModalOpen} iconConfirmBtn="bi bi-cart-plus" />   
                    <ModalConfiguratorInfo closeModal={this.closeModalConfigurationInfo} isOpen={this.state.isConfigurationInfoModalOpen} />        
                    <div className="modelConfiguratorFormDiv">
                        <form>
                            { this.getFormToRender() }
                            { this.getFormButtons() }
                        </form>
                    </div>
                    <link rel="stylesheet" type="text/css" href={ viewerCss } />
                    <Script url={ viewerJs } onLoad={this.handleScriptLoad.bind(this)} />
                    <div className="modelContainer">
                        <div ref={this.viewerDiv} id="ForgeViewer"></div>
                    </div>
                    <button className="btpOpenModalConfiguratorInfo" onClick={(e) => this.openModalConfiguratorInfo()} ><i role="img" className="bi bi-info" ></i></button>
                </div>
            )
        }
        else {
            return(<div></div>)
        }
        
    }
}

function mapStateToProps(state) {
    // console.log("model configurator state ", state)
    return {
        svf: state.modelconfigurator.svf,
        accessToken: state.modelconfigurator.accessToken,
        modelConfig: state.modelconfigurator.modelConfig,
        loading: state.modelconfigurator.loading,
        isMenuOpen: state.menu.isMenuOpen,
        item: state.modelconfigurator.item,
        outputIam: state.modelconfigurator.outputIam,
        itemInCart: state.cart.itemInCart
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getForgeOAuthToken: (successCB) => dispatch(getForgeOAuthToken(successCB)),
        updateModelParameters: (dataRequest, successCB) => dispatch(updateModelParameters(dataRequest, successCB)),
        setLoadingSpinnerVisibility: (isVisible) => dispatch(setLoadingSpinnerVisibility(isVisible)),
        getModelConfiguratorForm: (dataRequest) => dispatch(getModelConfiguratorForm(dataRequest)),
        toogleMenuButtonClicked: () => dispatch(toogleMenuButtonClicked()),
        addToCart: (item) => dispatch(addToCart(item)),
        initializeModalConfirm: (title, getContent, customStyles) => dispatch(initializeModalConfirm(title, getContent, customStyles)),
        updateModelParametersInReducer: (parameters) => dispatch(updateModelParametersInReducer(parameters)),
        onChangeConfiguratorFormInput: (idInput, newValue) => dispatch(onChangeConfiguratorFormInput(idInput, newValue)),
        getConfigurationDescription: (dataRequest) => dispatch(getConfigurationDescription(dataRequest)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModelConfigurator)