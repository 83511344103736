import React, { Component } from 'react';
import OrderCard from '../subcomponents/OrderCard';
import { getOrders, toogleDraftOrdersContainer, toogleInProgressOrdersContainer, toogleHistoryOrdersContainer } from '../../actions/index';
import { connect } from 'react-redux';

class Orders extends Component {

    constructor(props) {
        super(props)
        
        this.keyCount = 0;
        this.getKey = this.getKey.bind(this)
    }

    getKey() {
        var newKey = "orders" + this.keyCount++
        return newKey;
    }  

    componentDidMount() {
        this.props.getOrders()
        console.log("Orders ", this.props)
    }

    componentDidUpdate() {
        // console.log("BpList componentDidUpdate ", this.props)
    }

    onOrderElementClicked = (item) => {
       // console.log("onBpListElementClicked ", item)       
    }

    onToogleDraftOrdersContainer = () => {
        this.props.toogleDraftOrdersContainer()
    }

    onToogleInProgressOrdersContainer = () => {
        this.props.toogleInProgressOrdersContainer()
    }

    onToogleHistoryOrdersContainer = () => {
        this.props.toogleHistoryOrdersContainer()
    }

    render() {
        const orderCardsDraft = [];
        const orderCardsInProgress = [];
        const orderCardsHistory = [];
        // console.log("this.props", this.props)
        for (let i = 0; i < this.props.draft.length; i++) {
            orderCardsDraft.push( <OrderCard key={this.getKey()} item={this.props.draft[i]} onClick={(e) => this.onOrderElementClicked(this.props.draft[i])} deletable={true} />);
        }
        for (let j = 0; j < this.props.inProgress.length; j++) {
            orderCardsInProgress.push( <OrderCard key={this.getKey()} item={this.props.inProgress[j]} onClick={(e) => this.onOrderElementClicked(this.props.inProgress[j])} />);
        }
        for (let k = 0; k < this.props.history.length; k++) {
            orderCardsHistory.push( <OrderCard key={this.getKey()} item={this.props.history[k]} onClick={(e) => this.onOrderElementClicked(this.props.history[k])} />);
        }
        
        return(
            <div className="ordersContainer">

                <div className="draftOrdersContainer">
                    <div className="orderCardsContainerHeader">
                        <p>Bozze di preventivo</p>
                        {this.props.draftContainerOpen &&
                            <button className="card-link btn"><i role="img" className="bi bi-chevron-double-down" onClick={(e) => this.onToogleDraftOrdersContainer()} ></i></button>
                        }
                        {!this.props.draftContainerOpen &&
                            <button className="card-link btn"><i role="img" className="bi bi-chevron-double-up" onClick={(e) => this.onToogleDraftOrdersContainer()} ></i></button>
                        }
                    </div>
                    <div className="orderCardsContainerContent" style={{ display: this.props.draftContainerOpen ? "flex" : "none" }}>
                        { orderCardsDraft }
                    </div>
                </div>
                
                <div className="inProgressOrdersContainer">
                    <div className="orderCardsContainerHeader">
                        <p>Preventivi</p>
                        {this.props.inProgressContainerOpen &&
                            <button className="card-link btn"><i role="img" className="bi bi-chevron-double-down" onClick={(e) => this.onToogleInProgressOrdersContainer()} ></i></button>
                        }
                        {!this.props.inProgressContainerOpen &&
                            <button className="card-link btn"><i role="img" className="bi bi-chevron-double-up" onClick={(e) => this.onToogleInProgressOrdersContainer()} ></i></button>
                        }
                    </div>
                    <div className="orderCardsContainerContent" style={{ display: this.props.inProgressContainerOpen ? "flex" : "none" }}>
                        { orderCardsInProgress }
                    </div>
                </div>

                <div className="historyOrdersContainer">
                    <div className="orderCardsContainerHeader">
                        <p>Storico</p>
                        {this.props.historyContainerOpen &&
                            <button className="card-link btn"><i role="img" className="bi bi-chevron-double-down" onClick={(e) => this.onToogleHistoryOrdersContainer()} ></i></button>
                        }
                        {!this.props.historyContainerOpen &&
                            <button className="card-link btn"><i role="img" className="bi bi-chevron-double-up" onClick={(e) => this.onToogleHistoryOrdersContainer()} ></i></button>
                        }
                    </div>
                    <div className="orderCardsContainerContent" style={{ display: this.props.historyContainerOpen ? "flex" : "none" }}>
                        { orderCardsHistory }
                    </div>
                </div>
            </div>           
        )
    }
}

function mapStateToProps(state){
    // console.log("state", state)
    return {
        draft: state.orders.draft,
        inProgress: state.orders.inProgress,
        history: state.orders.history,
        draftContainerOpen: state.orders.draftContainerOpen,
        inProgressContainerOpen: state.orders.inProgressContainerOpen,
        historyContainerOpen: state.orders.historyContainerOpen,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getOrders: (dataRequest) => dispatch(getOrders(dataRequest)),
        toogleDraftOrdersContainer: () => dispatch(toogleDraftOrdersContainer()),
        toogleInProgressOrdersContainer: () => dispatch(toogleInProgressOrdersContainer()),
        toogleHistoryOrdersContainer: () => dispatch(toogleHistoryOrdersContainer())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Orders);