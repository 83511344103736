import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { selectCatalogBreadcrumbStep } from '../../actions/index';

class CatalogBreadcrumb extends Component {

    constructor(props){
        super(props)

        this.keyCount = 0;
        this.getKey = this.getKey.bind(this)
    }

    getKey() {
        var newKey = "catalogBr" + this.keyCount++
        return newKey;
    }  

    onStepClick = (step) => {
        this.props.selectCatalogBreadcrumbStep(step)
    }

     // � necessario passare il riferimento a this in quanto all'interno della callback della funzione map il
    // this corrisponde a quello della funzione anonima
    getSteps = (_this) => {
        //if (_this.props.auth) {
            console.log("getSteps", this.props.steps)
            return this.props.steps.map(function (step, index) {
                if (index < _this.props.steps.length - 1) {
                    return <li key={_this.getKey()} className="breadcrumb-item catalogBreadcrumbLi" style={{ float: "left", display: "inline-block" }} step={step} onClick={(e) => _this.onStepClick(step)}> <a href="#"> {step.item.catalogName}</a>  </li>
                }
                else {
                    return <li key={_this.getKey()} className="breadcrumb-item catalogBreadcrumbLi catalogBreadcrumbLiActive" style={{ float: "left", display: "inline-block" }} aria-current="page" step={step} onClick={(e) => _this.onStepClick(step)}> {step.item.catalogName} </li>
                }
            })
        //}
    }

    render() {
        return (
            <div className="catalogBreadcrumbContainer">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb" style={{marginBottom: '0px'}}>
                        {this.getSteps(this)}
                    </ol>
                </nav>
            </div>          
        )
    }
}

function mapStateToProps(state) {
    // console.log("breadcrumb state ",state)
    return {
        steps: state.catalogBreadcrumb.steps
    }
}

function mapDispatchToProps(dispatch) {
    return {
        selectCatalogBreadcrumbStep: (step, currBreadcrumb, history, callback) => dispatch(selectCatalogBreadcrumbStep(step, currBreadcrumb, history, callback))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CatalogBreadcrumb);