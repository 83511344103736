import { SCROLL_EVENT } from "../actions/types";

const INITIAL_STATE = {
    scrollOffsetY : 0,
    scrollOffsetX : 0
}

export default function (state = INITIAL_STATE, action) {
    switch(action.type) {
        case SCROLL_EVENT:
            return {...state, scrollOffsetY: action.payload.scrollOffsetY, scrollOffsetX: action.payload.scrollOffsetX}
        default:
            return state;
    }
}