import React, { useState  } from 'react';
import { connect } from 'react-redux';
import { changePassword, doLogout } from '../../actions/index';
import { Navigate, useNavigate } from "react-router-dom";
import {FormattedMessage, useIntl, injectIntl} from 'react-intl';

function ChangePassword(props) {
    
    const [errCredential, setErrCredential] = useState(false)
    const [oldPsw, setOldPswInputVal] = useState("")
    const [newPsw, setNewPswInputVal] = useState("")
    const [confirmPsw, setConfirmPswInputVal] = useState("")

    const intl = useIntl()

    let navigate = useNavigate();
    //props.doLogout();

    let inputChangedOldPswHandler = (e) => {
        setOldPswInputVal(e.target.value)
    }

    let inputChangedNewPswHandler = (e) => {
        setNewPswInputVal(e.target.value)
    }

    let inputChangedConfirmPswHandler = (e) => {
        setConfirmPswInputVal(e.target.value)

        if(newPsw !== e.target.value) {
            setErrCredential(true)
        }
        else {
            setErrCredential(false)
        }
    }

    let changePasswordClicked = (e) => {
        console.log(props, this)
        var res = props.changePassword({ id_User: props.id_User, oldPsw: oldPsw, newPsw: newPsw});

        if(res && res.response && (res.response.status === 401 || res.response.status === "401")) {
            setErrCredential(true)
        }
        else {
            props.doLogout();
            navigate('/');
        }
    }

    // console.log(props)
        return (
            <div id="changePsw-main-div">
                <div class="changePswContent ">
                    <div class="changePswHeaderDiv">
                        <h3>
                            <FormattedMessage
                                id = "ChangePassword.Form.Title"
                                defaultMessage="Cambio Password"
                            />
                            
                        </h3>  
                    </div>
                    <div key="changePsw-form-old-psw" className="">
                                        <input key="changePsw-form-old-psw-input" className="" onChange={(e) => inputChangedOldPswHandler(e)} type="password"  id="changePsw-form-old-psw-input" aria-describedby="Vecchia Password" 
                                        placeholder={intl.formatMessage({
                                        id: "ChangePassword.Form.OldPsw.Placeholder",
                                        defaultMessage: 'Vecchia Password',
                                    })} 
                                    defaultValue={oldPsw}/>                 
                    </div>
                    <div key="changePsw-form-psw" className="">
                        <input key="changePsw-form-psw-input" className="" onChange={(e) => inputChangedNewPswHandler(e)} type="password"  id="changePsw-form-psw-input" aria-describedby="Nuova Password" 
                            placeholder={intl.formatMessage({
                                id: "ChangePassword.Form.NewPsw.Placeholder",
                                defaultMessage: 'Nuova Password',
                            })} 
                             defaultValue={newPsw}/>                 
                    </div>
                    <div key="changePsw-form-confirm-psw" className="">
                        <input key="changePsw-form-confirm-psw-input" className="" onChange={(e) => inputChangedConfirmPswHandler(e)} type="password"  id="changePsw-form-confirm-psw" aria-describedby="Conferma Password" 
                            placeholder={intl.formatMessage({
                                id: "ChangePassword.Form.ConfirmNewPsw.Placeholder",
                                defaultMessage: 'Conferma Password',
                            })} 
                            value={confirmPsw}/>                 
                    </div>
                    <div id="changePswErrorDiv" style={{display: errCredential ? 'block' : 'none', color: 'red', fontSize:'1rem'}}>
                        <p>
                        <FormattedMessage
                                id = "ChangePassword.PswsDontMatch.Message"
                                defaultMessage="Le password non corrispondono."
                            />
                            
                        </p>
                    </div>
                    <button type="submit" className="btn btn-primary" onClick={async (e) => changePasswordClicked(e) } >
                    <FormattedMessage
                                id = "ChangePassword.ChnPswButtonLabel"
                                defaultMessage=" Cambia Password."
                            />
                           
                    </button>
                    
                </div>
           </div>
        )

}

function mapStateToProps(state) {
    console.log("cart state ", state)
    return {
        id_User: state.loggedUser.id_User
    }
}

function mapDispatchToProps(dispatch) {
    return {
        changePassword: (dataRquest) => dispatch(changePassword(dataRquest)),
        doLogout: () => dispatch(doLogout())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)
