import React, { Component } from 'react';
import CatalogCard from '../subcomponents/CatalogCard';
import CatalogBreadcrumb from '../subcomponents/CatalogBreadcrumb';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import {
    getCatalogFirstLevel,
    getCatalogNextLevel,
    setModelConfiguratorItem,
    refreshToken
  } from '../../actions/index';
  import { createBrowserHistory } from "history";



  const locale = navigator.language;

class Catalog extends Component {

    constructor(props) {
        super(props);

        this.keyCount = 0;
        this.getKey = this.getKey.bind(this)
    }


    getKey() {
        var newKey = "catalog" + this.keyCount++
        return newKey;
    }  

    componentDidMount() {
        // console.log("componentDidMount Catalog this.props", this.props.history)
        let _this = this

        if(window.location.search.includes("selectedBp")) {
            this.props.getCatalogFirstLevel({ bp: { bpCode: "Pippo"}, langCode : locale})
                .then(function(response){
                    // console.log("response", response, response.status)
                }).catch(function(err){
                    console.log("err",err)
                    let history = createBrowserHistory();
                    history.push("/login", _this.props)
                })
        }
        else {

            console.log("locale", locale)
            var catLabel = "Catalogo"
            if(locale !== "it"){
                catLabel = "Catalogue"
            }

            this.props.getCatalogFirstLevel({ bp: { bpCode: catLabel}, langCode : locale})        
                .then(function(response){
                    console.log("response", response, response.status)
                }).catch(function(err){
                    console.log("err",err)
                    let history = createBrowserHistory();
                    history.push("/login", _this.props)
                })
            
        }

        
        
    }

    // click categoria catalogo
    onCatalogElementClicked = (item) => {
        // console.log("onCatalogElementClicked", item)
        let _this = this
        if(window.location.search.includes("selectedBp")) {
            this.props.getCatalogNextLevel({bp: { bpCode: "Pippo"}, item : item, langCode : locale})
                .then(function(response){
                    // console.log("response", response, response.status)
                }).catch(function(err){
                    console.log("err",err)
                    let history = createBrowserHistory();
                    history.push("/login", _this.props)
                })
        }
        else {
            this.props.getCatalogNextLevel({ bp: { bpCode: "Catalogo"}, item : item, langCode : locale})
                .then(function(response){
                    // console.log("response", response, response.status)
                }).catch(function(err){
                    console.log("err",err)
                    let history = createBrowserHistory();
                    history.push("/login", _this.props)
                })
        }       
    }

    // click articolo
    onItemElementClicked = (item) => {
        // console.log("onItemElementClicked", item)     
        // imposto l'articolo che dev'essere configurato, nello stato di modelconfigurator
        this.props.setModelConfiguratorItem(item)
    }

    render() {
        console.log("this.props.itemElements", this.props.itemElements)
        const catalogCards = [];
        // categorie catalogo
        for (let i = 0; i < this.props.catalogElements.length; i++) {
            catalogCards.push( <CatalogCard key={this.getKey()} item={this.props.catalogElements[i]} onClick={(e) => this.onCatalogElementClicked(this.props.catalogElements[i])} />);
        }
        // articoli
        for (let i = 0; i < this.props.itemElements.length; i++) {
            var modelConfPage = "/modelconfigurator"

            if(this.props.itemElements[i].itemGrp.itemGrpCode === "PUNTA"){
                modelConfPage = "/modelconfiguratorpoint"
            }
            else if(this.props.itemElements[i].itemGrp.itemGrpCode === "FRESA"){
                modelConfPage = "/modelconfiguratorcutter"
            }

            catalogCards.push( <Link key={this.getKey()} to={modelConfPage} ><CatalogCard key={this.getKey()} item={this.props.itemElements[i]} onClick={(e) => this.onItemElementClicked(this.props.itemElements[i])} /></Link>);
        }

        return (
            <div style={{ width: "100%" }}>                     
                <CatalogBreadcrumb />
                <div className='catalogCardContainer'>
                    { catalogCards }
                </div>                      
            </div>         
        )
    }   
}

function mapStateToProps(state) {
    // console.log("catalog state ", state)
    return {
        catalogElements: state.catalog.catalogElements,
        itemElements: state.catalog.itemElements
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getCatalogFirstLevel: (dataRequest) => dispatch(getCatalogFirstLevel(dataRequest)),
        getCatalogNextLevel: (dataRequest) => dispatch(getCatalogNextLevel(dataRequest)),
        setModelConfiguratorItem: (item) => dispatch(setModelConfiguratorItem(item)),
        refreshToken: () => dispatch(refreshToken())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Catalog)