export const GetConfigurationParameters = function(item) {
    var confJson = "{ tipo_piastra: \"" + item.itemTypeCode + "\","

    if(item.modelConfiguration){

        item.modelConfiguration.configuration.forEach(c => {
            c.configurationForm.forEach(c => {

                var value = c.instance.value;
    
                if(c.inputType === "checkbox" && c.instance.value === "checked") {
                    value = true
                }
                else if(c.inputType === "checkbox" && c.instance.value === "unchecked") {
                    value = false
                }
                else if(c.inputType === "text" || c.inputType === "select" ){
                    value = value.split(" ")[0] // il valore potrebbe avere l unita di misura
                }
    
                confJson = confJson + " \"" + c.formDetailCode + "\" : \"" + value + "\"  ," 
            })
        });
        
        confJson = confJson.slice(0, -1)
        confJson = confJson + " } "

        console.log("confJson", confJson.replaceAll(' ',''))
        return confJson.replaceAll(' ','')
    }
}

export const GetPointConfigurationParameters = function(item) {
    console.log("item", item)
    var confJson = "{ \"tipo_piastra\": \"" + item.itemTypeCode + "\","

    if(item.modelConfiguration){
        var configFormInRequest = []
        var pointConf = item.modelConfiguration.configuration.find(c => c.formTemplateCode === "PNT_CONF")
        var nrStepConf = item.modelConfiguration.configuration.find(c => c.formTemplateCode === "PNT_NRSTEP")
        var paramConf = item.modelConfiguration.configuration.find(c => c.formTemplateCode === "PNT_ALTRO")

        console.log("pointConf", pointConf)
        if(pointConf && nrStepConf) {
            configFormInRequest = configFormInRequest.concat(pointConf.configurationForm.filter(cf => cf.formDetailCode === "Dcod" || cf.formDetailCode === "Ltot" || cf.formDetailCode === "Dc" || cf.formDetailCode === "Rivest" || cf.formDetailCode === "Lutile" || cf.formDetailCode === "MAtUt" || cf.formDetailCode === "Z"))
            configFormInRequest = configFormInRequest.concat(nrStepConf.configurationForm[0])
        }

        if(paramConf) {
            configFormInRequest.push(paramConf.configurationForm.find(cf => cf.formDetailCode === "Fori_Lubrificazione"))
        }

        console.log("configFormInRequest", configFormInRequest)
        //item.modelConfiguration.configuration.forEach(c => {
            /*c.configurationForm*/ configFormInRequest.forEach(c => {

                var value = c.instance.value;
    
                if(c.inputType === "checkbox" && c.instance.value === "checked") {
                    value = true
                }
                else if(c.inputType === "checkbox" && c.instance.value === "unchecked") {
                    value = false
                }
                else if(c.inputType === "text" || c.inputType === "select" ){
                    value = value.split(" ")[0] // il valore potrebbe avere l unita di misura
                }
    
                confJson = confJson + " \"" + c.formDetailCode + "\" : \"" + value + "\"  ," 
            })
        //});
        
        confJson = confJson.slice(0, -1)
        confJson = confJson + " } "

        console.log("confJson", confJson.replaceAll(' ',''))
        return confJson.replaceAll(' ','')
    }
}

export const GetCutterConfigurationParameters = function(item) {
    console.log("item", item)
    var confJson = "{ \"tipo_piastra\": \"" + item.itemTypeCode + "\","

    if(item.modelConfiguration){
        var configFormInRequest = []
        var cutterConf = item.modelConfiguration.configuration.find(c => c.formTemplateCode === "FRS_CONF")
        var paramConf = item.modelConfiguration.configuration.find(c => c.formTemplateCode === "FRS_ALTRO")
        console.log("cutterConf", cutterConf)
        if(cutterConf) {
            configFormInRequest = configFormInRequest.concat(cutterConf.configurationForm.filter(cf => cf.formDetailCode === "Dcod" || cf.formDetailCode === "Ltot" || cf.formDetailCode === "Dc" || cf.formDetailCode === "Rivest" || cf.formDetailCode === "Lutile" || cf.formDetailCode === "MAtUt" || cf.formDetailCode === "Prep_Tgl_Front" || cf.formDetailCode === "RTorico" || cf.formDetailCode === "Z" || cf.formDetailCode === "Prep_Cod"))
        }

        if(paramConf) {
            configFormInRequest.push(paramConf.configurationForm.find(cf => cf.formDetailCode === "Fori_Lubrificazione"))
        }

        console.log("configFormInRequest", configFormInRequest)
        
        configFormInRequest.forEach(c => {
                var value = c.instance.value;
    
                if(c.inputType === "checkbox" && c.instance.value === "checked") {
                    value = true
                }
                else if(c.inputType === "checkbox" && c.instance.value === "unchecked") {
                    value = false
                }
                else if(c.inputType === "text" || c.inputType === "select" ){
                    value = value.split(" ")[0] // il valore potrebbe avere l unita di misura
                }
    
                confJson = confJson + " \"" + c.formDetailCode + "\" : \"" + value + "\"  ," 
            })

        
        confJson = confJson.slice(0, -1)
        confJson = confJson + " } "

        console.log("confJson", confJson.replaceAll(' ',''))
        return confJson.replaceAll(' ','')
    }
}
