import React, { Component } from 'react';
import { viewerCss, viewerJs } from './../shared.js';
import Script from 'react-load-script';
import { getForgeOAuthToken } from '../../actions/index';
import { connect } from 'react-redux';

let Autodesk = null;

class ForgeViewer extends Component {

    constructor(props) {
        console.log("ForgeViewer", props)
        super(props);

        this.viewerDiv = React.createRef();
        this.viewer = null;
        this.keyCount = 0;
        this.getKey = this.getKey.bind(this)

        this.state = {
            svf: props.svf
        };
    }

    getKey() {
        var newKey = "forgeviewer" + this.keyCount++
        return newKey;
    }  

    componentDidMount() {    }

    componentWillReceiveProps(nextProps) {
        console.log("componentWillReceiveProps", nextProps)
        this.setState(({ svf }) => ({ svf: nextProps.svf }))
      }
    
    handleScriptLoad() {
        console.log("handleScriptLoad")
        var self = this;

        var oauthCallback = function(token) {
                console.log("token",token)
                const options = { 
                    env: 'AutodeskProduction',
                    accessToken: token
                }
    
                Autodesk = window.Autodesk;   
                // const container = self.viewerDiv.current;
                const container = document.getElementById("ForgeViewer");
               
                self.viewer = new Autodesk.Viewing.GuiViewer3D(container);
 
                // uncomment this for Viewer debugging
                //self.viewer.debugEvents(true);   
                Autodesk.Viewing.Initializer(options, self.handleViewerInit.bind(self));
        }

        if(!this.props.accessToken || this.props.accessToken === ""){
            self.props.getForgeOAuthToken((res) => {
                 console.log("getForgeOAuthToken", res)
                 oauthCallback(res.data.dictionary.access_token)
            })
        }
        else {
            oauthCallback(this.props.accessToken)
        }
            
        
    }

    handleViewerInit() {
        console.log("handleViewerInit", this.viewer)

        const errorCode = this.viewer.start();
        if (errorCode)
        {
            console.log("*** errorCode", errorCode);
            return;
        }
            
        // orient camera in the same way as it's on the thumbnail
        // corresponding to ViewOrientationTypeEnum.kIsoTopRightViewOrientation
        const viewer = this.viewer;
        
        this.viewer.addEventListener(Autodesk.Viewing.EXTENSION_LOADED_EVENT, (event) => {
            //console.log("EXTENSION_LOADED_EVENT", event.extensionId)
            const viewCubeExtensionId = "Autodesk.ViewCubeUi";

            // this is not perfect, because the view transition is visible, so it's a place to improve someday
            if (event.extensionId === viewCubeExtensionId) {

                const viewCubeUI = event.target.getExtension(viewCubeExtensionId);
                viewCubeUI.setViewCube("front top right");

                viewer.removeEventListener(Autodesk.Viewing.EXTENSION_LOADED_EVENT);
            }

        });

        // skip loading of svf when there is no active project svf
        if (!this.props.svf)
        {
            console.log("!!!!!this.props.svf",this.props.svf)
            return;
        }

        Autodesk.Viewing.Document.load(this.getSvfUrl(), this.onDocumentLoadSuccess.bind(this),this.onDocumentLoadFail.bind(this));
    }

    onDocumentLoadSuccess(viewerDocument) {
        var self = this;
        console.log("*** onDocumentLoadSuccess", viewerDocument)
        if(viewerDocument.myPath === self.getSvfUrl()){
            const defaultModel = viewerDocument.getRoot().getDefaultGeometry();
            this.viewer.loadDocumentNode(viewerDocument, defaultModel).then(function(){
            });
        }
    }

    onDocumentLoadFail(errorCode, error) {
        console.log("*** onDocumentLoadFail", errorCode, error)
    }

    getSvfUrl() {
        console.log("this.props.svf", this.props.svf + "/bubble.json")
        return this.props.svf + "/bubble.json";
    }

    render() {

        return (
          <div key={this.getKey()} className="forgeViewerMainDiv">
               
            <link  key={this.getKey()} rel="stylesheet" type="text/css" href={ viewerCss } />
                    <Script  key="forgeModelViewerScript" url={ viewerJs } onLoad={this.handleScriptLoad.bind(this)} />
                    <div  key={this.getKey()} className="modelContainer">
                        <div  key={this.getKey()} ref={this.viewerDiv} id="ForgeViewer"></div>
                    </div>
          </div>
        )
    }
}


function mapDispatchToProps(dispatch) {
    return {       
        getForgeOAuthToken: (successCB) => dispatch(getForgeOAuthToken(successCB))

    }
}

export default connect(null, mapDispatchToProps)(ForgeViewer)