import repo from '../Repository';
import axios from 'axios';
//File con nome server e porta per le chiamate delle API.
import config from '../config.json';
import { Jobs } from '../ForgeJobManager';

import {
    SCROLL_EVENT, UPDATE_CATALOG_CONTENT, ADD_BREADCRUMB_STEP_CATALOG, SELECT_BREADCRUMB_STEP_CATALOG, INITIALIZE_BREADCRUMB_CATALOG,
    TOOGLE_MENU_BUTTON_CLICKED, SET_BP_LIST, SET_BP_FAVOURITE, SET_ORDER_LIST, DELETE_ORDER, TOOGLE_DRAFT_ORDER_CONTAINER, TOOGLE_INPROGRESS_ORDER_CONTAINER,
    TOOGLE_HISTORY_ORDER_CONTAINER, SET_ACCESS_TOKEN, FORGE_UPDATE_MODEL_PARAMETERS, SET_LOADING_SPINNER_VISIBILITY, SET_MODELCONFIGURATOR_FORM, SET_MODELCARTDETAILS_FORM,
    SET_ITEMS_IN_CART_ACCESSORIES, ADD_ITEM_IN_CART, UPDATE_ITEM_IN_CART_QTY, REMOVE_ITEM_IN_CART, INITIALIZE_MODAL_CONFIRM, SET_MODEL_CONF_ITEM,
    SET_CARTCLOSE_USERDET_FORM, SET_CARTCLOSE_ITEMTABLE, UPDATE_MODEL_PARAMS_REDUCER, SET_ITEM_PRICE, SET_ISLOGGEDUSER, UPDATE_DELIVERY_DATE, UPDATE_DELIVERY_DATE_ITEM, 
    SET_ITEM_CONFIGURATION_PDF_URL, SET_ITEM_CONFIGURATION_STEP_URL, ONCHANGE_CONFIGURATOR_FORM_INPUT, ONCHANGE_ACCESSORYGRP_FORM_INPUT, UPDATE_ITEM_IN_CART_ACCESSORIES,
    ONCHANGE_CNC_FORM_INPUT, ONCHANGE_USERDET_FORM_INPUT, SET_MODELCONFIGURATOR_DESCR, DESTROY_SESSION, SET_MENU_CONTENT, EMPTY_CART, SET_MODELCONFIGURATOR_SVF, SET_LOGGED_USER,
    ONCHANGE_ITEM_NOTE
} from "./types";

/*
let interceptor = axios.interceptors.response.use(
    (response) => {
      console.log("----------")
      console.log("response refreshToken")
      console.log(response)
      console.log("----------")
      return response;
    },
    (error) => {
        console.log("error",error)
        if (error.response.status === 401) {
            console.log("REFRESH TOKEN UNAUTHORIZED!")
        }

        // axios.interceptors.request.eject(interceptor)
        console.log("*** refresh token error ", error)
        return error;
    }
);
*/

/* 
    Funzione invocata quando l'utente esegue scroll. Memorizza nel reducer l'offset' dello scroll effettuato.
*/
export const scrollEventOccurs = (scrollOffsetX, scrollOffsetY) => async dispatch => {
    dispatch({ type: SCROLL_EVENT, payload: { scrollOffsetY: scrollOffsetY, scrollOffsetX: scrollOffsetX } })
}

export const doLogin = (dataRequest) => async dispatch => {
    // console.log("doLogin",dataRequest)
    
    try{
        const response = await axios.post(
            config.server + 'gateway/Auth/authenticate',
            {
                username: dataRequest.username,
                password: dataRequest.psw
            },
            {headers: {           
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }}    
        )
    
        console.log("response", response)

        dispatch({ type: SET_LOGGED_USER, payload: response.data })
    
        sessionStorage.setItem('jwtToken', response.data.jwtToken)
        sessionStorage.setItem('refreshToken', response.data.refreshToken)    
        dispatch({ type: SET_ISLOGGEDUSER, payload: { isUserLogged: true} })
        dispatch({ type: SET_MENU_CONTENT, payload: { menuElements: [{ menuLabel: "Catalogo", target: "/catalog", icon: "bi bi-images"  }]} })

        return response
    }
    catch(err){
        console.log("doLogin err", err)
        return err
    }
 
    // DEBUG LOCALE   
  /*
    sessionStorage.setItem('jwtToken', "123456789")
    dispatch({ type: SET_ISLOGGEDUSER, payload: { isUserLogged: true} })
    dispatch({ type: SET_MENU_CONTENT, payload: { menuElements: [{ menuLabel: "Catalogo", target: "/catalog", icon: "bi bi-images"  }]} })
    return {}
*/
    // FINE DEBUG LOCALE

    
}

export const changePassword = (dataRequest) => async dispatch => {
    console.log("changePassword",dataRequest)
    
    try{
        const response = await axios.post(
            config.server + 'gateway/Auth/changePassword',
            {
                Id_User: dataRequest.id_User,
                OldPassword: dataRequest.oldPsw,
                NewPassword: dataRequest.newPsw
            },
            {headers: {           
                Authorization: "Bearer " + sessionStorage.getItem('jwtToken'),
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }}    
        )
    
        console.log("response", response)  
        return response 
    }
    catch(err){
        console.log("changePassword err", err)
        return err
    }

    
}


export const refreshToken = () => async dispatch => {
    console.log("*** refreshToken ***")

    const response = await axios.post(
        config.server + 'gateway/Auth/refresh',
        {
            jwtToken:  sessionStorage.getItem('jwtToken'),
            refreshToken: sessionStorage.getItem('refreshToken')
        },
        {headers: {           
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }}    
    )

    sessionStorage.setItem('jwtToken', response.data.jwtToken)
    sessionStorage.setItem('refreshToken', response.data.refreshToken)

    return response
}

export const doLogout = () => async dispatch => {
    console.log("*** LOGOUT ***")
    sessionStorage.removeItem('jwtToken')
    sessionStorage.clear()
    dispatch({ type: SET_ISLOGGEDUSER, payload: { isUserLogged: false} })
    dispatch({ type: DESTROY_SESSION });
}

/********************************************************************************************************************************* */
/*******************************************     CATALOGO      ******************************************************************* */
/********************************************************************************************************************************* */

// Recupera il primo livello del catalogo
export const getCatalogFirstLevel = (dataRequest) => async dispatch => {
    // console.log("getCatalogFirstLevel ", dataRequest.bp)
    
    try {       
        const response = await axios.post(
            config.server + 'Catalog/GetCatalogLevel',
            {
                ID_BP: -1,
                ID_Parent: -1,
                LangCode: dataRequest.langCode
            },
            {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem('jwtToken'),
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        )
       
        var payload = {
            bp: dataRequest.bp,
            catalogElements: response.data.categories,
            itemElements: response.data.items
        }
    
        // Verifico se ci sono già dati presenti nel sessionStorage
        var oldBreadcrumb = sessionStorage.getItem('catalogBreadcrumbSteps')
        var oldCatalogEl = sessionStorage.getItem('catalogElements')
        var oldItemsEl = sessionStorage.getItem('itemElements')
        var currCatalogBp = sessionStorage.getItem('catalogBpActive')
        // console.log("oldBreadcrumb",JSON.parse(oldBreadcrumb))
        // console.log("oldCatalogEl",JSON.parse(oldCatalogEl))
        //console.log("oldItemsEl",JSON.parse(oldItemsEl))
        // console.log("currCatalogBp",JSON.parse(currCatalogBp))
    
        if (typeof oldBreadcrumb !== "undefined" && oldBreadcrumb && JSON.parse(oldBreadcrumb).length > 1
            && typeof oldCatalogEl !== "undefined" && oldCatalogEl
            && typeof oldItemsEl !== "undefined" && oldItemsEl
            && dataRequest.bp.bpCode === JSON.parse(currCatalogBp).bpCode) {
            dispatch({ type: UPDATE_CATALOG_CONTENT, payload: { bp: JSON.parse(currCatalogBp), catalogElements: JSON.parse(oldCatalogEl), itemElements: JSON.parse(oldItemsEl) } })
            dispatch({ type: INITIALIZE_BREADCRUMB_CATALOG, payload: JSON.parse(oldBreadcrumb) })
        }
        else {
            dispatch({ type: UPDATE_CATALOG_CONTENT, payload: payload })
            dispatch({ type: INITIALIZE_BREADCRUMB_CATALOG, payload: [{ item: { catalogCode: "C", catalogName: dataRequest.bp.bpCode }, elements: payload }] })
        }
        
        return response
    }
    catch(err){
        console.log("getCatalogFirstLevel err", err)

        if(err.response && (err.response.status === 401 || err.response.status === "401")) {
            return dispatch(refreshToken()).then(function(refTok){
         
                if(refTok.status === 200) {
                    return dispatch(getCatalogFirstLevel(dataRequest))
                }
                else {
                    return refTok
                }
            })
        }

        return err
    }
   
}

// Data una categoria del catalogo, recupera il livello successivo (i figli della categoria)
export const getCatalogNextLevel = (dataRequest) => async dispatch => {
    // console.log("getCatalogNextLevel", dataRequest)

    try {
        var payload = undefined

        const response = await axios.post(
            config.server + 'Catalog/GetCatalogLevel',
            {
                ID_BP: -1,
                ID_Parent: dataRequest.item.iD_Catalog,
                LangCode: dataRequest.langCode
            },
            {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem('jwtToken'),
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        )

        console.log("response", response)

        payload = {
            bp: dataRequest.bp,
            catalogElements: response.data.categories,
            itemElements: response.data.items
        }

        if (typeof payload !== "undefined") {
            dispatch({ type: UPDATE_CATALOG_CONTENT, payload: payload })
            dispatch({ type: ADD_BREADCRUMB_STEP_CATALOG, payload: { item: dataRequest.item, elements: payload } })
        }
        else {
            payload = {
                bp: dataRequest.bp,
                catalogElements: [],
                itemElements: []
            }

            dispatch({ type: UPDATE_CATALOG_CONTENT, payload: payload })
            dispatch({ type: ADD_BREADCRUMB_STEP_CATALOG, payload: { item: dataRequest.item, elements: payload } })
        }

        return response
    }
    catch(err){
        console.log("getCatalogNextLevel err", err)

        if(err.response && (err.response.status === 401 || err.response.status === "401")) {
            return dispatch(refreshToken()).then(function(refTok){
         
                if(refTok.status === 200) {
                    return dispatch(getCatalogNextLevel(dataRequest))
                }
                else {
                    return refTok
                }
            })
        }

        return err
    }
   

}

/* 
    Funzione invocata al click di un elemento (step) del breadcrumb. 
    Aggiorna il contenuto del catalogo (con gli elementi catalogo dello step) e aggiorna il breadcrumb.
*/
export const selectCatalogBreadcrumbStep = (step) => async dispatch => {
    dispatch({ type: UPDATE_CATALOG_CONTENT, payload: step.elements })
    dispatch({ type: SELECT_BREADCRUMB_STEP_CATALOG, payload: step })

}

//---------------------------------------------  FINE CATALOGO -------------------------------------------------------------------------
//--------------------------------------------------------------------------------------------------------------------------------------


// Funzione invocata al click del bottone per aprire/chiudere menu
export const toogleMenuButtonClicked = () => async dispatch => {
    dispatch({ type: TOOGLE_MENU_BUTTON_CLICKED, payload: {} })
}

// Recupera la lista di bp associati all'utente loggato
export const getBpList = (dataRequest) => async dispatch => {
    var payload = {
        bpList: [
            {
                bpCode: "101978",
                bpName: "BRETON SPA",
                List1: "Mario Rossi",
                tel: "+39 049 5628741",
                isFavourite: true,
                color: "#ff00009e"
            },
            {
                bpCode: "562014",
                bpName: "CELENIT",
                List1: "Andrea Bianchi",
                tel: "+39 049 5628741",
                isFavourite: false,
                color: "#ffff00b3"
            },
            {
                bpCode: "896520",
                bpName: "HOFFMAN",
                List1: "Paolo Verdi",
                tel: "+39 049 5628741",
                isFavourite: true,
                color: "#0080008f"
            },
            {
                bpCode: "021774",
                bpName: "REDBULL",
                List1: "Luca Ferrari",
                tel: "+39 049 5628741",
                isFavourite: "",
                color: "rgb(255, 197, 136)"
            },
            {
                bpCode: "635200",
                bpName: "GARRONE SPA",
                List1: "Mario Rossi",
                tel: "+39 049 5628741",
                isFavourite: false,
                color: "rgb(126, 194, 214)"
            }
        ]
    }

    dispatch({ type: SET_BP_LIST, payload: payload })
}

// Marca un bp come favorito (icona stella di BpCard)
export const setBpFavourite = (bp) => async dispatch => {
    dispatch({ type: SET_BP_FAVOURITE, payload: bp })
}



/********************************************************************************************************************************* */
/*********************************************     ORDINI      ******************************************************************* */
/********************************************************************************************************************************* */

// Recupera gli ordini di un bp
export const getOrders = (dataRequest) => async dispatch => {
    var payload = {
        draft: [
            {
                orderCode: "0000000011",
                bpCode: "101978",
                deliveryAddress: "VIA TRENTO 40",
                orderDate: "2022-01-03",
                color: "#00e1ff",
                Tot: "12,00"
            },
            {
                orderCode: "0000000012",
                bpCode: "896520",
                deliveryAddress: "VIA ROMA 40",
                orderDate: "2022-01-03",
                color: "#00e1ff",
                Tot: "144,00"
            }
        ],
        inProgress: [
            {
                orderCode: "0000000013",
                bpCode: "101978",
                deliveryAddress: "VIA RONCHI 40",
                orderDate: "2022-01-03",
                color: "#ff0000a3",
                Tot: "152,00"
            },
            {
                orderCode: "0000000014",
                bpCode: "635200",
                deliveryAddress: "VIA TREVISO 40",
                orderDate: "2022-01-03",
                color: "#ff8300b0",
                Tot: "1123,00"
            }
        ],
        history: [
            {
                orderCode: "0000000015",
                bpCode: "896520",
                deliveryAddress: "VIA PADOVA 40",
                orderDate: "2022-01-03",
                color: "#008000a1",
                Tot: "222,00"
            },
            {
                orderCode: "0000000016",
                bpCode: "635200",
                deliveryAddress: "VIA TRENTO 40",
                orderDate: "2022-01-03",
                color: "#008000a1",
                Tot: "156,00"
            },
            {
                orderCode: "0000000017",
                bpCode: "896520",
                deliveryAddress: "VIA PADOVA 40",
                orderDate: "2022-01-03",
                color: "#008000a1",
                Tot: "222,00"
            },
            {
                orderCode: "0000000018",
                bpCode: "635200",
                deliveryAddress: "VIA TRENTO 40",
                orderDate: "2022-01-03",
                color: "#008000a1",
                Tot: "156,00"
            },
            {
                orderCode: "0000000019",
                bpCode: "896520",
                deliveryAddress: "VIA PADOVA 40",
                orderDate: "2022-01-03",
                color: "#008000a1",
                Tot: "222,00"
            },
            {
                orderCode: "0000000020",
                bpCode: "635200",
                deliveryAddress: "VIA TRENTO 40",
                orderDate: "2022-01-03",
                color: "#008000a1",
                Tot: "156,00"
            },
            {
                orderCode: "0000000021",
                bpCode: "635200",
                deliveryAddress: "VIA TRENTO 40",
                orderDate: "2022-01-03",
                color: "#008000a1",
                Tot: "156,00"
            },
            {
                orderCode: "0000000022",
                bpCode: "896520",
                deliveryAddress: "VIA PADOVA 40",
                orderDate: "2022-01-03",
                color: "#008000a1",
                Tot: "222,00"
            },
            {
                orderCode: "0000000023",
                bpCode: "635200",
                deliveryAddress: "VIA TRENTO 40",
                orderDate: "2022-01-03",
                color: "#008000a1",
                Tot: "156,00"
            },
            {
                orderCode: "0000000024",
                bpCode: "896520",
                deliveryAddress: "VIA PADOVA 40",
                orderDate: "2022-01-03",
                color: "#008000a1",
                Tot: "222,00"
            },
            {
                orderCode: "0000000025",
                bpCode: "635200",
                deliveryAddress: "VIA TRENTO 40",
                orderDate: "2022-01-03",
                color: "#008000a1",
                Tot: "156,00"
            }
        ]
    }

    //console.log("getOrders", payload)
    dispatch({ type: SET_ORDER_LIST, payload: payload })
}

// Elimina un ordine del bp
export const deleteOrder = (order) => async dispatch => {
    //console.log("order", order)
    dispatch({ type: DELETE_ORDER, payload: order })
}

// Click icona per comprimere/espandere sezione ordini in bozza.
export const toogleDraftOrdersContainer = () => async dispatch => {
    dispatch({ type: TOOGLE_DRAFT_ORDER_CONTAINER, payload: {} })
}

// Click icona per comprimere/espandere sezione ordini in elaborazione.
export const toogleInProgressOrdersContainer = () => async dispatch => {
    dispatch({ type: TOOGLE_INPROGRESS_ORDER_CONTAINER, payload: {} })
}

// Click icona per comprimere/espandere sezione ordini storici.
export const toogleHistoryOrdersContainer = () => async dispatch => {
    dispatch({ type: TOOGLE_HISTORY_ORDER_CONTAINER, payload: {} })
}

//-----------------------------------------------  FINE ORDINI -------------------------------------------------------------------------
//--------------------------------------------------------------------------------------------------------------------------------------



// Recupera token autenticazione Forge
export const getForgeOAuthToken = (successCB) => async dispatch => {

    try {
        const response = await axios.post(
            config.server + 'Forge/GetOAuthentication',
            { },
            {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem('jwtToken'),
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        )
    
        console.log("response", response)

        if(response.data && response.data !== ""){
            dispatch({ type: SET_ACCESS_TOKEN, payload: { accessToken: response.data.data.dictionary.access_token } })

            if(successCB){
                successCB(response.data)
            }
    
            return response
        }
    
        
        
    }
    catch(err){
        console.log("getForgeOAuthToken err", err)

        if(err.response && (err.response.status === 401 || err.response.status === "401")) {
            return dispatch(refreshToken()).then(function(refTok){
         
                if(refTok.status === 200) {
                    return dispatch(getForgeOAuthToken())
                }
                else {
                    return refTok
                }
            })
        }

        return err
    }
    /*
    try {
        const jobManager = Jobs();
        await jobManager.getOAuthToken(
            () => {
                // console.log("start getOAuthToken")
            },
            (response) => {
                console.log("onComplete", response)

                if (successCB) {
                    successCB(response);
                }

                dispatch({ type: SET_ACCESS_TOKEN, payload: { accessToken: response.data.dictionary.access_token } })

            },
            (err) => {
                console.log("onError", err)
            }
        )
    } catch (e) {
        console.log("getForgeOAuthToken error ", e)
    }
    */
}

/*
    Aggiorna un modello con nuovi valori per i parametri configurabili del modello
*/
export const updateModelParameters = (dataRequest, successCB) => async dispatch => {

    try {
        const response = await axios.post(
            config.server + 'Forge/UpdateParameters',
            {
                jsonData: JSON.stringify(dataRequest)
            },
            {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem('jwtToken'),
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        )
    
        console.log("response", response)

        successCB(response.data)
    
        dispatch({ type: FORGE_UPDATE_MODEL_PARAMETERS, payload: { svfUrl: response.data.OutputModelUrl, outputIam: response.data.OutputModelIAM } })

        return response
    }
    catch(err){
        console.log("updateModelParameters err", err)

        if(err.response && (err.response.status === 401 || err.response.status === "401")) {
            return dispatch(refreshToken()).then(function(refTok){
         
                if(refTok.status === 200) {
                    return dispatch(updateModelParameters(dataRequest, successCB))
                }
                else {
                    return refTok
                }
            })
        }

        return err
    }

    /*
    try {



        // console.log("updateModelParameters",dataRequest, successCB)
        const jobManager = Jobs();

        await jobManager.updateParamters(dataRequest,
            () => {
                // console.log("start updateParamters")
            },
            (response) => {
                console.log("onComplete", response)

                if (successCB) {
                    successCB(response);
                }

                dispatch({ type: FORGE_UPDATE_MODEL_PARAMETERS, payload: { svfUrl: response.OutputModelUrl, outputIam: response.OutputModelIAM } })

            },
            (err) => {
                console.log("onError", err)
            }
        )
        
    } catch (e) {
        console.log("updateModelParameters error ", e)
    }
    */
}

export const getDrawingPdf = (dataRequest, successCB) => async dispatch => {
    try {


        const response = await axios.post(
            config.server + 'Forge/GetDrawingPdf',
            dataRequest,
            {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem('jwtToken'),
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        )
    
        console.log("response", response)
      
        dispatch({ type: SET_ITEM_CONFIGURATION_PDF_URL, payload: { pdfUrl: response.data, item: dataRequest.item } })

    } catch (e) {
        console.log("GetDrawingPdf error ", e)
    }
}

export const getModelStep = (dataRequest, successCB) => async dispatch => {
    try {

        const response = await axios.post(
            config.server + 'Forge/GetModelStep',
            dataRequest,
            {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem('jwtToken'),
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        )
    
        console.log("response", response)

        dispatch({ type: SET_ITEM_CONFIGURATION_STEP_URL, payload: { stepUrl: response.data, item: dataRequest.item } })
       
    } catch (e) {
        console.log("getModelStep error ", e)
    }
}

export const downloadStepFromUrl = (stepUrl) => async dispatch => {
    console.log(stepUrl)
    const res = await axios.get(
        stepUrl,
        {
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem('jwtToken'),
                Accept: 'application/json',
                'Content-Type': 'application/STEP'
            }
        }
    )

    console.log("res", res)
    return res.data
}


// Visualizza/nasconde lo spinner di caricamento, a seconda se isVisible è true/false
export const setLoadingSpinnerVisibility = (isVisible) => async dispatch => {
    dispatch({ type: SET_LOADING_SPINNER_VISIBILITY, payload: isVisible })
}

/*
    Recupera la form con i parametri configurabili per il modello che si vuoel configurare
*/
export const getModelConfiguratorForm = (dataRequest) => async dispatch => {

    try {
        const response = await axios.post(
            config.server + 'ModelConfigurator/GetModelByItem',
            {
                ID_Item: dataRequest.iD_Item,
                LangCode: dataRequest.LangCode
            },
            {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem('jwtToken'),
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        )
    
        console.log("response", response)
    
        dispatch({ type: SET_MODELCONFIGURATOR_FORM, payload: { formList: response.data } })

        if(response.data && response.data.configuration){
            dispatch({ type: SET_MODELCONFIGURATOR_SVF, payload: { svf: response.data.configuration[0].urlConfiguration } })
        }
        



        return response
    }
    catch(err){
        console.log("getModelConfiguratorForm err", err)

        if(err.response && (err.response.status === 401 || err.response.status === "401")) {
            return dispatch(refreshToken()).then(function(refTok){
         
                if(refTok.status === 200) {
                    return dispatch(getModelConfiguratorForm(dataRequest))
                }
                else {
                    return refTok
                }
            })
        }

        return err
    }
   
}

export const getConfigurationDescription = (dataRequest) => async dispatch => {
    try {
        const response = await axios.post(
            config.server + 'ModelConfigurator/GetConfigurationDescription',
            {
                jsonData: dataRequest
            },
            {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem('jwtToken'),
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        )
    
        console.log("response", response)
    
        dispatch({ type: SET_MODELCONFIGURATOR_DESCR, payload: { configurationDescr: response.data } })

        return response
    }
    catch(err){
        console.log("getConfigurationDescription err", err)

        if(err.response && (err.response.status === 401 || err.response.status === "401")) {
            return dispatch(refreshToken()).then(function(refTok){
         
                if(refTok.status === 200) {
                    return dispatch(getConfigurationDescription(dataRequest))
                }
                else {
                    return refTok
                }
            })
        }

        return err
    }
   
}

/*
    Recupera la form dettaglio macchina in cart
*/
export const getCartDetailsForm = (dataRequest) => async dispatch => {

    console.log("dataRequest", dataRequest)
    try {
        const response = await axios.post(
            config.server + 'Cart/GetItemCncForm',
            {
                ID_Item: dataRequest.ID_Item, // dataRequest.iD_Item // TODO aggiungere i record a db per gestire la form di tutti gli articoli
                LangCode: dataRequest.LangCode
            },
            {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem('jwtToken'),
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        )
    
        console.log("response", response)
    
        dispatch({ type: SET_MODELCARTDETAILS_FORM, payload: { formList: response.data } })

        return response
    }   
    catch(err){
        console.log("getCartDetailsForm err", err)

        if(err.response && (err.response.status === 401 || err.response.status === "401")) {
            return dispatch(refreshToken()).then(function(refTok){
         
                if(refTok.status === 200) {
                    return dispatch(getCartDetailsForm(dataRequest))
                }
                else {
                    return refTok
                }
            })
        }

        return err
    }
   
}

export const getItemCncFormAgainstFormValues = (dataRequest) => async dispatch => {

    console.log("getItemCncFormAgainstFormValues", JSON.stringify(dataRequest))
    try {
        const response = await axios.post(
            config.server + 'Cart/GetItemCncFormAgainstFormValues',
            {
                jsonData: JSON.stringify(dataRequest)
            },
            {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem('jwtToken'),
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        )
    
        console.log("response", response)
    
        dispatch({ type: SET_MODELCARTDETAILS_FORM, payload: { formList: response.data } })

        return response
    }   
    catch(err){
        console.log("getItemCncFormAgainstFormValues err", err)

        if(err.response && (err.response.status === 401 || err.response.status === "401")) {
            return dispatch(refreshToken()).then(function(refTok){
         
                if(refTok.status === 200) {
                    return dispatch(getItemCncFormAgainstFormValues(dataRequest))
                }
                else {
                    return refTok
                }
            })
        }

        return err
    }
   
}

/*
    Aggiunge item a carrello
*/
export const addToCart = (item) => async dispatch => {
    dispatch({ type: ADD_ITEM_IN_CART, payload: { item: item } })
}

/*
    Recupera gli accessori per gli articoli a carrello
*/
export const getItemAccessories = (dataRequest) => async dispatch => {
    
    console.log("getItemAccessories", dataRequest)
    
    try {
        const response = await axios.post(
            config.server + 'Item/GetItemAccessories',
            {
                iD_ItemGrp: dataRequest.item.iD_ItemGrp,
                langCode: dataRequest.langCode,
                jsonData: dataRequest.jsonData
            },
            {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem('jwtToken'),
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        )
    
        console.log("response", response)
    
        dispatch({ type: SET_ITEMS_IN_CART_ACCESSORIES, payload: { item: dataRequest.item, accessories: response.data }})

        return response
    }
    catch(err){
        console.log("getItemAccessories err", err)

        if(err.response && (err.response.status === 401 || err.response.status === "401")) {
            return dispatch(refreshToken()).then(function(refTok){
         
                if(refTok.status === 200) {
                    return dispatch(getItemAccessories(dataRequest))
                }
                else {
                    return refTok
                }
            })
        }

        return err
    }
    
}


/*
    Aggiorna la qta a carrello dell articolo item
*/
export const updateItemInCartQty = (item, prevQty) => async dispatch => {
    dispatch({ type: UPDATE_ITEM_IN_CART_QTY, payload: { item: item, prevQty : prevQty } })
}

/*
    Rimuove articolo da carrello
*/
export const removeItemInCart = (item) => async dispatch => {
    dispatch({ type: REMOVE_ITEM_IN_CART, payload: { item: item } })
}

/*
    Inizializza il contenuto della ModalConfirm (reindirizzamento se utente conferma, markup del body della modal, stile css)
*/
export const initializeModalConfirm = (confirmRedirectTo, getContent, customStyles) => async dispatch => {
    dispatch({ type: INITIALIZE_MODAL_CONFIRM, payload: { confirmRedirectTo: confirmRedirectTo, getContent: getContent, customStyles: customStyles } })
}

/*
    Svuota il carrello
*/
export const emptyCart = () => async dispatch => {
    dispatch({ type: EMPTY_CART })
}

/*
    Imposta l'articolo da configurare nel modelconfigurator
*/
export const setModelConfiguratorItem = (item) => async dispatch => {
    // console.log("setModelConfiguratorItem", item)
    dispatch({ type: SET_MODEL_CONF_ITEM, payload: { item: item } })
}

/*
    Aggiorna lo stato di modelconfigurator aggiornando la configurazione del modello così come impostata dall utente (contenuta in parameters)
*/
export const updateModelParametersInReducer = (parameters) => async dispatch => {
    dispatch({ type: UPDATE_MODEL_PARAMS_REDUCER, payload: { newParams: parameters } })
}

/*
    Recupera i valori con cui popolare la form di close cart (cliente di fatturazione, destinatari, spedizionieri, ecc.)
*/
export const getCartCloseForm = (dataRequest) => async dispatch => {
    console.log("*** getCartCloseForm ***", dataRequest)

    try {
        const response = await axios.post(
            config.server + 'Estimator/GetUserDetailForm',
            {
                LangCode: dataRequest.langCode
            },
            {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem('jwtToken'),
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        )
    
        console.log("response", response)
    
        dispatch({ type: SET_CARTCLOSE_USERDET_FORM, payload: response.data })

        return response
    }
    catch(err){
        console.log("getCartCloseForm err", err)

        if(err.response && (err.response.status === 401 || err.response.status === "401")) {
            return dispatch(refreshToken()).then(function(refTok){
         
                if(refTok.status === 200) {
                    return dispatch(getCartCloseForm(dataRequest))
                }
                else {
                    return refTok
                }
            })
        }

        return err
    }
}

/*
    Recupera le colonne e le righe della tabella articoli (con relativi prezzi) in chiusura a carrello 
*/
export const getCartCloseTableItems = () => async dispatch => {
    var payload = {
        columns: [
            {
                columnName: "CartClose.ItemTable.Columns.Avaiability"
            },
            {
                columnName: "CartClose.ItemTable.Columns.ArtCode"
            },
            {
                columnName: "CartClose.ItemTable.Columns.ArtName"
            },
            {
                columnName: "CartClose.ItemTable.Columns.UM"
            },
            {
                columnName: "CartClose.ItemTable.Columns.Qty"
            },
            {
                columnName: "CartClose.ItemTable.Columns.Price"
            },
            {
                columnName: "CartClose.ItemTable.Columns.Discount"
            },
            {
                columnName: "CartClose.ItemTable.Columns.Total"
            },
            {
                columnName: "PDF"
            },
            {
                columnName: "STEP"
            }
        ],
        rows: [

        ]
    }

    dispatch({ type: SET_CARTCLOSE_ITEMTABLE, payload: payload })
}

/*
    Calcolo del prezzo preventivo
*/
export const getItemPrice =  (dataRequest) => async dispatch => {
    // console.log("dataRequest", dataRequest)

    try {
        const response = await axios.get(
            config.server + 'Estimator/GetEstimate/' + dataRequest.item.iD_Item,
            {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem('jwtToken'),
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                params: {
                    jsonData: dataRequest.jsonClientConf
                }
            }
        )
    
        console.log("getItemPrice response", response)
    
        dispatch({ type: SET_ITEM_PRICE, payload: { item: dataRequest.item, price: response.data }})

        return response
    }
    catch(err){
        console.log("getItemPrice err", err)

        if(err.response && (err.response.status === 401 || err.response.status === "401")) {
            return dispatch(refreshToken()).then(function(refTok){
         
                if(refTok.status === 200) {
                    return dispatch(getItemPrice(dataRequest))
                }
                else {
                    return refTok
                }
            })
        }

        return err
    }
   
}

/*  
    Aggiorna la configurazione del modello nel reducer di ModelConfiguration e ModelConfigurationPoint
*/
export const onChangeConfiguratorFormInput = (idInput, newValue) => async dispatch => {
    dispatch({ type: ONCHANGE_CONFIGURATOR_FORM_INPUT, payload: { idInput: idInput, newValue: newValue }})
}

/*
    Aggiorna la configurazione (es. accessorio selezionato e quantità) di un gruppo di accessori (nel reducer di Cart)
*/
export const onChangeAccessoryGrpFormInput = (item, formStep, idInput, newValue) => async dispatch => {
    dispatch({ type: ONCHANGE_ACCESSORYGRP_FORM_INPUT, payload: { item: item, formStep: formStep, idInput: idInput, newValue: newValue }})
}

/*
    Aggiorna gli accessori selezionati da un utente per un articolo a carrello
*/
export const updateItemInCartAccessories = (itemInCart) => async dispatch => {
    dispatch({ type: UPDATE_ITEM_IN_CART_ACCESSORIES, payload: { itemInCart: itemInCart }})
}

/*
    Aggiorna il modello della form CNC con i valori inseriti dall utente
*/
export const onChangeCncFormInput = (formStep, idInput, newValue) => async dispatch => {
    dispatch({ type: ONCHANGE_CNC_FORM_INPUT, payload: { formStep: formStep, idInput: idInput, newValue: newValue }})
}

/*
    Invia la mail con la richiesta preventivo ed i dati di quest ultimo
*/
export const sendEstimateRequestMail = (dataRequest) => async dispatch => {
    try {
        console.log("dataRequest", dataRequest)

        const response = await axios.post(
            config.server + 'Estimator/SendEstimateRequestMail',
            {
                jsonData:  JSON.stringify(dataRequest)
            },
            {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem('jwtToken'),
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        )
    
        console.log("response", response)

        return response
    }
    catch(err){
        console.log("sendEstimateRequestMail err", err)

        if(err.response && (err.response.status === 401 || err.response.status === "401")) {
            return dispatch(refreshToken()).then(function(refTok){
         
                if(refTok.status === 200) {
                    return dispatch(sendEstimateRequestMail(dataRequest))
                }
                else {
                    return refTok
                }
            })
        }

        return err
    }
}

/*
    Aggiorna il modello della form dettagli cliente con i valori inseriti dall utente
*/
export const onChangeUserDetFormInput = (formStep, idInput, newValue) => async dispatch => {
    dispatch({ type: ONCHANGE_USERDET_FORM_INPUT, payload: { formStep: formStep, idInput: idInput, newValue: newValue }})
}

/*
    Aggiorna le note dell articolo a carrello
*/
export const onChangeItemNote = (item, note) => async dispatch => {
    dispatch({ type: ONCHANGE_ITEM_NOTE, payload: { item: item, note: note }})
}

/*
    Invoca il servizio che applica le correzioni alla form di configurazione modello, in base a quanto imputato
        dall'utente durante la compilazione delle form di configurazione modello
*/
export const getPointModelConfigFormsAgainstFormValues = (modelConfiguration) => async dispatch => {
    //console.log("getPointModelConfigFormsAgainstFormValues", JSON.stringify(modelConfiguration))
    try {
        const response = await axios.post(
            config.server + 'ModelConfigurator/GetPointModelConfigFormsAgainstFormValues',
            {
                jsonData: JSON.stringify(modelConfiguration)
            },
            {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem('jwtToken'),
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        )
    
        console.log("response", response)
    
        if(response.data && response.data !== ""){
            console.log(dispatch({ type: SET_MODELCONFIGURATOR_FORM, payload: { formList: response.data } }))
            return response
        }
        
    }   
    catch(err){
        console.log("getPointModelConfigFormsAgainstFormValues err", err)

        if(err.response && (err.response.status === 401 || err.response.status === "401")) {
            return dispatch(refreshToken()).then(function(refTok){
         
                if(refTok.status === 200) {
                    return dispatch(getPointModelConfigFormsAgainstFormValues(modelConfiguration))
                }
                else {
                    return refTok
                }
            })
        }

        return err
    }
}

export const checkPointModelRules = (modelConfiguration) => async dispatch => {
    try {
        const response = await axios.post(
            config.server + 'ModelConfigurator/CheckPointModelRules',
            {
                jsonData: JSON.stringify(modelConfiguration)
            },
            {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem('jwtToken'),
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        )
    
        console.log("CheckPointModelRules response", response)
    
        if(response.data && response.data !== ""){
            console.log(dispatch({ type: SET_MODELCONFIGURATOR_FORM, payload: { formList: response.data } }))
            return response
        }
        
    }   
    catch(err){
        console.log("checkPointModelRules err", err)

        if(err.response && (err.response.status === 401 || err.response.status === "401")) {
            return dispatch(refreshToken()).then(function(refTok){
         
                if(refTok.status === 200) {
                    return dispatch(checkPointModelRules(modelConfiguration))
                }
                else {
                    return refTok
                }
            })
        }

        return err
    }
}

export const CheckCutterModelRules = (modelConfiguration) => async dispatch => {
    try {
        const response = await axios.post(
            config.server + 'ModelConfigurator/CheckCutterModelRules',
            {
                jsonData: JSON.stringify(modelConfiguration)
            },
            {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem('jwtToken'),
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        )
    
        console.log("CheckCutterModelRules response", response)
    
        if(response.data && response.data !== ""){
            console.log(dispatch({ type: SET_MODELCONFIGURATOR_FORM, payload: { formList: response.data } }))
            return response
        }
        
    }   
    catch(err){
        console.log("CheckCutterModelRules err", err)

        if(err.response && (err.response.status === 401 || err.response.status === "401")) {
            return dispatch(refreshToken()).then(function(refTok){
         
                if(refTok.status === 200) {
                    return dispatch(CheckCutterModelRules(modelConfiguration))
                }
                else {
                    return refTok
                }
            })
        }

        return err
    }
    
}

export const getCutterModelConfigFormsAgainstFormValues = (modelConfiguration) => async dispatch => {
    //console.log("getCutterModelConfigFormsAgainstFormValues", JSON.stringify(modelConfiguration))
    try {
        const response = await axios.post(
            config.server + 'ModelConfigurator/GetCutterModelConfigFormsAgainstFormValues',
            {
                jsonData: JSON.stringify(modelConfiguration)
            },
            {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem('jwtToken'),
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        )
    
        console.log("response", response)
    
        if(response.data && response.data !== ""){
            console.log(dispatch({ type: SET_MODELCONFIGURATOR_FORM, payload: { formList: response.data } }))
            return response
        }
        
    }   
    catch(err){
        console.log("getCutterModelConfigFormsAgainstFormValues err", err)

        if(err.response && (err.response.status === 401 || err.response.status === "401")) {
            return dispatch(refreshToken()).then(function(refTok){
         
                if(refTok.status === 200) {
                    return dispatch(getCutterModelConfigFormsAgainstFormValues(modelConfiguration))
                }
                else {
                    return refTok
                }
            })
        }

        return err
    }
}