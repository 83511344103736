import React, { Component } from 'react';
import { connect } from 'react-redux';
import {  setLoadingSpinnerVisibility, getModelConfiguratorForm, toogleMenuButtonClicked, initializeModalConfirm,
     onChangeConfiguratorFormInput, getCutterModelConfigFormsAgainstFormValues, addToCart, updateModelParameters,
     updateModelParametersInReducer, getConfigurationDescription, CheckCutterModelRules } from '../../actions/index';
import { createBrowserHistory } from "history";
import  PopupSection  from '../subcomponents/PopupSection.js';
import ForgeViewer from '../subcomponents/ForgeViewer';
import config from '../../config.json'
import {FormattedMessage} from 'react-intl';
import { GetConfigurationDetailMarkupForCutters } from '../../utils/Utils.js';
import ModalConfirm from '../subcomponents/ModalConfirm';
import ModalConfiguratorInfo from '../subcomponents/ModalConfiguratorInfo.js';
import LoadingSpinner from '../subcomponents/LoadingSpinner';
import ModalSuccess from '../subcomponents/ModalSuccess';

const locale = navigator.language;

class ModelConfiguratorCutter extends Component {

    constructor(props){
        //console.log("ModelConfigurator constructor", props)
        super(props);
        this.keyCount = 0;
        this.getKey = this.getKey.bind(this)

        this.state = {
            isConfirmModalOpen: false,
            isCartNotEmptyWarnModalOpen: false,
            isConfigurationInfoModalOpen: false,
            isWarningModalOpen: false,
            isCutterModelConfigFormsAgainstFormValuesRunning: false
        }
    }

    getKey() {
        var newKey = "ModelConfiguratorCutter" + this.keyCount++
        return newKey;
    }  

    componentDidMount() {
        let _this = this      
        console.log("ModelConfiguratorCutter componentDidMount", this)

        // In questa pagina chiudo il menu per recuperare spazio
        if(this.props.isMenuOpen === "true" || this.props.isMenuOpen == true) {
            this.props.toogleMenuButtonClicked()
        }

        // Action che inizializza l'oggett formList dello stato di modelconfigurator
        this.props.getModelConfiguratorForm({ iD_Item: this.props.item.iD_Item, LangCode: locale})
            .then(function(response){
                console.log("getModelConfiguratorForm response", response, response.status)
            }).catch(function(err){
                console.log("err",err)
                let history = createBrowserHistory();
                history.push("/login", _this.props)
            });
    }

    componentDidUpdate(prevProps, nextProps) {
        
    }

    /*
        Funzione scatenata quando l utente modifica il contenuto di un campo della form. Aggiorna il modello nel reducer.
    */
    onChangeConfiguratorFormInput = (e) => {
        //console.log("onChangeConfiguratorFormInput", e.target.id)
        var self = this
        var newValue = e.target.value

        if(e.target.type === "checkbox"){
            newValue = e.target.checked
        }

        console.log("this.state.isCutterModelConfigFormsAgainstFormValuesRunning", this.state.isCutterModelConfigFormsAgainstFormValuesRunning)
        if(this.state.isCutterModelConfigFormsAgainstFormValuesRunning === true) {
            setTimeout(function(){
                console.log("wait.....")
                self.props.onChangeConfiguratorFormInput(e.target.id, newValue)
            }, 500)
        }
        else {
            self.props.onChangeConfiguratorFormInput(e.target.id, newValue)
        }

        
        
    }

    /*
        Funzione scatenata quando l utente esce dal focus di un campo di input. 
    */
    onBlurConfiguratorFormInput = (e) => {
        e.preventDefault();
        var self = this;
        console.log("*** onBlurConfiguratorFormInput ***", e)
        console.log("--- getCutterModelConfigFormsAgainstFormValues ---")
        /*
            HOF-69
            Nonostante l utente selezioni un materiale da lavorare, cliccando update si viene rimandati alla sezione materiali come se non fosse selezionato nessun
            materiale (è obbligatorio selezionarne almeno uno). Questo accade se si modifica il valore di un parametro, e senza perdere il focus si seleziona un materiale. A
            questo punto vengono invocate in parallelo le funzioni onBlurConfiguratorFormInput (per update parametro) e onChangeConfiguratorFormInput (per update selezione materiale
            da lavorare). Questo portava ad un aggiornamento sbagliato del modello modelconfiguration.
            Introdotto workaround per eseguire le funzioni in sequenza.
        */
        self.setState(({ isCutterModelConfigFormsAgainstFormValuesRunning }) => ({ isCutterModelConfigFormsAgainstFormValuesRunning: true }))

        self.props.getCutterModelConfigFormsAgainstFormValues(this.props.modelConfig).then(function(res){
            console.log(" FINISH getCutterModelConfigFormsAgainstFormValues FINISH")
            self.setState(({ isCutterModelConfigFormsAgainstFormValuesRunning }) => ({ isCutterModelConfigFormsAgainstFormValuesRunning: false }))
        })
    }

    /*
        Restituisce la form di configuraizione principale (quella accanto al ForgeViewer)
    */
    getMainFormToRender = () => {
        //console.log(" getMainFormToRender",  this.props.modelConfig.configuration)
        var _this= this
        var formListToRender = []
        var keyDiv = ""
        var keyInput = ""
        var keyLabel = ""
        var mainForm = this.props.modelConfig.configuration.find(c => c.formTemplateCode === "FRS_CONF")
        
        mainForm.configurationForm.map(function(formStep, index) {
    
            // console.log("formStep ", formStep)
            keyDiv = "div_" + mainForm.formTemplateCode + "_" + formStep.formDetailCode
            keyInput = "input_" + mainForm.formTemplateCode + "_" + formStep.formDetailCode 
            keyLabel = "label_" + mainForm.formTemplateCode + "_" + formStep.formDetailCode

            switch(formStep.inputType) {
                case "text":
                    //console.log("formStep.instance.value", formStep.instance.value)
                    if(formStep.dimmed === 1) {
                        
                        formListToRender.push(                   
                            <div key={keyDiv} className={formStep.divClassName}>
                                <label key={keyLabel} className={formStep.labelClassName} htmlFor={formStep.formDetailCode}>{formStep.inputLabel}</label>
                                <input key={keyInput} type="text" readOnly className={formStep.inputClassName} id={mainForm.formTemplateCode + "__" + formStep.formDetailCode} name={formStep.formDetailCode} aria-describedby={formStep.inputLabel} placeholder={formStep.inputLabel} value={ typeof formStep.instance.value === "undefined" ? formStep.inputDefaultValue : formStep.instance.value} onChange={(e) => _this.onChangeConfiguratorFormInput(e) } />
                            </div>
                        )
                    }
                    else {
                        formListToRender.push(
                            <div key={keyDiv} className={formStep.divClassName}>
                                <label key={keyLabel} className={formStep.labelClassName} htmlFor={formStep.formDetailCode}>{formStep.inputLabel}</label>
                                <input key={keyInput} type="text" className={formStep.inputClassName} id={mainForm.formTemplateCode + "__" + formStep.formDetailCode} name={formStep.formDetailCode} aria-describedby={formStep.inputLabel} placeholder={formStep.inputLabel} value={ typeof formStep.instance.value === "undefined" ? formStep.inputDefaultValue : formStep.instance.value } onChange={(e) => _this.onChangeConfiguratorFormInput(e) } onBlur = {(e) => _this.onBlurConfiguratorFormInput(e) } />
                            </div>
                        )
                    }
                    
                    break;
                case "number":
                        //console.log("formStep.instance.value", formStep.instance.value)
                        
                        if(formStep.dimmed === 1) {
                            console.log("formStep", formStep, formStep.instance.value)
                            formListToRender.push(
                                <div key={keyDiv} className={formStep.divClassName}>
                                    <label key={keyLabel} className={formStep.labelClassName} htmlFor={mainForm.formTemplateCode + "__" + formStep.formDetailCode}>{formStep.inputLabel}</label>
                                    <input key={keyInput} type="number" readOnly className={formStep.inputClassName} id={mainForm.formTemplateCode + "__" + formStep.formDetailCode} name={formStep.formDetailCode} aria-describedby={formStep.inputLabel} placeholder={formStep.inputLabel} value={ formStep.instance.value } />
                                </div>
                            )
                        }
                        else {
                            formListToRender.push(
                                <div key={keyDiv} className={formStep.divClassName}>
                                    <label key={keyLabel} className={formStep.labelClassName} htmlFor={mainForm.formTemplateCode + "__" + formStep.formDetailCode}>{formStep.inputLabel}</label>
                                    <input key={keyInput} type="number" className={formStep.inputClassName} id={mainForm.formTemplateCode + "__" + formStep.formDetailCode} name={formStep.formDetailCode} aria-describedby={formStep.inputLabel} placeholder={formStep.inputLabel} value={ formStep.instance.value || formStep.inputDefaultValue } onChange={(e) => _this.onChangeConfiguratorFormInput(e) } onBlur = {(e) => _this.onBlurConfiguratorFormInput(e) } />
                                </div>
                            )
                        }
                        
                        break;
                case "select":
                    var options = []
                    var keyOpt = ""
                    // console.log("select formstep", formStep)
                    formStep.inputValuesList.map(function(selectValue, index){
                        keyOpt = "option_" + formStep.formDetailCode + "_" + index;            
                        
                        var instanceV = formStep.instance.value
                        
                        if(instanceV  === selectValue) {
                            options.push(
                                <option selected key={keyOpt}>{selectValue}</option>
                            )
                        }
                        else {
                            options.push(
                                <option key={keyOpt}>{selectValue}</option>
                            )
                        }
                        
                    })
                    
                    if(formStep.dimmed === 1) {
                        formListToRender.push(
                            <div key={keyDiv} className={formStep.divClassName}>
                                <label key={keyLabel} className={formStep.labelClassName} htmlFor={formStep.formDetailCode}>{formStep.inputLabel}</label>
                                <select key={keyInput} disabled className={formStep.inputClassName} id={mainForm.formTemplateCode + "__" + formStep.formDetailCode} >
                                    { options }
                                </select>
                            </div>
                        )
                    }
                    else {
                        formListToRender.push(
                            <div key={keyDiv} className={formStep.divClassName}>
                                <label key={keyLabel} className={formStep.labelClassName} htmlFor={formStep.formDetailCode}>{formStep.inputLabel}</label>
                                <select key={keyInput} className={formStep.inputClassName} id={mainForm.formTemplateCode + "__" + formStep.formDetailCode} onChange={(e) => _this.onChangeConfiguratorFormInput(e)} onBlur = {(e) => _this.onBlurConfiguratorFormInput(e) } >
                                    { options }
                                </select>
                            </div>
                        )
                    }
                    
                    break;
                case "checkbox":
                    
                    if(formStep.dimmed === 1) {
                        formListToRender.push(
                            <div key={keyDiv} className={formStep.divClassName}>
                                <input key={keyInput} disabled className={formStep.inputClassName} type="checkbox" value={formStep.formDetailCode} id={mainForm.formTemplateCode + "__" + formStep.formDetailCode} />
                                <label key={keyLabel} className={formStep.labelClassName} htmlFor={formStep.formDetailCode}>
                                    {formStep.inputLabel}
                                </label>
                            </div>
                        );
                    }
                    else{
                        if(formStep.instance.value === "checked" || formStep.instance.value === "true" || formStep.instance.value === "True" || formStep.instance.value === true) {
                            formListToRender.push(
                                <div key={keyDiv} className={formStep.divClassName}>
                                    <input key={keyInput} className={formStep.inputClassName} type="checkbox" checked value={formStep.formDetailCode} id={mainForm.formTemplateCode + "__" + formStep.formDetailCode} onChange={(e) => _this.onChangeConfiguratorFormInput(e) } onBlur = {(e) => _this.onBlurConfiguratorFormInput(e) } />
                                    <label key={keyLabel} className={formStep.labelClassName} htmlFor={formStep.formDetailCode}>
                                        {formStep.inputLabel}
                                    </label>
                                </div>
                            );
                        }
                        else {
                            formListToRender.push(
                                <div key={keyDiv} className={formStep.divClassName}>
                                    <input key={keyInput} className={formStep.inputClassName} type="checkbox" unchecked value={formStep.formDetailCode} id={mainForm.formTemplateCode + "__" + formStep.formDetailCode} onChange={(e) => _this.onChangeConfiguratorFormInput(e) } onBlur = {(e) => _this.onBlurConfiguratorFormInput(e) } />
                                    <label key={keyLabel} className={formStep.labelClassName} htmlFor={formStep.formDetailCode}>
                                        {formStep.inputLabel}
                                    </label>
                                </div>
                            );
                        }
                        
                    }
                    
                    break;
                default:
                    break;
            }
        })
    
         //console.log("formListToRender", formListToRender)
         return formListToRender;
    }
    

    /*
        Restituisce la form contenuta nella sezione Parametri
    */
    getParametersSection = () => {

        // console.log("getParametersSection", this.props.modelConfig.configuration)
        var paramSectionForms = this.props.modelConfig.configuration.filter(c => (c.formTemplateCode === "FRS_LUBROR" || c.formTemplateCode === "FRS_ALTRO" || c.formTemplateCode === "FRS_NOTE"))

        //console.log("paramSectionForms", paramSectionForms)
        var sectionsToRender = []
        var _this = this

        paramSectionForms.forEach(sf => {
            var formListToRender = []         
            var keyDiv = ""
            var keyInput = ""
            var keyLabel = ""
            
            sf.configurationForm.map(function(formStep, index) {
        
                // console.log("formStep ", formStep)
                keyDiv = "div_" + sf.formTemplateCode + "_" + formStep.formDetailCode + "_" + index;
                keyInput = "input_" + sf.formTemplateCode + "_" + formStep.formDetailCode + "_" + index;
                keyLabel = "label_" + sf.formTemplateCode + "_" + formStep.formDetailCode + "_" + index;
                
                var iconImg = null

                if(formStep.iconImg) {
                    iconImg = <img src={ config.server + formStep.iconImg }></img>
                }
                                            

                switch(formStep.inputType) {
                    case "text":
                        //console.log("formStep.instance.value", formStep.instance.value)
                        if(formStep.dimmed === 1) {
                            formListToRender.push(
                                <div key={keyDiv} className={formStep.divClassName}>
                                    <label key={keyLabel} className={formStep.labelClassName} htmlFor={formStep.formDetailCode}>{formStep.inputLabel}</label>                                  
                                    <input key={keyInput} type="text" readOnly className={formStep.inputClassName} id={sf.formTemplateCode + "__" + formStep.formDetailCode} name={formStep.formDetailCode} aria-describedby={formStep.inputLabel} placeholder={formStep.inputLabel} value={ typeof formStep.instance.value === "undefined" ? formStep.inputDefaultValue : formStep.instance.value} />
                                </div>
                            )
                        }
                        else {
                            formListToRender.push(
                                <div key={keyDiv} className={formStep.divClassName}>
                                    <label key={keyLabel} className={formStep.labelClassName} htmlFor={formStep.formDetailCode}>{formStep.inputLabel}</label>
                                    <input key={keyInput} type="text" className={formStep.inputClassName} id={sf.formTemplateCode + "__" + formStep.formDetailCode} name={formStep.formDetailCode} aria-describedby={formStep.inputLabel} placeholder={formStep.inputLabel} value={ typeof formStep.instance.value === "undefined" ? formStep.inputDefaultValue : formStep.instance.value } onChange={(e) => _this.onChangeConfiguratorFormInput(e) } />
                                </div>
                            )
                        }
                        
                        break;
                    case "textarea":
                            //console.log("formStep.instance.value", formStep.instance.value)
                            if(formStep.dimmed === 1) {
                                formListToRender.push(
                                    <div key={keyDiv} className={formStep.divClassName}>
                                        <label key={keyLabel} className={formStep.labelClassName} htmlFor={formStep.formDetailCode}>{formStep.inputLabel}</label>                                  
                                        <input key={keyInput} type="textarea" readOnly className={formStep.inputClassName} id={sf.formTemplateCode + "__" + formStep.formDetailCode} name={formStep.formDetailCode} aria-describedby={formStep.inputLabel} placeholder={formStep.inputLabel} value={ typeof formStep.instance.value === "undefined" ? formStep.inputDefaultValue : formStep.instance.value} />
                                    </div>
                                )
                            }
                            else {
                                formListToRender.push(
                                    <div key={keyDiv} className={formStep.divClassName}>
                                        <label key={keyLabel} className={formStep.labelClassName} htmlFor={formStep.formDetailCode}>{formStep.inputLabel}</label>
                                        <textarea key={keyInput}  className={formStep.inputClassName} id={sf.formTemplateCode + "__" + formStep.formDetailCode} name={formStep.formDetailCode} aria-describedby={formStep.inputLabel} placeholder={formStep.inputLabel} value={ typeof formStep.instance.value === "undefined" ? formStep.inputDefaultValue : formStep.instance.value } onChange={(e) => _this.onChangeConfiguratorFormInput(e) } />
                                    </div>
                                )
                            }
                            
                        break;
                    case "number":
                            //console.log("formStep.instance.value", formStep.instance.value)
                            if(formStep.dimmed === 1) {
                                formListToRender.push(
                                    <div key={keyDiv} className={formStep.divClassName}>
                                        <label key={keyLabel} className={formStep.labelClassName} htmlFor={formStep.formDetailCode}>{formStep.inputLabel}</label>
                                        <input key={keyInput} type="number" readOnly className={formStep.inputClassName} id={sf.formTemplateCode + "__" + formStep.formDetailCode} name={formStep.formDetailCode} aria-describedby={formStep.inputLabel} placeholder={formStep.inputLabel} defaultValue={formStep.instance.value || formStep.inputDefaultValue} />
                                    </div>
                                )
                            }
                            else {
                                formListToRender.push(
                                    <div key={keyDiv} className={formStep.divClassName}>
                                        <label key={keyLabel} className={formStep.labelClassName} htmlFor={formStep.formDetailCode}>{formStep.inputLabel}</label>
                                        <input key={keyInput} type="number" className={formStep.inputClassName} id={sf.formTemplateCode + "__" + formStep.formDetailCode} name={formStep.formDetailCode} aria-describedby={formStep.inputLabel} placeholder={formStep.inputLabel} value={ formStep.instance.value || formStep.inputDefaultValue } onChange={(e) => _this.onInputChange(e, formStep)} />
                                    </div>
                                )
                            }
                            
                            break;
                    case "select":
                        var options = []
                        var keyOpt = ""
                        // console.log("select formstep", formStep)
                        formStep.inputValuesList.map(function(selectValue, index){
                            keyOpt = "option_" + formStep.formDetailCode + "_" + index;                      
                            var instanceV = formStep.instance.value
                            
                            if(instanceV  === selectValue) {
                                options.push(
                                    <option selected key={keyOpt}>{selectValue}</option>
                                )
                            }
                            else {
                                options.push(
                                    <option key={keyOpt}>{selectValue}</option>
                                )
                            }
                            
                        })
                        
                        if(formStep.dimmed === 1) {
                            formListToRender.push(
                                <div key={keyDiv} className={formStep.divClassName}>
                                    <label key={keyLabel} className={formStep.labelClassName} htmlFor={formStep.formDetailCode}>{formStep.inputLabel}</label>
                                    <select key={keyInput} disabled className={formStep.inputClassName} id={sf.formTemplateCode + "__" + formStep.formDetailCode} onChange={(e) => _this.onChangeConfiguratorFormInput(e)} >
                                        { options }
                                    </select>
                                </div>
                            )
                        }
                        else {
                            formListToRender.push(
                                <div key={keyDiv} className={formStep.divClassName}>
                                    <label key={keyLabel} className={formStep.labelClassName} htmlFor={formStep.formDetailCode}>{formStep.inputLabel}</label>
                                    <select key={keyInput} className={formStep.inputClassName} id={sf.formTemplateCode + "__" + formStep.formDetailCode} onChange={(e) => _this.onChangeConfiguratorFormInput(e)} >
                                        { options }
                                    </select>
                                </div>
                            )
                        }
                        
                        break;
                    case "checkbox":
                   
                        if(formStep.dimmed === 1) {
                            formListToRender.push(
                                <div key={keyDiv} className={formStep.divClassName}>
                                     { iconImg   }
                                    <input key={keyInput} disabled className={formStep.inputClassName} type="checkbox" value={formStep.formDetailCode} id={sf.formTemplateCode + "__" + formStep.formDetailCode} onChange={(e) => _this.onChangeConfiguratorFormInput(e) } />
                                    <label key={keyLabel} className={formStep.labelClassName} htmlFor={formStep.formDetailCode}>{formStep.inputLabel}</label>
                                </div>
                            );
                        }
                        else{
                            if(formStep.instance.value === "checked" || formStep.instance.value === "true" || formStep.instance.value === "True" || formStep.instance.value === true) {
                                formListToRender.push(
                                    <div key={keyDiv} className={formStep.divClassName}>
                                        { iconImg   }
                                        <input key={keyInput} className={formStep.inputClassName} type="checkbox" checked id={sf.formTemplateCode + "__" + formStep.formDetailCode} onChange={(e) =>_this.onChangeConfiguratorFormInput(e) } />
                                        <label key={keyLabel} className={formStep.labelClassName} htmlFor={formStep.formDetailCode}>{formStep.inputLabel}</label>
                                    </div>
                                );
                            }
                            else {
                                formListToRender.push(
                                    <div key={keyDiv} className={formStep.divClassName}>
                                        { iconImg   }
                                        <input key={keyInput} className={formStep.inputClassName} type="checkbox" unchecked id={sf.formTemplateCode + "__" + formStep.formDetailCode} onChange={(e) =>_this.onChangeConfiguratorFormInput(e) } />
                                        <label key={keyLabel} className={formStep.labelClassName} htmlFor={formStep.formDetailCode}>{formStep.inputLabel}</label>
                                    </div>
                                );
                            }
                            
                        }
                        
                        break;
                    case "radio":
                        if(formStep.dimmed === 1) {
                            formListToRender.push(
                                <div key={keyDiv} className={formStep.divClassName}>
                                     { iconImg   }
                                    <input key={keyInput} name={sf.formTemplateCode} disabled className={formStep.inputClassName} type="radio" value={formStep.formDetailCode} id={sf.formTemplateCode + "__" + formStep.formDetailCode} onChange={(e) => _this.onChangeConfiguratorFormInput(e) } />
                                    <label key={keyLabel} className={formStep.labelClassName} htmlFor={formStep.formDetailCode}>{formStep.inputLabel}</label>
                                </div>
                            );
                        }
                        else{
                            if(formStep.instance.value === "checked" || formStep.instance.value === "true") {
                                formListToRender.push(
                                    <div key={keyDiv} className={formStep.divClassName}>
                                        { iconImg   }
                                        <input key={keyInput} checked name={sf.formTemplateCode}  className={formStep.inputClassName} type="radio" value={formStep.formDetailCode} id={sf.formTemplateCode + "__" + formStep.formDetailCode} onChange={(e) => _this.onChangeConfiguratorFormInput(e) } onBlur = {(e) => _this.onBlurConfiguratorFormInput(e) }/>
                                        <label key={keyLabel} className={formStep.labelClassName} htmlFor={formStep.formDetailCode}>{formStep.inputLabel}</label>
                                    </div>
                                );
                            }
                            else {
                                formListToRender.push(
                                    <div key={keyDiv} className={formStep.divClassName}>
                                        { iconImg   }
                                        <input key={keyInput} name={sf.formTemplateCode}  className={formStep.inputClassName} type="radio" value={formStep.formDetailCode} id={sf.formTemplateCode + "__" + formStep.formDetailCode} onChange={(e) => _this.onChangeConfiguratorFormInput(e) } onBlur = {(e) => _this.onBlurConfiguratorFormInput(e) } />
                                        <label key={keyLabel} className={formStep.labelClassName} htmlFor={formStep.formDetailCode}>{formStep.inputLabel}</label>
                                    </div>
                                );
                            }
                           
                        }
                        
                        break;
                    default:
                        break;
                }
            })

            switch(sf.formTemplateCode) {
                case "FRS_ANGVER":
                    sectionsToRender.push(
                        <div className="angVerMainDiv">
                            <div>Angolo al vertice (Av)</div>
                            <form> { formListToRender } </form>
                        </div>
                    )
                    break;
                case "FRS_LUBROR":
                    sectionsToRender.push(
                        <div className="lubrorefMainDiv">
                            <div>Lubrorefrig. durante la lavorazione</div>
                            <form> { formListToRender } </form>
                        </div>
                    )
                    break;
                case "FRS_ALTRO":
                    sectionsToRender.push(
                        <div className="altroMainDiv">
                            <div>Altro</div>
                            <form> { formListToRender } </form>
                        </div>
                    )
                    break;
                case "FRS_NOTE":
                    sectionsToRender.push(
                        <div className="noteMainDiv">
                            <div>Note</div>
                            <form> { formListToRender } </form>
                        </div>
                    )
                    break;
            }
        
            //console.log("formListToRender", formListToRender)
            
        })

        return sectionsToRender;
    }

    /*
        Restituisce la form contenuta nella sezione Materiali da lavorare
    */
    getMaterialsSection = () => {
        //console.log("getMaterialsSection", this.props.modelConfig.configuration)
        var matSectionForms = this.props.modelConfig.configuration.filter(c => (c.formTemplateCode === "FRS_MATPOLIM" || c.formTemplateCode === "FRS_MATRAME" || c.formTemplateCode === "FRS_MATLAV" ))

        var sectionsToRender = []
        var _this = this

        matSectionForms.forEach(sf => {
            var formListToRender = []         
            var keyDiv = ""
            var keyInput = ""
            var keyLabel = ""
            
            sf.configurationForm.map(function(formStep, index) {
                //console.log("formStep ", formStep)
                keyDiv = "div_" + sf.formTemplateCode + "_" + formStep.formDetailCode + "_" + index;
                keyInput = "input_" + sf.formTemplateCode + "_" + formStep.formDetailCode + "_" + index;
                keyLabel = "label_" + sf.formTemplateCode + "_" + formStep.formDetailCode + "_" + index;
                
                var iconImg = null

                if(formStep.iconImg) {
                    iconImg = <img src={ config.server + formStep.iconImg }></img>
                }
                                            

                switch(formStep.inputType) {
                    case "text":
                        //console.log("formStep.instance.value", formStep.instance.value)
                        if(formStep.dimmed === 1) {
                            formListToRender.push(
                                <div key={keyDiv} className={formStep.divClassName}>
                                    <label key={keyLabel} className={formStep.labelClassName} htmlFor={formStep.formDetailCode}>{formStep.inputLabel}</label>                                  
                                    <input key={keyInput} type="text" readOnly className={formStep.inputClassName} id={sf.formTemplateCode + "__" + formStep.formDetailCode} name={formStep.formDetailCode} aria-describedby={formStep.inputLabel} placeholder={formStep.inputLabel} value={ typeof formStep.instance.value === "undefined" ? formStep.inputDefaultValue : formStep.instance.value} />
                                </div>
                            )
                        }
                        else {
                            formListToRender.push(
                                <div key={keyDiv} className={formStep.divClassName}>
                                    <label key={keyLabel} className={formStep.labelClassName} htmlFor={formStep.formDetailCode}>{formStep.inputLabel}</label>
                                    <input key={keyInput} type="text" className={formStep.inputClassName} id={sf.formTemplateCode + "__" + formStep.formDetailCode} name={formStep.formDetailCode} aria-describedby={formStep.inputLabel} placeholder={formStep.inputLabel} value={ typeof formStep.instance.value === "undefined" ? formStep.inputDefaultValue : formStep.instance.value } onChange={(e) => _this.onChangeConfiguratorFormInput(e) } />
                                </div>
                            )
                        }
                        
                        break;
                    case "number":
                            //console.log("formStep.instance.value", formStep.instance.value)
                            if(formStep.dimmed === 1) {
                                formListToRender.push(
                                    <div key={keyDiv} className={formStep.divClassName}>
                                        <label key={keyLabel} className={formStep.labelClassName} htmlFor={formStep.formDetailCode}>{formStep.inputLabel}</label>
                                        <input key={keyInput} type="number" readOnly className={formStep.inputClassName} id={sf.formTemplateCode + "__" + formStep.formDetailCode} name={formStep.formDetailCode} aria-describedby={formStep.inputLabel} placeholder={formStep.inputLabel} defaultValue={formStep.instance.value || formStep.inputDefaultValue} />
                                    </div>
                                )
                            }
                            else {
                                formListToRender.push(
                                    <div key={keyDiv} className={formStep.divClassName}>
                                        <label key={keyLabel} className={formStep.labelClassName} htmlFor={formStep.formDetailCode}>{formStep.inputLabel}</label>
                                        <input key={keyInput} type="number" className={formStep.inputClassName} id={sf.formTemplateCode + "__" + formStep.formDetailCode} name={formStep.formDetailCode} aria-describedby={formStep.inputLabel} placeholder={formStep.inputLabel} value={ formStep.instance.value || formStep.inputDefaultValue } onChange={(e) => _this.onInputChange(e, formStep)} />
                                    </div>
                                )
                            }
                            
                            break;
                    case "select":
                        var options = []
                        var keyOpt = ""
                        // console.log("select formstep", formStep)
                        formStep.inputValuesList.map(function(selectValue, index){
                            keyOpt = "option_" + formStep.formDetailCode + "_" + index;                      
                            var instanceV = formStep.instance.value
                            
                            if(instanceV  === selectValue) {
                                options.push(
                                    <option selected key={keyOpt}>{selectValue}</option>
                                )
                            }
                            else {
                                options.push(
                                    <option key={keyOpt}>{selectValue}</option>
                                )
                            }
                            
                        })
                        
                        if(formStep.dimmed === 1) {
                            formListToRender.push(
                                <div key={keyDiv} className={formStep.divClassName}>
                                    <label key={keyLabel} className={formStep.labelClassName} htmlFor={formStep.formDetailCode}>{formStep.inputLabel}</label>
                                    <select key={keyInput} disabled className={formStep.inputClassName} id={sf.formTemplateCode + "__" + formStep.formDetailCode} onChange={(e) =>_this.onChangeConfiguratorFormInput(e)} >
                                        { options }
                                    </select>
                                </div>
                            )
                        }
                        else {
                            formListToRender.push(
                                <div key={keyDiv} className={formStep.divClassName}>
                                    <label key={keyLabel} className={formStep.labelClassName} htmlFor={formStep.formDetailCode}>{formStep.inputLabel}</label>
                                    <select key={keyInput} className={formStep.inputClassName} id={sf.formTemplateCode + "__" + formStep.formDetailCode} onChange={(e) => _this.onChangeConfiguratorFormInput(e)} >
                                        { options }
                                    </select>
                                </div>
                            )
                        }
                        
                        break;
                    case "checkbox":
                        if(formStep.dimmed === 1) {
                            formListToRender.push(
                                <div key={keyDiv} className={formStep.divClassName}>
                                     { iconImg   }
                                    <label key={keyLabel} className={formStep.labelClassName} htmlFor={formStep.formDetailCode}>{formStep.inputLabel}</label>
                                    <input key={keyInput} disabled className={formStep.inputClassName} type="checkbox" value={formStep.formDetailCode} id={sf.formTemplateCode + "__" + formStep.formDetailCode} onChange={(e) => _this.onChangeConfiguratorFormInput(e) } />                                   
                                </div>
                            );
                        }
                        else{
                            formListToRender.push(
                                <div key={keyDiv} className={formStep.divClassName}>
                                    { iconImg   }
                                    <label key={keyLabel} className={formStep.labelClassName} htmlFor={formStep.formDetailCode}>{formStep.inputLabel}</label>
                                    <input key={keyInput} className={formStep.inputClassName} type="checkbox" value={formStep.formDetailCode} id={sf.formTemplateCode + "__" + formStep.formDetailCode} onChange={(e) => _this.onChangeConfiguratorFormInput(e) } />                                   
                                </div>
                            );
                        }
                        
                        break;
                    default:
                        break;
                }
            })

            switch(sf.formTemplateCode) {
                case "FRS_MATPOLIM":
                    sectionsToRender.push(
                        <div className="lubrorefMainDiv">
                            <div>Materiali Polimerici</div>
                            <form id={sf.formTemplateCode}> { formListToRender } </form>
                        </div>
                    )
                    break;
                case "FRS_MATRAME":
                    sectionsToRender.push(
                        <div className="lubrorefMainDiv">
                            <div>Materiali a base di rame</div>
                            <form id={sf.formTemplateCode}> { formListToRender } </form>
                        </div>
                    )
                    break;
                case "FRS_MATLAV":
                    sectionsToRender.push(
                        <div className="lubrorefMainDiv">
                            <div>Materiali da lavorare</div>
                            <form id={sf.formTemplateCode}> { formListToRender } </form>
                        </div>
                    )
                    break;
            }
        })

        

        return sectionsToRender
    }

    /*
        Funzione che verifica se tutti i campi input obbligatori sono stati valorizzati. In caso contrario, va ad aggiungere un bordo rosso
            per i campi che sono da compilare e ne setta il focus.
    */
    checkFormMandatory = (e) => {
        // console.log("checkFormMandatory")
        var errors = false
        this.props.modelConfig.configuration.forEach(c => {
            c.configurationForm.forEach(cf => {
                var el = document.getElementById(c.formTemplateCode + "__" + cf.formDetailCode)
                if(cf.mandatory === 1 && (!cf.instance.value || cf.instance.value === "")) {
                    el.style.border = "1px solid red"
                    el.focus()
                    errors = true
                }
                else {
                    el.style.border = "none"
                }
            })
        })

        // verifica sezione materiali da lavorare
        
        var polimConf = this.props.modelConfig.configuration.find(c => c.formTemplateCode === "FRS_MATPOLIM")
        var rameConf = this.props.modelConfig.configuration.find(c => c.formTemplateCode === "FRS_MATRAME")
        var matLavConf = this.props.modelConfig.configuration.find(c => c.formTemplateCode === "FRS_MATLAV")
        var matLavSection = [...polimConf.configurationForm, ...rameConf.configurationForm , ...matLavConf.configurationForm]

       // matLavSection.forEach(s => {
            var chboxSelected = false

            for(var i=0; i < matLavSection.length; i++){
                var cf = matLavSection[i]
                if(cf.instance.value === "checked" || cf.instance.value.toString() === "true" || cf.instance.value.toString() === "True" ) {
                    chboxSelected = true;
                    break
                }
            }

            var els = document.getElementsByClassName("cutterMaterialsSectionPopup")

            //console.log("els", els)

            if(els.length > 0) {
                var el = els[0]

                if(!chboxSelected){
                    el.style.border = "2px solid red"
                    el.scrollIntoView({ behavior: 'smooth' });
                    errors = true
                }
                else {
                    el.style.border = "none"
                }
            }

           
                
          
       // })




        // console.log(errors)
        return errors
    }

    CheckCutterModelRules = (e) => {       

        if(this.checkFormMandatory()) {
            return
        }

        var oldConfig = JSON.parse(JSON.stringify(this.props.modelConfig))
        var self = this
        console.log("--- CheckCutterModelRules ---", oldConfig)
        var oldMainConf = oldConfig.configuration.find(c => c.formTemplateCode === "FRS_CONF")

        var changes = false;

        this.props.CheckCutterModelRules(this.props.modelConfig).then(function(res){
            console.log("res", res)
            console.log("oldMainConf", oldMainConf)

            var newMainConf = self.props.modelConfig.configuration.find(c => c.formTemplateCode === "FRS_CONF")

            oldMainConf.configurationForm.forEach(cf => {
                var newCf = newMainConf.configurationForm.find(ncf => ncf.formDetailCode === cf.formDetailCode )
                var el = document.getElementById(oldMainConf.formTemplateCode + "__" + cf.formDetailCode)

                console.log("el", el)
                
                if(newCf.instance.value !== cf.instance.value){
                    changes = true
                    el.style.border = "1px solid red"
                }
                else {
                    el.style.border = "none"
                }
            })

            if(!changes){
                self.updateModel(e)
            }
            else {
                self.setState(({ isWarningModalOpen }) => ({ isWarningModalOpen: true }))
            }
        })
    }

    getFormButtons = () => {
        //if(this.props.outputIam && this.props.outputIam !== "") {
            if(true) {
            return[
                <div key="modelConfFormBtnContainer" className="modelConfFormBtnContainer">
                    <button key="button_upd" type="button" className="btn btn-primary btnConfUpd" onClick={ (e) => this.CheckCutterModelRules(e) } >Update</button>
                    <button key="button_confirm" type="button" className="btn btn-light btnConfAddCart" onClick={ (e) => this.openModal(e) }><i role="img" className="bi bi-cart-plus"  ></i></button>
                </div>
            ]
        }
        else {
            return[
                <div className="modelConfFormBtnContainer">
                    <button key="button_upd" type="button" className="btn btn-primary btnConfUpd" >Update</button>
                    <button key="button_confirm" type="button" disabled className="btn btn-light btnConfAddCart" onClick={ (e) => this.openModal(e) }><i role="img" className="bi bi-cart-plus"  ></i></button>
                </div>
            ]
        }
    }

     /*
        Controlla se sono già presenti articoli a carrello. In caso affermativo, apre la modal che avvisa l utente. In caso contrario apre la modal per confermare la configurazione scelta.
    */
    openModal = (e) => {
            //e.preventDefault();
            
            if(this.props.itemInCart.length > 0){
                this.openModalCartNotEmptyWarn();
            }
            else {
                
               console.log(this.checkFormMandatory())
               if(!this.checkFormMandatory()) {
                    this.openModalConfirm();
               }
                
            }
    }
    
    openModalConfiguratorInfo = (e) => {
        this.setState(({ isConfigurationInfoModalOpen }) => ({ isConfigurationInfoModalOpen: true }))
    }

    /*
        Inizializza lo stato di ModalConfirm e apre la modal
            Apre la modal per visualizzare ed eventualmente confermare la configurazione scelta
    */
    openModalConfirm = () => {
    
            this.setState(({ isCartNotEmptyWarnModalOpen }) => ({ isCartNotEmptyWarnModalOpen: false }))
    
            var confirmRedirectTo = "/cart" // redirezione su /cart se utente conferma
            var allConfigs = []
            // var configPnt = this.props.modelConfig.configuration.find( c => c.formTemplateCode === "PNT_CONF")
            // var config = GetConfigurationDetailMarkup(configPnt.configurationForm) // configurazione del modello da visualizzare // configurazione del modello da visualizzare

            var config = GetConfigurationDetailMarkupForCutters(this.props.modelConfig.configuration)

            // Funzione che restituisce il markup da settare come contenuto della modal
            var getContent = function() {         
                return  <div id="modalConfirmConfigTopContainer" className="modalConfirmContentTopDiv">
                            <h3><FormattedMessage
                                    id = "ModelConfigurator.ConfirmConfiguration"
                                    defaultMessage="Conferma Configurazione"
                                /></h3>
                            <div id="modalConfirmConfigDiv" className="modalConfirmConfigDiv">
                                { config }
                            </div>
                        </div>
            }
    
            // Stile css della ModalConfirm
            var customStyles = {
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    width: "60%",
                    border: "1px solid black",             
                  }
            }
    
            // Inizializza lo stato della ModalConfirm
            this.props.initializeModalConfirm(confirmRedirectTo, getContent, customStyles)
            // Apre la ModalConfirm
            this.setState(({ isConfirmModalOpen }) => ({ isConfirmModalOpen: true }))
    }

    /* Chiude la ModalConfirm, se aperta */
    closeModalConfirm = () => {
            this.setState(({ isConfirmModalOpen }) => ({ isConfirmModalOpen: false }))
    }

    closeWarningModal = () => {
        this.setState(({ isWarningModalOpen }) => ({ isWarningModalOpen: false }))
    }

     /*
        Apre la modal che avvisa l utente della presenza di un articolo a carrello. Se conferma, tale articolo a carrello verrà sostituito dall articolo corrente
    */
        openModalCartNotEmptyWarn = () => {

            // Funzione che restituisce il markup da settare come contenuto della modal
            var getContent = function() {         
               return  <div id="modalConfirmConfigTopContainer" className="modalConfirmContentTopDiv">
                           <h3>
                           <FormattedMessage
                                   id = "ModelConfigurator.ModalCartNotEmptyWarn.Title"
                                   defaultMessage="Attenzione"
                               />
                               </h3>
                           <div className="">
                               <p>
                               <FormattedMessage
                                   id = "ModelConfigurator.ModalCartNotEmptyWarn.P1"
                                   defaultMessage="Nel carrello è presente un articolo configurato."
                               />
                                   </p> 
                               <p>
                               <FormattedMessage
                                   id = "ModelConfigurator.ModalCartNotEmptyWarn.P2"
                                   defaultMessage="Conferma se desideri sostituirlo con l'articolo corrente."
                               />
                               </p>
                           </div>
                       </div>
           }
   
           // Stile css della ModalConfirm
           var customStyles = {
               content: {
                   top: '50%',
                   left: '50%',
                   right: 'auto',
                   bottom: 'auto',
                   marginRight: '-50%',
                   transform: 'translate(-50%, -50%)',
                   width: "50%",
                   border: "1px solid black",             
                 }
           }
   
           // Inizializza lo stato della ModalConfirm
           this.props.initializeModalConfirm("", getContent, customStyles)
           // Apre la ModalConfirm
           this.setState(({ isCartNotEmptyWarnModalOpen }) => ({ isCartNotEmptyWarnModalOpen: true }))
    }

    /* Chiude la ModalConfirm (se aperta) che avverte l utente della presenza di articoli a carrello */
    closeModalCartNotEmptyWarn = () => {
        this.setState(({ isCartNotEmptyWarnModalOpen }) => ({ isCartNotEmptyWarnModalOpen: false }))
    }

    closeModalConfigurationInfo = () => {
        this.setState(({ isConfigurationInfoModalOpen }) => ({ isConfigurationInfoModalOpen: false }))
    }

    // Aggiunge articolo (prelevato dallo stato di modelconfigurator) a carrello
    addItemInCart = (e) => {
        // console.log("***** addItemInCart ***** ", this.props.item)
        e.preventDefault();
        var item = this.props.item

        console.log("addItemInCart", this.props.modelConfig)
        item.modelConfiguration = this.props.modelConfig;
        item.outputIam = this.props.outputIam; // identificativo dello iam nel bucket forge. Utilizzato per generare pdf.
        item.forgeBucketName = this.props.modelConfig.forgeBucketName
        item.objectName = this.props.modelConfig.forgeObjectName
        item.assemblyRoot = this.props.modelConfig.assemblyRoot
        
        this.props.addToCart(item)
        // recupero descrizione in base a configurazione
        // setta nel modello dell item la descrizione in base alla configurazione        
        var dataRequest = JSON.stringify({Item: item, ModelConfiguration: item.modelConfiguration })   
        this.props.getConfigurationDescription(dataRequest)
        this.setState(({ isConfirmModalOpen }) => ({ isConfirmModalOpen: false }))
    }

    /* Aggiorna il modello 3D con i nuovi parametri impostati dall utente */
    updateModel(event) {
        event.preventDefault();

        this.props.setLoadingSpinnerVisibility(true)
        console.log("***updateModel this.props.modelConfig", this.props.modelConfig)

        var parameters = "{"
        var paramForReducer = []

        this.props.modelConfig.configuration.forEach(conf => {
            conf.configurationForm.forEach(confInput => {

                //console.log("confInput", confInput)
                
                if(confInput.isForgeConfigParam) {
                     /*
                    TODO
                        aggiungere la gestione per tutti i tipi di input, non solo text/select
    
                    */
                    if(confInput.inputType === "text" || confInput.inputType === "select" || confInput.inputType === "number"){
                        // console.log(document.getElementById(confInput.formDetailCode).value)
                        var val = document.getElementById(conf.formTemplateCode + "__" + confInput.formDetailCode).value
                        if(val && val !== "") {
                            parameters = parameters + " \"" + confInput.formDetailCode + "\" : { \"Value\": \""  + val + "\" }  ,"                    
                            paramForReducer.push({ formDetailCode:  confInput.formDetailCode, value: val })
                        }                    
                    }
        
                    if(confInput.inputType === "checkbox"){
                        //console.log( confInput.formDetailCode, " ", document.querySelector('#' + conf.formTemplateCode + "__" + confInput.formDetailCode + ':checked'))
        
                        if(document.querySelector('#' + conf.formTemplateCode + "__" + confInput.formDetailCode + ':checked')) {
                            parameters = parameters + " \"" + confInput.formDetailCode + "\" : { \"Value\": \""  + "True" + "\" }  ,"                    
                            paramForReducer.push({ formDetailCode:  confInput.formDetailCode, value: "True" })
                        }
                        else {
                            parameters = parameters + " \"" + confInput.formDetailCode + "\" : { \"Value\": \""  + "False" + "\" }  ,"                    
                            paramForReducer.push({ formDetailCode:  confInput.formDetailCode, value: "False" })
                        }
                        
                    }
                }
                             
            })
        })  

        console.log(" this.props.modelConfig.rifHoi",  this.props.modelConfig.rifHoi)

        parameters = parameters + " \"RIFERIMENTO_HOI\" : { \"Value\": \""  + this.props.modelConfig.rifHoi + "\" }  ,"
        parameters = parameters.slice(0, -1)
        parameters = parameters + " } "

        console.log("parameters", parameters )
        
        var dataRequest = {
            appBundleName: "UpdateParameters",
            appBundleLabelVersion: "alpha",
            activityName: "UpdateProject",
            activityLabelVersion: "alpha",
            bucketName: this.props.modelConfig.forgeBucketName,
            objectName: this.props.modelConfig.forgeObjectName,
            assemblyRoot: this.props.modelConfig.assemblyRoot,
            parameters: JSON.parse(parameters)
        }

        this.props.updateModelParameters(dataRequest, (updResp) => { 
            console.log("updResp", updResp)
            var outputParameters = JSON.parse(updResp.UpdateParametersOutput)
            console.log("paramOut", outputParameters)
            this.props.setLoadingSpinnerVisibility(false)
            // this.props.updateModelParametersInReducer(paramForReducer)   
            this.props.updateModelParametersInReducer(outputParameters)    
        });
    }

    render() {
        
        if(this.props.modelConfig.configuration){
            var paramSection = this.getParametersSection() 
            var matSection = this.getMaterialsSection()

            return <div className="cutterMainDiv">
                        {this.props.loading &&
                            <LoadingSpinner isVisible="true" />
                         } 
                        {/* Modal che avvisa l utente della presenza di un articolo a carrello */}
                        <ModalConfirm onConfirm={this.openModalConfirm} closeModal={this.closeModalCartNotEmptyWarn} isOpen={this.state.isCartNotEmptyWarnModalOpen} textConfirmBtn="ModelConfigurator.ModalConfirm.ConfirmButtonLabel" /> 
                        {/* Modal per confermare la configurazione corrente e la conseguente aggiunta a carrello (se già presente un articolo a carrello, quest'ultimo verrà sostituito) */}
                        <ModalConfirm onConfirm={this.addItemInCart} closeModal={this.closeModalConfirm} isOpen={this.state.isConfirmModalOpen} iconConfirmBtn="bi bi-cart-plus" />     
                        <ModalConfiguratorInfo closeModal={this.closeModalConfigurationInfo} isOpen={this.state.isConfigurationInfoModalOpen} />       
                        <ModalSuccess onConfirm={this.closeWarningModal} title = "Attenzione"  message="Alcuni valori sono stati modificati. Ricontrolla la configurazione e procedi con l aggiornamento." isOpen={this.state.isWarningModalOpen}  /> 

                        <div className="cutterTopDiv">
                            <div className="configurationCutterMainForm">
                                <form>
                                    { this.getMainFormToRender() }
                                    { this.getFormButtons() }
                                </form>                        
                            </div>        
                            <div className="cutterTopRightDiv">
                                <div className="cutterModelDiv">
                                    <ForgeViewer svf={ this.props.svf } ></ForgeViewer> 
                                </div>       
                            </div>
                        </div>
                         
                        <PopupSection className="cutterParamSectionPopup" title="Parametri" sectionContent={paramSection}></PopupSection>
                        <PopupSection className="cutterMaterialsSectionPopup" title="Materiali da lavorare" sectionContent={matSection}></PopupSection>
                        <button className="btpOpenModalConfiguratorInfo" onClick={(e) => this.openModalConfiguratorInfo()} ><i role="img" className="bi bi-info" ></i></button>
                    </div>
        }
        else {
            return <div> </div>
        }
        
    }
}

function mapStateToProps(state) {
    //console.log("model configurator cutter state ", state)
    return {
        svf: state.modelconfigurator.svf,
        modelConfig: state.modelconfigurator.modelConfig,
        isMenuOpen: state.menu.isMenuOpen,
        item: state.modelconfigurator.item,     
        loading: state.modelconfigurator.loading,
        outputIam: state.modelconfigurator.outputIam,
        itemInCart: state.cart.itemInCart
    }
}

function mapDispatchToProps(dispatch) {
    return  {
        setLoadingSpinnerVisibility: (isVisible) => dispatch(setLoadingSpinnerVisibility(isVisible)),
        getModelConfiguratorForm: (dataRequest) => dispatch(getModelConfiguratorForm(dataRequest)),
        toogleMenuButtonClicked: () => dispatch(toogleMenuButtonClicked()),
        onChangeConfiguratorFormInput: (idInput, newValue) => dispatch(onChangeConfiguratorFormInput(idInput, newValue)),
        getCutterModelConfigFormsAgainstFormValues: (modelConfiguration) => dispatch(getCutterModelConfigFormsAgainstFormValues(modelConfiguration)),
        initializeModalConfirm: (title, getContent, customStyles) => dispatch(initializeModalConfirm(title, getContent, customStyles)),
        addToCart: (item) => dispatch(addToCart(item)),
        updateModelParameters: (dataRequest, successCB) => dispatch(updateModelParameters(dataRequest, successCB)),
        updateModelParametersInReducer: (parameters) => dispatch(updateModelParametersInReducer(parameters)),
        getConfigurationDescription: (dataRequest) => dispatch(getConfigurationDescription(dataRequest)),
        CheckCutterModelRules: (modelConfiguration) => dispatch(CheckCutterModelRules(modelConfiguration))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModelConfiguratorCutter)
