import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import {FormattedMessage} from 'react-intl';

function ModalConfirm(props) {
    

    const [isOpen, setModalConfirmOpen] = useState(false)
     Modal.setAppElement('#root');

    

        const buttonStyle= {
            border: "1px solid black",
            margin: "1rem 1rem 0 0"         
        }

        return (
            <div>
                <Modal
                   isOpen={props.isOpen}
                   // onAfterOpen={}
                   onRequestClose={props.closeModal}
                   style={props.customStyles}
                   contentLabel="Conferma" >
                       <div>
                            {props.getContent()}
                       </div>
                       <div className="floatBtnsDiv">

                            { typeof props.closeModal !== "undefined" ? 
                                <button style={buttonStyle} type="submit" className="btn btnModalConfirmExit" onClick={(e) => props.closeModal(e)}>
                                        <FormattedMessage
                                            id = "ModalConfirm.BackButtonLabel"
                                            defaultMessage="Annulla"
                                        />
                                </button>
                                : 
                                <div></div>
                            }                   
                           
                            { typeof props.confirmRedirectTo === "undefined" || props.confirmRedirectTo === "" ? 
                                <button style={buttonStyle} type="submit" className="btn btnModalConfirmConfirm" onClick={(e) => props.onConfirm(e)}><i role="img" className={props.iconConfirmBtn}  ></i>
                                    {
                                        props.textConfirmBtn && props.textConfirmBtn !== "" ?
                                            <FormattedMessage
                                                id = {props.textConfirmBtn}
                                                defaultMessage=""
                                            />
                                            :
                                            ""
                                    }
                                     
                                </button> 
                                : 
                                <button style={buttonStyle} type="submit" className="btn btnModalConfirmConfirm" onClick={(e) => props.onConfirm(e)}><Link  to={props.confirmRedirectTo} ><i role="img" className={props.iconConfirmBtn}></i>
                                    {
                                            props.textConfirmBtn && props.textConfirmBtn !== "" ?
                                                <FormattedMessage
                                                    id = {props.textConfirmBtn}
                                                    defaultMessage=""
                                                />
                                                :
                                                ""
                                        }
                                    
                                    </Link></button>
                            }                   
                        </div>
                </Modal>
            </div>
        )
    
}

function mapStateToProps(state) {
    //console.log("ModalConfirm state ", state)
    return {
        customStyles: state.modalconfirm.customStyles,
        getContent: state.modalconfirm.getContent,
        confirmRedirectTo: state.modalconfirm.confirmRedirectTo     
    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalConfirm)