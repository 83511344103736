import React, { Component } from 'react';
import { connect } from 'react-redux';
import { onChangeAccessoryGrpFormInput } from '../../actions/index';
import 'bootstrap-icons/font/bootstrap-icons.css';
import {FormattedMessage} from 'react-intl';

class CartItemAccessoryGrp extends Component {

    CartItemQty = 0;

    constructor(props) {
        super(props);
        
        this.state = {
            SelectedAcc: undefined,
            AccessoriesGrp: undefined
        }
        
    }

    componentDidMount = () => {
        this.setState(({ AccessoriesGrp }) => ({ AccessoriesGrp: this.props.accessoryGrp }))
    }


    getInfo = () => {
        // console.log("this.props.accessoryGrp", this.props.accessoryGrp)

        if(this.props.accessoryGrp.descr && this.props.accessoryGrp.descr !== ""){
            return <div>
                    <div className="accDetailContainer">
                        <p style={{fontWeight: 500, marginRight: "0.75em"}}>
                                            <FormattedMessage
                                                id = "CartAccessoryGrp.Details.Name.Label"
                                                defaultMessage="Descrizione"
                                            />:
                                        </p>
                        <p>{ this.props.accessoryGrp.accessoryGrpName }</p>
                    </div>  
                    <div className="accDetailContainer">
                        <p style={{fontWeight: 500, marginRight: "0.75em"}}>
                                            <FormattedMessage
                                                id = "CartAccessoryGrp.Details.Description.Label"
                                                defaultMessage="Descrizione"
                                            />:
                                            </p>
                        <p>{ this.props.accessoryGrp.descr }</p>
                    </div>     
                    <div>
                        {/* 
                        <label className="labelAccessoriesNotes" for={this.props.accessoryGrp.accessoryGrpCode}>Note:</label>
                        <input className="inputAccessoriesNotes" id={this.props.accessoryGrp.accessoryGrpCode} name={this.props.accessoryGrp.accessoryGrpCode} aria-describedby="" value="" />+
                        */}
                        { this.getHoffmannCatalogLink() }
                    </div>              
                </div>
        }
        else {
            return <div></div>
        }
    }

    onInputChange = (event, formStep) => {
        console.log("formStep onInputChange", formStep)
        event.preventDefault()
        // this.props.onChangeAccessoryGrpFormInput(this.props.item, formStep, event.target.id, event.target.value)
        var newAccGrpObj = JSON.parse(JSON.stringify(this.state.AccessoriesGrp))
        var accGrpFormToUpd = newAccGrpObj.accessoryGrpForm.find(f => f.iD_FormDetail === formStep.iD_FormDetail)
        var accGrpFormToUpdIndex = newAccGrpObj.accessoryGrpForm.findIndex(f => f.iD_FormDetail === formStep.iD_FormDetail)

        console.log("accGrpFormToUpd", accGrpFormToUpd)
        
        if(accGrpFormToUpd) {
            var newValue = event.target.value

            try {
                newValue = parseInt(newValue)
            }
            catch(e){
                newValue = 0
            }

            if(newValue < 0) {
                console.log("new value < 1")
                newValue = 0
            }

            accGrpFormToUpd.instance.value = newValue
            this.setState(({ AccessoriesGrp }) => ({ AccessoriesGrp: newAccGrpObj }))
            // devo resettare la variabile di stato SelectedAcc, in quanto la view verrà aggiornata
            this.setState(({ SelectedAcc }) => ({ SelectedAcc: undefined }))
            this.props.accessoryGrp.accessoryGrpForm[accGrpFormToUpdIndex].instance.value = event.target.value
        }   
        
        console.log("newAccGrpObj", newAccGrpObj)
    }

    getForm = () => {
        // console.log("getForm", this.state.AccessoriesGrp)
        var formListToRender = []
        var keyDiv = ""
        var keyInput = ""
        var keyLabel = ""  
        var _this = this

        if(this.state.AccessoriesGrp) {
            this.state.AccessoriesGrp.accessoryGrpForm.map(function(formStep, index) {

                // console.log("formStep ", formStep)
                keyDiv = "div_" + formStep.formDetailCode + "_" + index;
                keyInput = "input_" + formStep.formDetailCode + "_" + index
                keyLabel = "label_" + formStep.formDetailCode + "_" + index;
    
                switch(formStep.inputType) {
                    case "number":
                            //console.log("formStep", formStep)
                            //console.log("formStep number key", keyDiv, keyLabel, keyInput)
                            if(formStep.dimmed === 1) {
                               // console.log("formStep.instance.value", formStep.instance.value)
                                formListToRender.push(
                                    <div key={keyDiv} className={formStep.divClassName}>
                                        <label key={keyLabel} className={formStep.labelClassName} htmlFor={formStep.formDetailCode}>{formStep.inputLabel}</label>
                                        <input key={keyInput} type="number" readOnly className={formStep.inputClassName} id={formStep.formDetailCode} name={formStep.formDetailCode} aria-describedby={formStep.inputLabel} placeholder={formStep.inputLabel} value={formStep.instance.value || formStep.inputDefaultValue} onChange={(e) => _this.onInputChange(e, formStep)}  />
                                    </div>
                                )
                            }
                            else {
                                formListToRender.push(
                                    <div key={keyDiv} className={formStep.divClassName}>
                                        <label key={keyLabel} className={formStep.labelClassName} htmlFor={formStep.formDetailCode}>{formStep.inputLabel}</label>
                                        <input key={keyInput} type="number" className={formStep.inputClassName} id={formStep.formDetailCode} name={formStep.formDetailCode} aria-describedby={formStep.inputLabel} placeholder={formStep.inputLabel} value={ formStep.instance.value || formStep.inputDefaultValue } onChange={(e) => _this.onInputChange(e, formStep)} />
                                    </div>
                                )
                            }
                            
                    break;
                    case "select":
                        var options = []
                        var keyOpt = ""
                        formStep.inputValuesList.map(function(selectValue, index){
                            keyOpt = "option_" + formStep.formDetailCode + "_" + index;

                            if(formStep.instance.value === selectValue) {

                                options.push(
                                    <option selected key={keyOpt}>{selectValue}</option>
                                )

                                if(!_this.state.SelectedAcc){
                                    _this.setState(({ SelectedAcc }) => ({ SelectedAcc: selectValue }))
                                }
                                
                            }
                            else {
                                options.push(
                                    <option key={keyOpt}>{selectValue}</option>
                                )
                            }
                            
                        })
                        
                        formListToRender.push(
                            <div key={keyDiv} className={formStep.divClassName}>
                                <label key={keyLabel} className={formStep.labelClassName} htmlFor={formStep.formDetailCode}>{formStep.inputLabel}</label>
                                <select key={keyInput} className={formStep.inputClassName} id={formStep.formDetailCode} onChange={(e) => _this.onInputChange(e, formStep)}>
                                    { options }
                                </select>
                            </div>
                        )
                        
                        break;
                    default:
                        break;
                }
            })
    
            return formListToRender
        }

       
    }

    getHoffmannCatalogLink = () => {
        //console.log("getHoffmannCatalogLink")
        var _this = this
        var result = []

        if(this.state.AccessoriesGrp) {
            this.state.AccessoriesGrp.accessories.forEach(function(acc){
                if(_this.state.SelectedAcc && acc.hoffCatalogLink && acc.hoffCatalogLink !== "" && acc.accessoryCode === _this.state.SelectedAcc) {
                    result = <a target="_blank" href={acc.hoffCatalogLink}>
                        <FormattedMessage
                                id = "CartItemAccessoryGrp.LinkAccessoryDetails.Label"
                                defaultMessage="Dettagli Accessorio"
                            />
                        </a>
                }
            })
        }
       

        return result
    }

    render() {


        return(
            <div className="cartItemAccGrpContainer">
                <div className="cartAccGrpLeftSection">
                    <div className="cartAccGrpImgCont">
                        <img className="cartAccGrpImg" src={ this.props.accessoryGrp.imgUrl }  />
                    </div>
                    <div className="cartAccGrpDetail">
                        { this.getInfo() }
                    </div>
                </div>             
                <div className="cartItemAccGrpSx">                  
                    { this.getForm() }
                </div>				
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onChangeAccessoryGrpFormInput: (item, formStep, idInput, newValue) => dispatch(onChangeAccessoryGrpFormInput(item, formStep, idInput, newValue))
    }
}

export default connect(null, mapDispatchToProps)(CartItemAccessoryGrp)