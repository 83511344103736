import {  INITIALIZE_MODAL_CONFIRM } from '../actions/types';

/*
    isOpen bool : gestione visualizzazione modal
    confirmRedirectTo: route in cui effettuare il reindirizzamento dopo la conferma
    getContent: funzione che restituisce il markup che sarà il contenuto della modal
    customStyles: stile css della modal
*/
const INITIAL_STATE = {
    isOpen: false, // il controllo modal aperta/chiusa viene fatto direttamente dal controller che contiene la modal
    confirmRedirectTo: "",
    getContent: function() {},
    customStyles: {}
}

export default function(state = INITIAL_STATE, action) {
    //console.log("modelconfigurator reducer", action)
    switch(action.type) {
        case INITIALIZE_MODAL_CONFIRM:
            sessionStorage.setItem('confirmModalConfirmRedirectTo', action.payload.confirmRedirectTo )
            sessionStorage.setItem('confirmModalGetContentF', action.payload.getContent )
            sessionStorage.setItem('confirmModalCustomStyles', action.payload.customStyles )
            return {...state, confirmRedirectTo: action.payload.confirmRedirectTo, getContent: action.payload.getContent, customStyles: action.payload.customStyles }       
        default:
            return state
    }
}