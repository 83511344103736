import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner'
import { connect } from 'react-redux';

class LoadingSpinner extends Component {

    constructor(props){
        super(props);
    }

    render() {
            // console.log("LoadingSpinner isVisible",this.props.isVisible)
            return (
                this.props.isVisible === "true" ?
                    <div className="loadingSpinnerMainDiv" >
                        <Spinner animation="border" variant="primary" />
                    </div> : null
            )
    }
}

export default LoadingSpinner;

