import React, { useState } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import {FormattedMessage} from 'react-intl';

function ModalConfiguratorInfo(props) {
    

    const [isOpen, setModalConfirmOpen] = useState(false)
     Modal.setAppElement('#root');

    

        const buttonStyle= {
            border: "1px solid #FF7000",
            margin: "1rem 1rem 0 0",
            color: "#FF7000"        
        }

        return (
            <div>
                <Modal
                   isOpen={props.isOpen}
                   // onAfterOpen={}
                   onRequestClose={props.closeModal}
                   style={props.customStyles}
                   contentLabel="Conferma" >
                       <div>
                            <div><h3>Note Generali</h3></div>
                            <div>
                                <p>Il configuratore online di Hoffmann Italia è in versione Beta e per questo motivo può avere problemi di funzionamento. Nel caso contattate direttamente il servizio tecnico di Hoffmann Italia spa al <span>Tel. 049-7960209</span> o via mail <span>application.specialtools@hoffmann-group.com</span> </p>
                            </div>
                            <div>
                                <ul>
                                    <li>Le dimensioni realizzabili delle piastre possono essere diverse in funzione della loro geometria della piastra Zeropoint</li>
                                    <li>Le piastre possono essere costruite in più parti in funzione delle dimensioni e vengono assemblate in fase di montaggio da parte del cliente.</li>
                                    <li>In fase di offerta viene specificato se le piastre Zeropoint sono componibili e in quante parte saranno costituite</li>
                                    <li>I prezzi indicati dal configuratore posso subire variazioni in funzione dei costi delle materie prime</li>
                                    <li>La consegna indicata in offerta è solo indicativa</li>
                                    <li>Dopo l’accettazione dell’ordine da parte del cliente con la controfirma del disegno dell’attrezzatura definitivo è possibile dare una data precisa di consegna.</li>
                                    <li>Il prezzi per le piastre in acciaio e in alluminio non varia</li>
                                    <li>Le piastre possono essere costruite con circuitazione resinata, con foratura profonda o con entrambe le tecnologie. La scelta è di competenza di Hoffmann Italia spa. </li>
                                </ul>
                            </div>
                       </div>
                       <div className="floatBtnsDiv">

                            { typeof props.closeModal !== "undefined" ? 
                                <button style={buttonStyle} type="submit" className="btn btnModalConfirmExit" onClick={(e) => props.closeModal(e)}>
                                        <FormattedMessage
                                            id = "Ok"
                                            defaultMessage="Ok"
                                        />
                                </button>
                                : 
                                <div></div>
                            }                                    
                        </div>
                </Modal>
            </div>
        )
    
}


export default connect(null, null)(ModalConfiguratorInfo)