import { createStore, applyMiddleware, compose } from 'redux';
import reduxThunk from 'redux-thunk';
import reducers from './reducers/root.js'

// Store is the result of calling createStore.
// You may also pass an initial state to createStore, useful for server side rendering and state preloading.
// The most important concept to understand here is that the state in Redux comes from reducers; 
//      reducers produce the state of your application.
// Reducers produce the state of an application.
// A Redux reducer is just a JavaScript function. It takes two parameters: the current state and action.

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// console.log("sessionStorage", sessionStorage)

const store = createStore(
    reducers,
    {
        scroll: {
            scrollOffsetY : 0,
            scrollOffsetX : 0
        },
        catalog: {
            bp: sessionStorage.getItem('catalogBpActive') ? JSON.parse(sessionStorage.getItem('catalogBpActive')) : {},
            catalogElements: sessionStorage.getItem('catalogElements') ? JSON.parse(sessionStorage.getItem('catalogElements')) : [],
            itemElements: sessionStorage.getItem('itemElements') ? JSON.parse(sessionStorage.getItem('itemElements')) : []
        },
        catalogBreadcrumb: {
            steps: sessionStorage.getItem('catalogBreadcrumbSteps') ? JSON.parse(sessionStorage.getItem('catalogBreadcrumbSteps')) : []
        },
        menu: {
            menuElements: sessionStorage.getItem('menuElements') ? JSON.parse(sessionStorage.getItem('menuElements')) : [],
            isMenuOpen: sessionStorage.getItem('isMenuOpen') ? JSON.parse(sessionStorage.getItem('isMenuOpen')) : false,
        },
        bpList: {
            bpList: sessionStorage.getItem('bpList') ? JSON.parse(sessionStorage.getItem('bpList')) : []
        },
        orders: { 
            draft: sessionStorage.getItem('draftOrders') ? JSON.parse(sessionStorage.getItem('draftOrders')) : [], 
            inProgress: sessionStorage.getItem('inProgressOrders') ? JSON.parse(sessionStorage.getItem('inProgressOrders')) : [], 
            history: sessionStorage.getItem('historyOrders') ? JSON.parse(sessionStorage.getItem('historyOrders')) : [], 
            draftContainerOpen: true, 
            inProgressContainerOpen: true, 
            historyContainerOpen: true,
            searchstring: ""
        },
        modelconfigurator: {
            svf: sessionStorage.getItem('svf') ? sessionStorage.getItem('svf') : "",
            accessToken: sessionStorage.getItem('accessToken') ? sessionStorage.getItem('accessToken') : "",
            item: sessionStorage.getItem('modelConfiguratorItem') ? JSON.parse(sessionStorage.getItem('modelConfiguratorItem')) : [], 
            modelConfig: sessionStorage.getItem('modelConfig') ? JSON.parse(sessionStorage.getItem('modelConfig')) : [],
            loading: false,
            outputIam: sessionStorage.getItem('outputIam') ? sessionStorage.getItem('outputIam') : "",
        },
        cart : {
            itemInCart: sessionStorage.getItem('itemInCart') ? JSON.parse(sessionStorage.getItem('itemInCart')) : [],
            formList: sessionStorage.getItem('cartDetailsForm') ? JSON.parse(sessionStorage.getItem('cartDetailsForm')) : [],          
            completeRequestBtnEnabled: sessionStorage.getItem('completeRequestBtnEnabled') ? sessionStorage.getItem('completeRequestBtnEnabled') : false,
        },
        cartClose : {
            invoiceBp: sessionStorage.getItem('cartCloseInvoiceBp') ? JSON.parse(sessionStorage.getItem('cartCloseInvoiceBp')) : {},
            destinations: sessionStorage.getItem('cartCloseDestinations') ? JSON.parse(sessionStorage.getItem('cartCloseDestinations')) : [],
            selectedDestination: sessionStorage.getItem('cartCloseSelectedDestination') ? JSON.parse(sessionStorage.getItem('cartCloseSelectedDestination')) : null,
            carriages: sessionStorage.getItem('cartCloseCarriages') ? JSON.parse(sessionStorage.getItem('cartCloseCarriages')) : [],
            selectedCarriage: sessionStorage.getItem('cartCloseSelectedCarriage') ? JSON.parse(sessionStorage.getItem('cartCloseSelectedCarriage')) : null,
            creationDate: sessionStorage.getItem('cartCloseCreationDate') ? new Date(sessionStorage.getItem('cartCloseCreationDate')) : null,
            deliveryDate: sessionStorage.getItem('cartCloseDeliveryDate') ? new Date(sessionStorage.getItem('cartCloseDeliveryDate')) : null,
            internalRef: sessionStorage.getItem('cartCloseInternalRef') ? sessionStorage.getItem('cartCloseInternalRef') : null,
            note: sessionStorage.getItem('cartCloseNote') ? sessionStorage.getItem('cartCloseNote') : null,
            itemTableColumns: sessionStorage.getItem('cartCloseItemTableColumns') ? JSON.parse(sessionStorage.getItem('cartCloseItemTableColumns')) : [],
            userDetailForm: sessionStorage.getItem('cartCloseUserDetailForm') ? JSON.parse(sessionStorage.getItem('cartCloseUserDetailForm')) : [],          
        },
        loggedUser: {
            isUserLogged: sessionStorage.getItem('isUserLogged') ? sessionStorage.getItem('isUserLogged') : false,
            id_User: sessionStorage.getItem('id_User') ? sessionStorage.getItem('id_User') : "",
            userCode: sessionStorage.getItem('userCode') ? sessionStorage.getItem('userCode') : "",
            userName: sessionStorage.getItem('userName') ? sessionStorage.getItem('userName') : ""
        }
        
    },
    composeEnhancers(applyMiddleware(reduxThunk))
)

export default store;