import React, { Component } from 'react';
import BpCard from '../subcomponents/BpCard';
import { connect } from 'react-redux';
import {
    getBpList
  } from '../../actions/index';

class BpList extends Component {

    constructor(props) {
        super(props)
        
        this.keyCount = 0;
        this.getKey = this.getKey.bind(this)
    }

    getKey() {
        var newKey = "bplist" + this.keyCount++
        return newKey;
    }  

    componentDidMount() {
        this.props.getBpList()
        //console.log("BpList ", this.props)
    }

    componentDidUpdate() {
        // console.log("BpList componentDidUpdate ", this.props)
    }

    onBpListElementClicked = (item) => {
       // console.log("onBpListElementClicked ", item)       
    }

    render() {
        const bpCards = [];
        
        for (let i = 0; i < this.props.bpList.length; i++) {
            bpCards.push( <BpCard key={this.getKey()} item={this.props.bpList[i]} onClick={(e) => this.onBpListElementClicked(this.props.bpList[i])} />);
        }

        return(
            <div className="bpListContainer">
                { bpCards }
            </div>           
        )
    }
}

function mapStateToProps(state){
    return {
        bpList: state.bpList.bpList
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getBpList: (dataRequest) => dispatch(getBpList(dataRequest))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BpList);