import { SET_ORDER_LIST, DELETE_ORDER, TOOGLE_DRAFT_ORDER_CONTAINER, TOOGLE_INPROGRESS_ORDER_CONTAINER, TOOGLE_HISTORY_ORDER_CONTAINER, UPDATE_ORDERS_SEARCH_STRING } from '../actions/types';

const INITIAL_STATE = { 
    draft: [],
    inProgress: [],
    history: [],
    draftContainerOpen: true,
    inProgressContainerOpen: true,
    historyContainerOpen: true,
    searchstring: ""
}

export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
        case SET_ORDER_LIST:
            sessionStorage.setItem('draftOrders', JSON.stringify(action.payload.draft))
            sessionStorage.setItem('inProgressOrders', JSON.stringify(action.payload.inProgress))
            sessionStorage.setItem('historyOrders', JSON.stringify(action.payload.history))
            return { ...state, draft: action.payload.draft, inProgress: action.payload.inProgress, history: action.payload.history }
        case TOOGLE_DRAFT_ORDER_CONTAINER:
            return { ...state, draftContainerOpen: !state.draftContainerOpen }
        case TOOGLE_INPROGRESS_ORDER_CONTAINER:
            return { ...state, inProgressContainerOpen: !state.inProgressContainerOpen }
        case TOOGLE_HISTORY_ORDER_CONTAINER:
            return { ...state, historyContainerOpen: !state.historyContainerOpen }
        case DELETE_ORDER:
            var newDraft = JSON.parse(JSON.stringify(state.draft))
            var orderToDeleteIndex = -1
            orderToDeleteIndex = newDraft.findIndex(el => el.orderCode === action.payload.orderCode)

            if(orderToDeleteIndex >= 0){
                newDraft.splice(orderToDeleteIndex, 1)
            }
            
            sessionStorage.setItem('draftOrders', JSON.stringify(newDraft))

            return { ...state, draft: newDraft }
        case UPDATE_ORDERS_SEARCH_STRING:
            return { ...state, searchstring: action.payload }
        default:
            return state
    }
}