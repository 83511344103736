import { combineReducers } from "redux";
import scroll from "./scroll";
import catalog from "./catalog";
import catalogBreadcrumb from "./catalogBreadcrumb";
import menu from "./menu";
import bpList from "./bpList";
import orders from "./orders";
import modelconfigurator from "./modelconfigurator";
import cart from "./cart";
import modalconfirm from "./modalconfirm";
import cartClose from "./cartClose";
import loggedUser from "./loggedUser";
import { DESTROY_SESSION } from "../actions/types";

// Combine all reducers.
const appReducer = combineReducers({
    scroll,
    catalog,
    catalogBreadcrumb,
    menu,
    bpList,
    orders,
    modelconfigurator,
    cart,
    modalconfirm,
    cartClose,
    loggedUser
});

 const rootReducer = (state, action) => {   
    // Clear all data in redux store to initial.
    if(action.type === DESTROY_SESSION)
    {
        state = undefined;
    }
         
    return appReducer(state, action);
 };


export default rootReducer;