export const SCROLL_EVENT = "scroll_event"; // azione di scrolling della pagina

export const UPDATE_CATALOG_CONTENT = "update_catalog_content"; // click di un elemento del catalogo per caricamento livello successivo

export const ADD_BREADCRUMB_STEP_CATALOG = "add_breadcrumb_step_catalog"; // azione di aggiunta nuovo step in coda al breadcrumb
export const INITIALIZE_BREADCRUMB_CATALOG = "initialize_breadcrumb_catalog"; // azione di aggiunta nuovo step in coda al breadcrumb
export const SELECT_BREADCRUMB_STEP_CATALOG = "select_breadcrumb_step_catalog"; // azione click su uno step del breadcrumb 

export const TOOGLE_MENU_BUTTON_CLICKED = "toogle_menu_button_clicked"; // azione click bottone menu (che sta nell header)

export const SET_BP_LIST = "set_bp_list"; // caricamento lista BP
export const SET_BP_FAVOURITE = "set_bp_favoutire" // In lista bp, click icona a stella per set/unset bp preferito

export const SET_ORDER_LIST = "set_order_list"; // caricamento ordini
export const DELETE_ORDER = "delete_order" // In pagina ordini, click icona cestino in una OrderCard
export const TOOGLE_DRAFT_ORDER_CONTAINER = "toogle_draft_order_Container" // espandi/comprimi sezione ordini in bozza (pagina orders)
export const TOOGLE_INPROGRESS_ORDER_CONTAINER = "toogle_inprogress_order_Container" // espandi/comprimi sezione ordini in corso (pagina orders)
export const TOOGLE_HISTORY_ORDER_CONTAINER = "toogle_draft_history_Container" // espandi/comprimi sezione ordini storici (pagina orders)

export const UPDATE_ORDERS_SEARCH_STRING = "update_orders_search_string"

export const SET_ACCESS_TOKEN = "set_access_token" // memorizza token autenticazione Forge
export const FORGE_UPDATE_MODEL_PARAMETERS = "forge_update_model_parameters" // aggiorna l'url (Forge) del modello  da visualizzare nel viewer

export const SET_LOADING_SPINNER_VISIBILITY = "set_loading_spinner_visibility" // Visualizza/nasconde lo spinner di caricamento

export const SET_MODELCONFIGURATOR_FORM = "set_modelconfigurator_form" // Recupera la form con i parametri configurabili per il modello che si vuoel configurare

export const SET_MODELCARTDETAILS_FORM = "set_modelcartdetails_form" // Recupera la form dettaglio macchina in cart
export const ADD_ITEM_IN_CART = "add_item_in_cart" // Aggiunge un item a carrello
export const SET_ITEMS_IN_CART_ACCESSORIES = "set_item_in_cart_accessories" // Setta gli accessori per gli items a carrello
export const UPDATE_ITEM_IN_CART_QTY = "update_item_in_cart_qty" // Aggiornamento quantità di un articolo a carrello
export const REMOVE_ITEM_IN_CART = "remove_item_in_cart" // Elimina un articolo dal carrello
export const EMPTY_CART = "empty_cart" // Svuota il contenuto del carrello

export const INITIALIZE_MODAL_CONFIRM = "initialize_modal_confirm" // Inizializzazione della confirm modal (title, content, styles)
export const SET_MODEL_CONF_ITEM = "set_model_conf_item" // Imposta la proprietà item dello stato di modelconfigurator
export const UPDATE_MODEL_PARAMS_REDUCER = "update_model_parameters_in_reducer" // Aggiorna lo stato di modelconfigurator aggiornando la configurazione del modello così come impostata dall utente

export const SET_CARTCLOSE_USERDET_FORM = "set_cartclose_userdet_form" // Inizializza i campi della form in chiusura carrello
export const SET_CARTCLOSE_ITEMTABLE = "set_cartclose_itemtable" // Inizializza le colonne e le righe della tabella articoli in chiusura carrello
export const SET_ITEM_PRICE = "set_item_price"
export const UPDATE_DELIVERY_DATE = "update_delivery_date"
export const UPDATE_DELIVERY_DATE_ITEM = "update_delivery_date_item"

export const SET_ISLOGGEDUSER = "is_logged_user"

export const SET_ITEM_CONFIGURATION_PDF_URL = "set_item_configuration_pdf_url"
export const SET_ITEM_CONFIGURATION_STEP_URL = "set_item_configuration_step_url"

export const ONCHANGE_CONFIGURATOR_FORM_INPUT = "onchange_configurator_input"
export const ONCHANGE_ACCESSORYGRP_FORM_INPUT = "onchange_accessorygrp_input"
export const ONCHANGE_CNC_FORM_INPUT = 'onchange_cnc_form_input'
export const UPDATE_ITEM_IN_CART_ACCESSORIES = "update_item_in_cart_accessories"
export const ONCHANGE_USERDET_FORM_INPUT = "onchange_userdet_form_input"
export const SET_MODELCONFIGURATOR_DESCR = "set_modelconfiguration_descr"
export const DESTROY_SESSION = "destroy_session"
export const SET_MENU_CONTENT = "set_menu_content"

export const SET_LOGGED_USER = "set_logged_user"

export const SET_MODELCONFIGURATOR_SVF = "set_modelconfiguration_svf"
export const ONCHANGE_ITEM_NOTE = "onchange_item_note"
